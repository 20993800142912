import { openModal, setModal } from 'actions';
import { capitalCase } from 'change-case';
import { message, Modal } from 'antd';
import { gql } from '@apollo/client';
import { client as apolloClient } from '@xbcb/apollo-client';
import { memoFragments } from '@xbcb/client-queries';
import { checkAccess, UserPermissions } from '@xbcb/client-utils';
import { AccountType, RecordType, AnyObject } from '@xbcb/shared-types';
import {
  UsConsumptionEntry as ApigUsConsumptionEntry,
  UsConsumptionEntryMasterBill,
} from '@xbcb/api-gateway-client';
import {
  generateUsConsumptionEntryNumber,
  subscriptionWorkOrderTaskFragments,
  workOrderTaskFragments,
  workOrderTaskStepFragments,
} from '@xbcb/work-order-queries';
import {
  UsCbpEntryReleaseStatus,
  UsCbpEntrySummaryStatus,
  UsConsumptionEntryConfirmationTransactionType,
  WorkOrderTaskType,
  WorkOrderType,
  WorkOrderTaskStatus,
} from '@xbcb/work-order-types';
import {
  AppRecordEditMode,
  KebabMenuItemKey,
  UiStage,
  uiStageToBackendStage,
} from '@xbcb/ui-types';
import { getEnv } from '@xbcb/ui-utils';
import {
  usConsumptionEntryGenerator,
  UsConsumptionEntryLevel,
  UsConsumptionEntryPgaGenerator,
} from '@xbcb/work-order-utils/dist/workOrderGenerators';
import { ModalKey } from 'types';
import { executeMutation } from 'libs/executeMutation';
import { transformUsConsumptionEntry } from 'libs/formTransforms';
import {
  queryUsConsumptionEntrySummaryCbp,
  replaceUsConsumptionEntryReleaseCbp,
  deleteUsConsumptionEntrySummaryCbp,
  updateUsConsumptionEntryPgaCbp,
  updateUsConsumptionEntryReleaseCbp,
  upsertUsConsumptionEntrySummaryCbp,
  usConsumptionEntryFragments,
  usConsumptionEntrySubscriptionFragments,
} from 'libs/sharedQueries';
import validateUsConsumptionEntry from 'libs/validateUsConsumptionEntry';
import { validateEntryForDraftSummary } from 'libs/validateEntryForDraftSummary';
import { disableWorkOrderTaskCompletionButton } from 'libs/workOrderTaskCompletionButton';
import { shouldUpsertRelease } from 'libs/axAndSxAcceptedMessages';
import UsConsumptionEntrySecondaryHeader from 'components/UsConsumptionEntrySecondaryHeader';
import { UsConsumptionEntry } from '../../loadableComponents';
import {
  AppRecordOnConfirmPayloadConfirmationData,
  AppRecordOnConfirmPayload,
} from '../../types';
import { WorkOrderRoute } from '.';
import {
  findWorkOrderTaskAndModifyStepCompletion,
  appRecordAbiDataMenuItem,
  appRecordAssignWorkOrderTaskMenuItem,
  appRecordMemoMenuItem,
  appRecordModifyDutyCollectionParty,
  inquireWorkOrderItem,
  unblockWorkOrderItem,
  createUsPostSummaryCorrectionFromUsConsumptionEntry,
  cancelEntryWithCbpButton,
} from '../utils';
import MOTMenuOptions from 'components/MOTMenuOptions';
import AmsIndicatorOptions from 'components/AmsIndicatorOptions';
import { AmsIndicatorOptionsConfiguration } from 'libs/amsIndicatorOptionsConfiguration';
import { markWorkOrderButton } from '../utils/markWorkOrdersReadyButton';
import { markWorkOrderHotButton } from '../utils/markWorkOrderHotButton';
import {
  validateEntryButton,
  showSubmitButton,
} from '../utils/validateEntryButton';
import { checkKebabMenuItemForWorkOrderTask } from '../utils/checkKebabMenuItemForWorkOrderTask';
import { updateIorOnEntry } from 'libs/updateIorOnEntry';
import {
  skipUpdateEntryNoOverrideCode,
  submitCensusWarningsOverridesToCBP,
} from 'libs/workOrderTaskTransmitOverridesButton';
import { completeWorkOrderTaskButton } from '../utils/completeWorkOrderTaskButton';
import { createFlipWorkOrderMenuItem } from '../utils/flipWorkOrder';
import { appRecordSendAbiMessage } from '../utils/appRecordSendAbiMessage';
import { approveEntryConfirmation } from '../utils/approveEntryConfirmation';
import {
  WORK_ORDER_CLONING_FEATURE,
  workOrderCloningFeatureName,
} from '@xbcb/feature-flags';
import {
  hasBrokerNumber,
  isEntryUnderCBPControl,
  hasEntryNumber,
  hasEntryReleaseStatus,
  hasEntrySummaryStatus,
  isRecordCanceled,
  isRecordDeleted,
  isRecordReplaced,
} from 'libs/workOrderConditions';
import { getFrontendUsEntryLargeEntryClient } from 'libs/uiUsConsumptionEntryLargeEntryClient';
import { isExamOrHoldWorkOrderTask } from '../utils/isExamOrHoldWorkOrderTask';

const { stage } = getEnv();

let isIorDeleted: boolean;

const createWorkOrderConfirmation = gql`
  mutation CreateWorkOrderConfirmation(
    $idempotencyKey: String!
    $input: CreateWorkOrderConfirmationInput!
  ) {
    createWorkOrderConfirmation(
      idempotencyKey: $idempotencyKey
      input: $input
    ) {
      record {
        id
      }
    }
  }
`;

const changeMot = gql`
  mutation ChangeUsConsumptionEntryMot(
    $id: ID!
    $version: Int!
    $input: UpdateUsConsumptionEntryInput!
  ) {
    updateUsConsumptionEntry(id: $id, version: $version, input: $input) {
      record {
        id
        version
        conveyance {
          modeOfTransport
        }
      }
    }
  }
`;

const modifyAmsIndicator = gql`
  mutation ModifyUsConsumptionEntryAmsIndicator(
    $id: ID!
    $version: Int!
    $input: UpdateUsConsumptionEntryInput!
  ) {
    updateUsConsumptionEntry(id: $id, version: $version, input: $input) {
      record {
        id
        version
        masterBills {
          number
          houseBills {
            number
            quantity
            nonAMS
            inBondNumber
          }
        }
      }
    }
  }
`;

export const generateDraftEntrySummary = gql`
  mutation GenerateDraftEntrySummary($usConsumptionEntryId: ID!) {
    generateDraftEntrySummary(usConsumptionEntryId: $usConsumptionEntryId) {
      documentGenerationRequest {
        id
      }
    }
  }
`;

type TransactCreateWorkOrderConfirmationProps = {
  confirmationData?: AppRecordOnConfirmPayloadConfirmationData;
  updatedRecord: AnyObject;
  transactionType: UsConsumptionEntryConfirmationTransactionType;
  transactionInput?: AnyObject;
};

const submitCreateWorkOrderConfirmation = async ({
  confirmationData,
  updatedRecord,
  transactionType,
  transactionInput,
}: TransactCreateWorkOrderConfirmationProps) => {
  return await executeMutation({
    mutation: createWorkOrderConfirmation,
    variables: {
      input: {
        workOrder: {
          id: updatedRecord.id,
          version: updatedRecord.version,
        },
        operator: {
          id: updatedRecord.operator.id,
        },
        transaction: {
          type: transactionType,
          input: transactionInput,
        },
      },
    },
  });
};

const sharedUsConsumptionEntryRouteFragment = `fragment usConsumptionEntryRouteBaseFields on UsConsumptionEntry {
  controlStatus
  deadline
  status
  exam {
    lspNotifiedDate
  }
  group {
    workOrders {
      id
      ... on UsIsf {
        cbpStatus
        masterBills {
          number
          houseBills {
            number
          }
        }
      }
    }
  }
  confirmations {
    id
    status
    disputedReason
    additionalComments
  }
  pendingUpdates {
    path
    value
    occurrenceTime
    dataSource
    confidenceScore
  }
  tasks {
    ...workOrderTaskFields
    assignee {
      id
    }
    steps {
      id
      name
      description
      isCompletedBySystem
      isFinalConfirmation
    }
    definition {
      id
      workOrderTaskType
    }
    customFields {
			label
			customFieldType
		  booleanValue
			dateValue
		  textValue
		  recordId
      enumOptions
      enumValues
		}
  }
  totalInvoiceLineCount
}`;

const fields = `
  ...usConsumptionEntryFields
  ...usConsumptionEntryRouteBaseFields
  group {
    workOrders {
      id
      ... on UsIsf {
        cbpStatus
        masterBills {
          number
          houseBills {
            number
          }
        }
      }
    }
    forwarders {
      forwarder {
        tags {
          key
          value
        }
      }
    }
    documents {
      id
    }
    memos {
      ...memoFields
    }
    shipper {
      name
      memos {
        ...memoFields
      }
    }
    workOrders {
      id
    }
  }
  ior {
    usIor {
      name
      industry
      memos {
        ...memoFields
      }
    }
  }
  tasks {
    assignee {
      id
      name
    }
  }
`;

const sharedFragments = `${sharedUsConsumptionEntryRouteFragment}${workOrderTaskStepFragments}`;

const fragments = `${usConsumptionEntryFragments}${workOrderTaskFragments}${sharedFragments}${memoFragments}`;

const subscriptionFields =
  '...usConsumptionEntryBaseFields ...usConsumptionEntryRouteBaseFields';

const subscriptionFragments = `${usConsumptionEntrySubscriptionFragments}${subscriptionWorkOrderTaskFragments}${sharedFragments}`;

const usConsumptionEntryRoute: WorkOrderRoute = {
  Page: UsConsumptionEntry,
  recordType: RecordType.US_CONSUMPTION_ENTRY,
  getTitleField: () => 'entryNumber',
  fields,
  fragments,
  transformUpdateRecordInput: (inputs) => {
    return getFrontendUsEntryLargeEntryClient().processForOversizedInput(
      transformUsConsumptionEntry.toSchema(inputs),
    );
  },
  transformRecordToInput: transformUsConsumptionEntry.toForm,
  validateUpdate: validateUsConsumptionEntry,
  SecondaryHeaderContents: [UsConsumptionEntrySecondaryHeader],
  kebabMenuItems: [
    appRecordAbiDataMenuItem,
    appRecordModifyDutyCollectionParty,
    inquireWorkOrderItem,
    unblockWorkOrderItem,
    appRecordSendAbiMessage,
    approveEntryConfirmation,
    {
      text: 'Generate Entry Number',
      key: KebabMenuItemKey.GENERATE_ENTRY_NUMBER,
      action: async ({ record }) => {
        const { data, errors } = await apolloClient.mutate({
          mutation: generateUsConsumptionEntryNumber,
          variables: { usConsumptionEntryId: record.id },
          errorPolicy: 'all', // this means any errors returned will  no throw, so are handled directly below
        });
        if (errors) {
          message.error(
            `Sorry, an error occurred, please try again later.`,
            5.0,
          );
        } else {
          message.success(
            `Entry number ${data?.generateUsConsumptionEntryNumber?.usConsumptionEntry?.entryNumber} generated.`,
            5.0,
          );
        }
      },
      show: ({
        existingRecord,
        currentUser,
        workOrderTaskType,
        workOrderTaskEvaluatedState,
      }) =>
        currentUser.accountType === AccountType.OPERATOR &&
        !hasEntryNumber(existingRecord) &&
        !hasBrokerNumber(existingRecord) &&
        !isRecordDeleted(existingRecord) &&
        checkKebabMenuItemForWorkOrderTask(
          workOrderTaskType,
          workOrderTaskEvaluatedState,
          KebabMenuItemKey.GENERATE_ENTRY_NUMBER,
        ),
    },
    appRecordAssignWorkOrderTaskMenuItem,
    appRecordMemoMenuItem,
    // TODO ABI sub menu
    {
      text: 'Replace Release',
      key: 'replaceRelease',
      action: async ({ setEditMode }) => {
        setEditMode('replaceRelease');
      },
      show: ({
        existingRecord,
        currentUser,
        workOrderTaskType,
        workOrderTaskEvaluatedState,
      }) =>
        currentUser.accountType === AccountType.OPERATOR &&
        hasEntrySummaryStatus(existingRecord) &&
        hasEntryReleaseStatus(existingRecord) &&
        !isRecordDeleted(existingRecord) &&
        !isRecordReplaced(existingRecord) &&
        checkKebabMenuItemForWorkOrderTask(
          workOrderTaskType,
          workOrderTaskEvaluatedState,
        ),
    },
    {
      text: 'Update PGA',
      key: 'updatePga',
      action: async ({ setEditMode }) => {
        setEditMode('updatePga');
      },
      show: ({
        existingRecord,
        currentUser,
        workOrderTaskType,
        workOrderTaskEvaluatedState,
      }): boolean => {
        const entry = existingRecord as ApigUsConsumptionEntry;
        const pgaIterator = usConsumptionEntryGenerator({
          entry,
          level: UsConsumptionEntryLevel.PGAS,
        }) as Generator<UsConsumptionEntryPgaGenerator>;
        const hasFdaPga = Array.from(pgaIterator).some(
          (pga) => pga.agencyCode === 'FDA',
        );
        return (
          currentUser.accountType === AccountType.OPERATOR &&
          hasEntrySummaryStatus(existingRecord) &&
          hasEntryReleaseStatus(existingRecord) &&
          !isRecordDeleted(existingRecord) &&
          !isRecordReplaced(existingRecord) &&
          (!existingRecord?.dailyStatement?.number || !hasFdaPga) &&
          checkKebabMenuItemForWorkOrderTask(
            workOrderTaskType,
            workOrderTaskEvaluatedState,
          )
        );
      },
    },
    {
      text: 'Update Statement',
      key: 'updateStatement',
      action: async ({ dispatch }) => {
        dispatch(
          openModal({
            key: ModalKey.UPDATE_STATEMENT,
          }),
        );
      },
      show: ({
        existingRecord,
        currentUser,
        workOrderTaskType,
        workOrderTaskEvaluatedState,
      }) =>
        currentUser.accountType === AccountType.OPERATOR &&
        !currentUser.loading &&
        checkAccess(
          currentUser,
          RecordType.US_CONSUMPTION_ENTRY,
          UserPermissions.UPDATE_STATEMENT_CBP_TRANSACTION,
        ) &&
        hasEntrySummaryStatus(existingRecord) &&
        !existingRecord?.dailyStatement?.authorizationTime &&
        !isRecordDeleted(existingRecord) &&
        !isRecordReplaced(existingRecord) &&
        checkKebabMenuItemForWorkOrderTask(
          workOrderTaskType,
          workOrderTaskEvaluatedState,
        ),
    },
    {
      text: 'Query Entry',
      key: 'queryEntry',
      action: async ({ record }) => {
        await executeMutation({
          mutation: queryUsConsumptionEntrySummaryCbp,
          variables: { usConsumptionEntryId: record.id },
        });
      },
      show: ({
        existingRecord,
        currentUser,
        workOrderTaskType,
        workOrderTaskEvaluatedState,
      }) =>
        currentUser.accountType === AccountType.OPERATOR &&
        hasEntryNumber(existingRecord) &&
        checkKebabMenuItemForWorkOrderTask(
          workOrderTaskType,
          workOrderTaskEvaluatedState,
        ),
    },
    {
      text: 'Send Documents to CBP',
      key: KebabMenuItemKey.SEND_DOCUMENTS_TO_CBP,
      action: async ({ dispatch }) => {
        dispatch(
          setModal({
            key: ModalKey.SEND_DOCUMENT,
            props: {
              visible: true,
              // set document to null since we don't want to just send one specific document
              document: null,
            },
          }),
        );
      },
      show: ({ currentUser, workOrderTaskType, workOrderTaskEvaluatedState }) =>
        currentUser.accountType === AccountType.OPERATOR &&
        checkKebabMenuItemForWorkOrderTask(
          workOrderTaskType,
          workOrderTaskEvaluatedState,
          KebabMenuItemKey.SEND_DOCUMENTS_TO_CBP,
        ),
    },
    {
      text: 'Upload Invoice',
      key: KebabMenuItemKey.UPLOAD_INVOICE,
      action: async ({ history, location }) => {
        history.push(`${location.pathname}/upload${location.search}`);
      },
      show: ({
        currentUser,
        existingRecord,
        workOrderTaskType,
        workOrderTaskEvaluatedState,
      }) =>
        checkAccess(currentUser, RecordType.PRODUCT, UserPermissions.UPDATE) &&
        checkAccess(currentUser, RecordType.PRODUCT, UserPermissions.CREATE) &&
        currentUser.accountType === AccountType.OPERATOR &&
        !isRecordDeleted(existingRecord) &&
        checkKebabMenuItemForWorkOrderTask(
          workOrderTaskType,
          workOrderTaskEvaluatedState,
          KebabMenuItemKey.UPLOAD_INVOICE,
        ),
    },
    {
      text: 'Delete Entry Summary',
      key: 'deleteEntry',
      action: async ({ dispatch, record }) => {
        Modal.confirm({
          title: 'Delete Entry Summary (AE data)',
          content: `Are you sure you want to delete Entry Summary ${record?.entryNumber}? This will not delete the Release (SE) data, you can delete that separately if required.`,
          width: 500,
          onOk: async () => {
            await executeMutation({
              mutation: deleteUsConsumptionEntrySummaryCbp,
              variables: { usConsumptionEntryId: record.id },
            });
          },
        });
      },
      show: ({ existingRecord, currentUser }) =>
        currentUser.accountType === AccountType.OPERATOR &&
        !currentUser.loading &&
        checkAccess(
          currentUser,
          RecordType.US_CONSUMPTION_ENTRY,
          UserPermissions.DELETE,
        ) &&
        hasEntryNumber(existingRecord) &&
        hasEntrySummaryStatus(existingRecord) &&
        existingRecord?.summaryStatus !== UsCbpEntrySummaryStatus.DELETED,
    },
    {
      text: 'Delete Release',
      key: 'deleteRelease',
      action: async ({ dispatch }) => {
        dispatch(
          openModal({
            key: ModalKey.DELETE_US_CONSUMPTION_ENTRY_RELEASE_CBP,
          }),
        );
      },
      show: ({ existingRecord, currentUser }) =>
        currentUser.accountType === AccountType.OPERATOR &&
        hasEntryNumber(existingRecord) &&
        [
          UsCbpEntrySummaryStatus.DELETED,
          UsCbpEntrySummaryStatus.REJECTED,
        ].includes(existingRecord?.summaryStatus) &&
        existingRecord?.releaseStatus !== UsCbpEntryReleaseStatus.DELETED,
    },
    {
      text: 'Change MOT',
      key: KebabMenuItemKey.CHANGE_MOT,
      action: async ({ record, props }) => {
        const originalMot = record.conveyance.modeOfTransport;
        if (!props?.key) {
          message.error(
            `Sorry, an error occurred, please try again later.`,
            5.0,
          );
          return;
        }
        await executeMutation({
          mutation: changeMot,
          variables: {
            id: record.id,
            version: record.version,
            input: {
              conveyance: {
                modeOfTransport: props!.key.toUpperCase(),
              },
            },
          },
          successMessage: `MOT changed from ${originalMot.toLowerCase()} to ${
            props.key
          }`,
        });
      },
      show: ({
        existingRecord,
        currentUser,
        workOrderTaskType,
        workOrderTaskEvaluatedState,
      }) =>
        currentUser.accountType === AccountType.OPERATOR &&
        checkAccess(
          currentUser,
          RecordType.US_CONSUMPTION_ENTRY,
          UserPermissions.UPDATE,
        ) &&
        !isRecordCanceled(existingRecord) &&
        existingRecord?.summaryStatus !== UsCbpEntrySummaryStatus.FILED &&
        checkKebabMenuItemForWorkOrderTask(
          workOrderTaskType,
          workOrderTaskEvaluatedState,
          KebabMenuItemKey.CHANGE_MOT,
        ),
      SubMenuOptions: MOTMenuOptions,
    },
    createFlipWorkOrderMenuItem({
      currentWorkOrderType: WorkOrderType.UsConsumptionEntry,
      outputWorkOrderTypes: [WorkOrderType.UsType86Entry],
      validateFilingTypeClassificationBeforeFlip: true,
    }),
    createUsPostSummaryCorrectionFromUsConsumptionEntry,
    {
      text: 'Generate Draft 7501',
      key: 'generateDraft7501',
      action: async ({ record, form }): Promise<void> => {
        const { errors } = await apolloClient.mutate({
          mutation: generateUsConsumptionEntryNumber,
          variables: { usConsumptionEntryId: record.id },
          errorPolicy: 'all', // this means any errors returned will no throw, so are handled directly below
        });
        if (errors?.length) {
          message.error(`Failed to generate entry number`, 5.0);
        }
        const valid = await validateEntryForDraftSummary({ form, record });
        if (!valid) return;
        await executeMutation({
          mutation: generateDraftEntrySummary,
          variables: {
            usConsumptionEntryId: record?.id,
            draft: true,
          },
          successMessage: 'Draft Entry Summary Generation Initiated',
        });
      },
      show: ({
        currentUser,
        existingRecord,
        workOrderTaskType,
        workOrderTaskEvaluatedState,
      }): boolean => {
        // Business requirement: Entry Completion & Submission WOT and US Entry External Customer WOT
        const entry: ApigUsConsumptionEntry = existingRecord;
        const completionTask = entry.tasks.find((task) => {
          const taskType = task.definition.workOrderTaskType;
          return (
            taskType ===
              WorkOrderTaskType.US_CONSUMPTION_ENTRY_COMPLETION_SUBMISSION ||
            taskType === WorkOrderTaskType.US_CONSUMPTION_ENTRY_FILING
          );
        });
        // Entry Completion & Submission WOT is not assigned, assigned or in progress
        const isEntryCompletionTaskInComplete = Boolean(
          completionTask?.status &&
            [
              WorkOrderTaskStatus.NOT_ASSIGNED,
              WorkOrderTaskStatus.ASSIGNED,
              WorkOrderTaskStatus.IN_PROGRESS,
            ].includes(completionTask.status as WorkOrderTaskStatus),
        );
        return (
          currentUser.accountType === AccountType.OPERATOR &&
          checkAccess(
            currentUser,
            RecordType.US_CONSUMPTION_ENTRY,
            UserPermissions.UPDATE,
          ) &&
          isEntryCompletionTaskInComplete &&
          checkKebabMenuItemForWorkOrderTask(
            workOrderTaskType,
            workOrderTaskEvaluatedState,
          )
        );
      },
    },
    {
      text: 'Modify AMS Indicator',
      key: KebabMenuItemKey.MODIFY_AMS_INDICATOR,
      action: async ({ record, props }): Promise<void> => {
        const masterBills: UsConsumptionEntryMasterBill[] = record.masterBills;
        if (!props?.key) {
          message.error(
            `Sorry, an error occurred, please try again later.`,
            5.0,
          );
          return;
        }
        const isNonAms = props.key === AmsIndicatorOptionsConfiguration.NON_AMS;
        const newMasterBills = masterBills?.reduce(
          (acc: UsConsumptionEntryMasterBill[], masterBill) => {
            const { number, houseBills } = masterBill;
            const newHouseBills = houseBills?.map((houseBill) => {
              const { number, quantity, inBondNumber } = houseBill || {};
              return {
                inBondNumber,
                quantity,
                nonAMS: isNonAms,
                number: isNonAms ? (null as unknown as undefined) : number,
                // when we set entry as non-AMS, we need to set nonAMS as true and clean houseBills number
                // Otherwise we will get entry rejection "INVALID BILL TYPE FOR NON-AMS BILL"
                // https://quip-amazon.com/rH8UAQfx5KVE/Playbook-Submit-entry-without-AMS-Submit-non-AMS-entry
              };
            });
            acc.push({
              number,
              houseBills: newHouseBills,
            });
            return acc;
          },
          [] as UsConsumptionEntryMasterBill[],
        );
        await executeMutation({
          mutation: modifyAmsIndicator,
          variables: {
            id: record.id,
            version: record.version,
            input: {
              masterBills: newMasterBills,
            },
          },
          successMessage: `AMS indicator changes to ${props.key}`,
        });
      },
      show: ({
        existingRecord,
        currentUser,
        workOrderTaskType,
        workOrderTaskEvaluatedState,
      }) =>
        currentUser.accountType === AccountType.OPERATOR &&
        checkAccess(
          currentUser,
          RecordType.US_CONSUMPTION_ENTRY,
          UserPermissions.UPDATE,
        ) &&
        !isRecordCanceled(existingRecord) &&
        checkKebabMenuItemForWorkOrderTask(
          workOrderTaskType,
          workOrderTaskEvaluatedState,
          KebabMenuItemKey.MODIFY_AMS_INDICATOR,
        ),
      SubMenuOptions: AmsIndicatorOptions,
    },
  ],
  editModes: [
    {
      key: 'updateEntry',
      submitText: 'Update entry',
      cancelText: 'Cancel entry update',
      onConfirm: ({ dispatch, isValid }) =>
        new Promise<AppRecordOnConfirmPayload>((resolve) => {
          if (isValid) {
            dispatch(
              setModal({
                key: ModalKey.VALIDATE_US_ENTRY_SUMMARY,
                props: {
                  visible: true,
                  sendConfirmationDecision: resolve,
                  updatingEntry: true,
                  transactionType:
                    UsConsumptionEntryConfirmationTransactionType.UPSERT_US_CONSUMPTION_ENTRY_SUMMARY_CBP,
                },
              }),
            );
          } else {
            return resolve({ canceled: true });
          }
        }),
      preSubmit: async ({ existingRecord, form }): Promise<void> => {
        await updateIorOnEntry(
          { ior: { usIor: { id: existingRecord.ior.usIor.id } } },
          form,
        );
      },
      onSubmit: async ({ updatedRecord, confirmationData }): Promise<void> => {
        const upsertRelease = shouldUpsertRelease(updatedRecord);
        if (confirmationData?.confirmationRequired) {
          await submitCreateWorkOrderConfirmation({
            confirmationData,
            updatedRecord,
            transactionType:
              UsConsumptionEntryConfirmationTransactionType.UPSERT_US_CONSUMPTION_ENTRY_SUMMARY_CBP,
            transactionInput: {
              upsertRelease,
            }, // undefined should be default true
          });
        } else {
          await executeMutation({
            mutation: upsertUsConsumptionEntrySummaryCbp,
            variables: {
              usConsumptionEntryId: updatedRecord.id,
              input: {
                upsertRelease,
              }, // undefined should be default true
            },
          });
        }
      },
    },
    {
      key: 'updateRelease',
      submitText: 'Submit bill update',
      cancelText: 'Cancel bill update',
      onConfirm: ({ dispatch, isValid }) =>
        new Promise<AppRecordOnConfirmPayload>((resolve) => {
          if (isValid) {
            dispatch(
              setModal({
                key: ModalKey.VALIDATE_US_ENTRY_SUMMARY,
                props: {
                  visible: true,
                  sendConfirmationDecision: resolve,
                  updatingEntry: true,
                  transactionType:
                    UsConsumptionEntryConfirmationTransactionType.UPDATE_US_CONSUMPTION_ENTRY_RELEASE_CBP,
                },
              }),
            );
          } else {
            return resolve({ canceled: true });
          }
        }),
      onSubmit: async ({ updatedRecord, confirmationData }): Promise<void> => {
        if (confirmationData?.confirmationRequired) {
          await submitCreateWorkOrderConfirmation({
            confirmationData,
            updatedRecord,
            transactionType:
              UsConsumptionEntryConfirmationTransactionType.UPDATE_US_CONSUMPTION_ENTRY_RELEASE_CBP,
          });
        } else {
          await executeMutation({
            mutation: updateUsConsumptionEntryReleaseCbp,
            variables: { usConsumptionEntryId: updatedRecord.id },
          });
        }
      },
    },
    {
      key: 'replaceRelease',
      submitText: 'Submit replace release',
      cancelText: 'Cancel replace release',
      onConfirm: ({ dispatch, isValid }) =>
        new Promise<AppRecordOnConfirmPayload>((resolve) => {
          if (isValid) {
            dispatch(
              setModal({
                key: ModalKey.VALIDATE_US_ENTRY_SUMMARY,
                props: {
                  visible: true,
                  sendConfirmationDecision: resolve,
                  updatingEntry: true,
                  transactionType:
                    UsConsumptionEntryConfirmationTransactionType.REPLACE_US_CONSUMPTION_ENTRY_RELEASE_CBP,
                },
              }),
            );
          } else {
            return resolve({ canceled: true });
          }
        }),
      onSubmit: async ({ updatedRecord, confirmationData }): Promise<void> => {
        if (confirmationData?.confirmationRequired) {
          await submitCreateWorkOrderConfirmation({
            confirmationData,
            updatedRecord,
            transactionType:
              UsConsumptionEntryConfirmationTransactionType.REPLACE_US_CONSUMPTION_ENTRY_RELEASE_CBP,
          });
        } else {
          await executeMutation({
            mutation: replaceUsConsumptionEntryReleaseCbp,
            variables: { usConsumptionEntryId: updatedRecord.id },
          });
        }
      },
    },
    {
      key: 'updatePga',
      submitText: 'Submit PGA update',
      cancelText: 'Cancel PGA update',
      onSubmit: async ({ updatedRecord, confirmationData }): Promise<void> => {
        if (confirmationData?.confirmationRequired) {
          await submitCreateWorkOrderConfirmation({
            confirmationData,
            updatedRecord,
            transactionType:
              UsConsumptionEntryConfirmationTransactionType.UPDATE_US_CONSUMPTION_ENTRY_PGA_CBP,
          });
        } else {
          await executeMutation({
            mutation: updateUsConsumptionEntryPgaCbp,
            variables: { usConsumptionEntryId: updatedRecord.id },
          });
        }
      },
    },
    {
      key: AppRecordEditMode.UPDATE_EXAM_INFO,
      submitText: 'Save',
      cancelText: 'Cancel',
      onSubmit: async (): Promise<void> => {
        // Do nothing, default action is to save record
      },
    },
  ],
  submitButtons: [
    validateEntryButton(),
    completeWorkOrderTaskButton({
      skipValidationForWorkOrderTaskTypes: [
        WorkOrderTaskType.US_CONSUMPTION_ENTRY_BUILD_INVOICE,
        WorkOrderTaskType.US_CONSUMPTION_ENTRY_BUILD_TRANSPORTATION_AND_INVOICE,
      ],
    }),
    ...(WORK_ORDER_CLONING_FEATURE.isEnabled(workOrderCloningFeatureName, {
      stage: uiStageToBackendStage[stage],
    })
      ? [cancelEntryWithCbpButton()]
      : []),
    {
      key: 'transmitOverrides',
      text: 'Transmit Overrides',
      show: ({ workOrderTaskType }) => {
        return (
          workOrderTaskType ===
          WorkOrderTaskType.US_CONSUMPTION_ENTRY_CENSUS_WARNING
        );
      },
      skipUpdateRecord: ({ form }) => {
        return skipUpdateEntryNoOverrideCode(form);
      },
      onSubmit: async ({ updatedRecord, form }) => {
        return submitCensusWarningsOverridesToCBP(updatedRecord, form);
      },
    },
    markWorkOrderButton(WorkOrderType.UsConsumptionEntry),
    markWorkOrderHotButton(WorkOrderType.UsConsumptionEntry),
    {
      key: 'submitEntry',
      text: 'Submit entry',
      show: ({
        existingRecord,
        editMode,
        currentUser,
        workOrderTaskType,
      }): boolean => {
        return (
          showSubmitButton(workOrderTaskType) &&
          !hasEntrySummaryStatus(existingRecord) &&
          editMode === 'draft' &&
          !isRecordDeleted(existingRecord) &&
          !isRecordCanceled(existingRecord) &&
          !isRecordReplaced(existingRecord) &&
          !currentUser.loading &&
          currentUser.accountType === AccountType.OPERATOR &&
          checkAccess(
            currentUser,
            RecordType.US_CONSUMPTION_ENTRY,
            UserPermissions.UPSERT_CBP_TRANSACTION,
          )
        );
      },
      disable: ({ existingRecord, workOrderTaskType }): boolean => {
        if (stage !== UiStage.PROD) {
          if (existingRecord) {
            return isIorDeleted;
          }
        }
        if (workOrderTaskType) {
          return disableWorkOrderTaskCompletionButton(
            existingRecord,
            workOrderTaskType,
          );
        }
        return false;
      },
      preSubmit: async ({ existingRecord, form }): Promise<void> => {
        await updateIorOnEntry(
          { ior: { usIor: { id: existingRecord.ior.usIor.id } } },
          form,
        );
      },
      onSubmit: async ({
        updatedRecord,
        confirmationData,
        workOrderTaskType,
        form,
      }): Promise<void> => {
        if (workOrderTaskType) {
          await findWorkOrderTaskAndModifyStepCompletion({
            workOrder: updatedRecord,
            workOrderTaskType,
          });
        }
        if (
          updatedRecord.ior.usIor.deleted ||
          updatedRecord.ior.usIor.status === 'CANCELED'
        ) {
          Modal.error({
            title: `IOR Deactivated`,
            content: `This IOR has been deactivated so you cannot file this entry. Please contact your team lead for more information.`,
            okText: 'OK',
            onOk: () => {
              isIorDeleted = true;
            },
          });
        } else {
          // upsertRelease is always true for the first submission so we don't care about in the below code
          if (confirmationData?.confirmationRequired) {
            await submitCreateWorkOrderConfirmation({
              confirmationData,
              updatedRecord,
              transactionType:
                UsConsumptionEntryConfirmationTransactionType.UPSERT_US_CONSUMPTION_ENTRY_SUMMARY_CBP,
              transactionInput: { upsertRelease: true },
            });
          } else {
            await executeMutation({
              mutation: upsertUsConsumptionEntrySummaryCbp,
              variables: {
                usConsumptionEntryId: updatedRecord.id,
                input: { upsertRelease: true },
              },
            });
          }
        }
      },
      onConfirm: ({ dispatch, isValid }) =>
        new Promise<AppRecordOnConfirmPayload>((resolve, reject) => {
          if (isValid) {
            dispatch(
              setModal({
                key: ModalKey.VALIDATE_US_ENTRY_SUMMARY,
                props: {
                  visible: true,
                  sendConfirmationDecision: resolve,
                  updatingEntry: false,
                  transactionType:
                    UsConsumptionEntryConfirmationTransactionType.UPSERT_US_CONSUMPTION_ENTRY_SUMMARY_CBP,
                  transactionInput: { upsertRelease: true },
                },
              }),
            );
          } else {
            return resolve({ canceled: true });
          }
        }),
    },
  ],
  editButtons: [
    {
      key: AppRecordEditMode.UPDATE_ENTRY,
      text: 'Update entry',
      show: ({ existingRecord, workOrderTaskType }) =>
        !isEntryUnderCBPControl(existingRecord) &&
        showSubmitButton(workOrderTaskType) &&
        hasEntrySummaryStatus(existingRecord) &&
        !isRecordDeleted(existingRecord) &&
        !isRecordCanceled(existingRecord) &&
        !isRecordReplaced(existingRecord) &&
        (!existingRecord?.dailyStatement?.number ||
          existingRecord?.summaryNotification?.type ===
            'ENTRY_SUMMARY_REJECTED'),
    },
    {
      key: AppRecordEditMode.UPDATE_RELEASE,
      text: 'Update bill',
      show: ({ existingRecord, workOrderTaskType }) =>
        showSubmitButton(workOrderTaskType) &&
        hasEntrySummaryStatus(existingRecord) &&
        hasEntryReleaseStatus(existingRecord) &&
        !isRecordDeleted(existingRecord) &&
        !isRecordCanceled(existingRecord) &&
        !isRecordReplaced(existingRecord),
    },
    {
      key: AppRecordEditMode.UPDATE_EXAM_INFO,
      text: 'Update Exam Info',
      show: ({ existingRecord, workOrderTaskType }) =>
        isExamOrHoldWorkOrderTask(workOrderTaskType) &&
        showSubmitButton(workOrderTaskType) &&
        hasEntrySummaryStatus(existingRecord) &&
        hasEntryReleaseStatus(existingRecord) &&
        !isRecordDeleted(existingRecord) &&
        !isRecordCanceled(existingRecord) &&
        !isRecordReplaced(existingRecord),
    },
  ],
  defaultEditMode: false,
  draftEditMode: ({ existingRecord }) => {
    return !existingRecord?.summaryStatus;
  },
  getAdditionalWorkOrderInfo: ({ record }) => {
    const { entryNumber, releaseStatus, summaryStatus } = record || {};

    return [
      {
        label: 'Entry #',
        value: entryNumber,
      },
      {
        label: 'Summary status',
        value: capitalCase(summaryStatus || ''),
      },
      {
        label: 'Release status',
        value: capitalCase(releaseStatus || ''),
      },
    ];
  },
  subscriptionOptions: {
    fields: subscriptionFields,
    fragments: subscriptionFragments,
  },
  downloadReferenceField: 'invoicesReference',
};

export default usConsumptionEntryRoute;
