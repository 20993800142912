import React, { useState } from 'react';
import { Form, message, Modal, Button } from 'antd';
import { shouldUpdate, useModal } from '@xbcb/ui-utils';
import { CssSize } from '@xbcb/ui-types';
import { useMutation } from '@apollo/client';
import { RecordType, KeywordSearchOperator } from '@xbcb/shared-types';
import type { CreateUsIorContinuousBondRequestInput } from '@xbcb/api-gateway-client';
import RecordSelect from 'components/RecordSelect';
import { v4 as uuidV4 } from 'uuid';
import { StyledActionsDiv, StyledForm } from './styles';
import { ModalKey } from 'types';
import { createOneMutation } from '@xbcb/shared-queries';
import { formatRecordName } from '@xbcb/js-utils';
import { useCurrentUser } from 'libs/hooks';
import UsIorContinuousBondRequestInfoFields from 'components/UsIorContinuousBondRequestInfoFields';

// Accounts for the widest row (the top row that contains two
// RecordSelects) that has width of 512, plus the padding on both sides
// of the modal (24 px each), plus the margin-left (16px) on the form to
// accommodate the trailing right margin of the form items.
// Thus, 512 + 48 + 16 = 576 allows for a perfectly centered modal
const MODAL_WIDTH = 512 + 48 + 16;

type CreateUsIorContinuousBondRequestModalProps = {
  record?: { id: string };
  // `recordType` indicates the type of record being passed in
  recordType?: typeof RecordType.US_IOR | typeof RecordType.SHIPPER;
};

/*
TODO the properties are optional because createButtonModal does not pass properties. 
Modify either this modal or the createButtonModal to pass in ModalProps
*/
const CreateUsIorContinuousBondRequestModal: React.FC<
  CreateUsIorContinuousBondRequestModalProps
> = ({ record, recordType }) => {
  const isShipperPage = recordType === RecordType.SHIPPER;
  const isIorPage = recordType === RecordType.US_IOR;

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { visible, closeModal } = useModal(
    ModalKey.CREATE_US_IOR_CONTINUOUS_BOND_REQUEST,
  );
  const createMutation = createOneMutation({
    recordName: RecordType.US_IOR_CONTINUOUS_BOND_REQUEST,
    fields: `
      id
    `,
  });
  const [createRecord] = useMutation(createMutation);
  const { accountType, shipper } = useCurrentUser();

  const formattedRecordName = formatRecordName({
    recordType: RecordType.US_IOR_CONTINUOUS_BOND_REQUEST,
    accountType,
  });

  const createUsIorContinuousBondRequest = async (
    input: CreateUsIorContinuousBondRequestInput,
  ) => {
    const { errors } = await createRecord({
      variables: {
        idempotencyKey: uuidV4(),
        input,
      },
    });
    if (errors) {
      message.error(
        `Sorry, the submission failed with error ${errors.join(
          ', ',
        )}, please try again later.`,
      );
    } else {
      message.success(`Created ${formattedRecordName} successfully.`);
    }
  };

  const closeModalAndResetFields = () => {
    form.resetFields();
    closeModal();
  };

  const handleUpdate = async () => {
    try {
      await form.validateFields();
    } catch (e) {
      message.error('Please fill required fields');
      return;
    }

    const {
      shipper: shipperFieldObj,
      usIor: usIorFieldObj,
      ...rest
    } = form.getFieldsValue();
    setIsLoading(true);

    const input = {
      ...rest,
      shipper: { id: !isShipperPage ? shipperFieldObj.id : record?.id },
      usIor: { id: !isIorPage ? usIorFieldObj.id : record?.id },
    };

    try {
      await createUsIorContinuousBondRequest(input);
    } catch (e) {
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    closeModalAndResetFields();
  };

  const shipperBaseNamePath = ['shipper'];
  const usIorBaseNamePath = ['usIor'];

  // Some custom logic is needed when shipper users are logged in as we don't
  // want to allow shipper users to select other shippers and we also don't
  // want to search for other shippers
  const shipperUserShipperId = shipper?.id;
  const shipperIdNamePath = [...shipperBaseNamePath, 'id'];
  // If there's a shipper user logged in and the shipper id is not already set,
  // set it here (as well as the name so we can see it as well)
  if (shipperUserShipperId && !form.getFieldValue(shipperIdNamePath)) {
    form.setFields([
      { name: shipperIdNamePath, value: shipperUserShipperId },
      { name: [...shipperBaseNamePath, 'name'], value: shipper?.name },
    ]);
  }
  const shipperUserGetCustomLabel = () => shipper?.name;

  return (
    <Modal
      open={visible}
      width={MODAL_WIDTH}
      footer={null}
      closable={false}
      onCancel={closeModalAndResetFields}
    >
      <StyledForm form={form}>
        <h3>Request {formattedRecordName}</h3>
        {!isShipperPage && (
          <RecordSelect
            label={formatRecordName({
              recordType: RecordType.SHIPPER,
              accountType,
            })}
            fullNamePath={shipperBaseNamePath}
            recordType={RecordType.SHIPPER}
            $itemSize={CssSize.SHORT}
            required
            form={form}
            // Fetch the usIors so that we can use then below to determine how
            // many possible usIors there are
            fieldsToFetchForSelectedRecord="usIors { nodes { id } }"
            // Shipper users should not be able to change the shipper
            disabled={shipperUserShipperId}
            readOnly={shipperUserShipperId}
            // Shipper users should not search for other shippers or PA will
            // throw an unauthorized error
            skipSearch={shipperUserShipperId}
            // Always show the shipper's name if a shipper user is signed in
            getCustomLabel={shipperUserShipperId && shipperUserGetCustomLabel}
          />
        )}
        <Form.Item noStyle shouldUpdate={shouldUpdate([shipperBaseNamePath])}>
          {() => {
            // This will include the `id` field as well as the `usIors` field
            // since we added it to `fieldsToFetchForSelectedRecord` above
            const shipper = form.getFieldValue(shipperBaseNamePath);
            // If there's only one associated IOR, set it. Note: This would
            // only be applicable on the table page, otherwise the shipper id
            // RecordSelect does not display. Thus, even if there is only one
            // option while on the shipper page, the user would have to still
            // select that option. Additionally, we cannot add this check on
            // the `onChange` for the Shipper RecordSelect above because the
            // `onChange` handler is called before the fields inside
            // `fieldsToFetchForSelectedRecord` are fetched and returned.
            const usIors = shipper?.usIors?.nodes;
            if (usIors?.length === 1) {
              form.setFields([
                {
                  name: [...usIorBaseNamePath, 'id'],
                  value: usIors[0].id,
                },
              ]);
            }
            // We need to know the Shipper ID to determine whether or not we
            // should add `additionalSearchCriteria` for the IORs. We should
            // use the selected shipper's ID (i.e. shipper?.id) if there is
            // one. If there is not a shipperId then if we are on the shipper
            // page use the ID of the record as we know it will be added before
            // we attempt to create the cont bond
            const shipperId =
              shipper?.id || (isShipperPage ? record?.id : undefined);
            return (
              !isIorPage && (
                <RecordSelect
                  label={formatRecordName({
                    recordType: RecordType.US_IOR,
                    accountType,
                  })}
                  fullNamePath={usIorBaseNamePath}
                  recordType={RecordType.US_IOR}
                  $itemSize={CssSize.SHORT}
                  required
                  form={form}
                  additionalSearchCriteria={
                    shipperId && {
                      shipperId: {
                        values: [shipperId],
                        operator: KeywordSearchOperator.EQUALS,
                      },
                    }
                  }
                />
              )
            );
          }}
        </Form.Item>
        <UsIorContinuousBondRequestInfoFields
          form={form}
          readOnly={isLoading}
          disabled={isLoading}
          $displayHelperTextInSeparateLine
        />
        <StyledActionsDiv>
          <Button loading={isLoading} onClick={closeModalAndResetFields}>
            Cancel
          </Button>
          <Button loading={isLoading} onClick={handleUpdate} type="primary">
            Submit
          </Button>
        </StyledActionsDiv>
      </StyledForm>
    </Modal>
  );
};

export default CreateUsIorContinuousBondRequestModal;
