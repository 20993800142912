import { AccountType, AnyObject, TagKey } from '@xbcb/shared-types';
import {
  WorkOrderType,
  getCountryAbbreviationFromWorkOrderType,
  WorkOrderStatus,
  WorkOrderMilestoneName,
  EuCountryCodes,
} from '@xbcb/work-order-types';
import {
  workOrderTaskFragments,
  workOrderTaskStepFragments,
} from '@xbcb/work-order-queries';
import { isEuIorStatusNonMutable } from '@xbcb/party-utils';
import { WorkOrderRoute } from '.';
import { pascalCase, capitalCase } from 'change-case';
import { DocumentTag } from '@xbcb/document-types';
import { setModal, showValidationErrors } from '@xbcb/ui-utils';
import { executeMutation } from 'libs/executeMutation';
import { AppRecordOnConfirmPayload, AppRecordProps } from 'routes';
import { ModalKey } from 'types';
import {
  getEuIorRecordType,
  EuIorType,
} from 'components/EuIorActivationSummary/component';
import { message } from 'antd';
import { ActivationStatus } from '@xbcb/party-types';
import {
  createWorkOrderMilestoneMutation,
  getIorVersion,
  updateEuIorActivationStatus,
} from 'libs/sharedQueries';
import { v4 } from 'uuid';
import { validateAddress } from 'libs/validateAddress';

const additionalfields = `
  tasks {
    ...workOrderTaskFields
    assignee {
      id
      name
    }
    steps {
      id
      name
      description
      completed {
        time
      }
      isCompletedBySystem
      isFinalConfirmation
    }
    definition {
      id
      workOrderTaskType
    }
  }
`;

const validateEuPoa = (euIor: EuIorType) => {
  const additionalErrors = [];
  let hasMaster = false;
  let hasSub = false;
  const { documents = [] } = euIor || {};
  documents.forEach(({ documentTags = [], deleted, tags = [] }) => {
    const rejected = tags.find((tag) => tag.key === TagKey.REJECTED);
    if (documentTags.length && !deleted && !rejected) {
      for (const documentTag of documentTags) {
        switch (documentTag) {
          case DocumentTag.MASTER_POWER_OF_ATTORNEY: {
            hasMaster = true;
            break;
          }
          case DocumentTag.SUB_POWER_OF_ATTORNEY: {
            hasSub = true;
            break;
          }
          default: {
            break;
          }
        }
      }
    }
  });

  if (!hasMaster || !hasSub) {
    additionalErrors.push({
      title: 'Missing POA',
      messages: [`Importer of Record needs a Power of Attorney.`],
    });
  }
  return additionalErrors;
};

// Here we need to validate the fields that are not required on the IOR's page but are required for activation
const validateEuActivationFields = (euIor: EuIorType) => {
  const { addresses, vatNumber, eori } = euIor;

  const additionalErrors = [
    // First add the validations for addresses
    ...validateAddress({
      addressType: 'mailing',
      addressObject: addresses?.mailing,
      shouldValidateStateCode: false,
    }),
    ...validateAddress({
      addressType: 'physical',
      addressObject: addresses?.physical,
      shouldValidateStateCode: false,
    }),
  ];
  // VAT Number and EORI is required for ops, whatever fields are required for import
  // sign need to be validated here as well
  if (!vatNumber) {
    additionalErrors.push({
      title: 'Missing VAT Number',
      messages: ['Importer of Record must have a VAT Number'],
    });
  }

  if (!eori) {
    additionalErrors.push({
      title: 'Missing EORI',
      messages: ['Importer of Record must have a EORI'],
    });
  }
  return additionalErrors;
};

const showActivationButtons = (
  existingRecord: AnyObject,
  currentUser: AnyObject,
) => {
  const isOperator = currentUser.accountType === AccountType.OPERATOR;
  // If the WO is completed, blocked, or canceled, don't allow activation
  const invalidWorkOrderStatus = [
    WorkOrderStatus.COMPLETED,
    WorkOrderStatus.BLOCKED,
    WorkOrderStatus.CANCELED,
  ].includes(existingRecord?.status);
  const taskCreated = Boolean(existingRecord?.tasks.length);
  const invalidIorStatus = isEuIorStatusNonMutable({
    ior: existingRecord?.ior?.ior,
    customsBrokerId: existingRecord?.broker?.customsBroker?.id,
  });

  return (
    isOperator &&
    !invalidIorStatus &&
    !existingRecord.deleted?.time &&
    !invalidWorkOrderStatus &&
    taskCreated
  );
};

type EuIorActivationRoute = Pick<
  AppRecordProps,
  'recordType' | 'fields' | 'fragments'
> & {
  page: React.FC<any>;
};

const euIorActivationRoute = ({
  page,
  recordType,
  fields,
  fragments,
}: EuIorActivationRoute): WorkOrderRoute => {
  const countryCode = getCountryAbbreviationFromWorkOrderType(
    pascalCase(recordType) as WorkOrderType,
  ) as EuCountryCodes;
  const euIorRecordType = getEuIorRecordType(recordType);
  return {
    Page: page,
    recordType,
    fields: `${fields}${additionalfields}`,
    fragments: `${
      fragments || ''
    }${workOrderTaskFragments}${workOrderTaskStepFragments}`,
    // OPERATOR users should not be able to edit the IOR Activation WO (EU Countries)
    hideDefaultEditButton: ({ accountType }) => {
      return accountType === AccountType.OPERATOR;
    },
    // OPERATOR users should not be able to delete the IOR Activation WO (EU Countries)
    hideDeleteKebabMenuItem: ({ accountType }) => {
      return accountType === AccountType.OPERATOR;
    },
    disableCancel: true,
    submitButtons: [
      {
        key: `activate${capitalCase(countryCode)}Ior`,
        text: 'Approve',
        skipUpdateRecord: () => true,
        skipValidation: () => true,
        show: ({ existingRecord, currentUser }) => {
          return showActivationButtons(existingRecord, currentUser);
        },
        onSubmit: async ({ updatedRecord }) => {
          const euIor = updatedRecord?.ior?.ior;
          const additionalErrors = [
            ...validateEuActivationFields(euIor),
            ...validateEuPoa(euIor),
          ];
          if (additionalErrors?.length) {
            showValidationErrors(additionalErrors);
            return;
          } else {
            const version = await getIorVersion(
              euIor.id,
              euIorRecordType,
              countryCode,
            );
            await executeMutation({
              mutation: updateEuIorActivationStatus(countryCode),
              successMessage: 'Importer of Record Activated',
              variables: {
                id: euIor?.id,
                version: version || euIor.version,
                activationInput: {
                  customsBroker: {
                    id: updatedRecord.broker.customsBroker.id,
                  },
                  status: ActivationStatus.ACTIVATED,
                },
              },
            });
            await executeMutation({
              mutation: createWorkOrderMilestoneMutation,
              successMessage: 'Milestone Created',
              variables: {
                idempotencyKey: v4(),
                input: {
                  allowDuplicate: false,
                  name: WorkOrderMilestoneName.IOR_ACTIVATED,
                  workOrder: {
                    id: updatedRecord.id,
                    version: updatedRecord.version,
                  },
                },
              },
            });
          }
        },
      },
      {
        key: `reject${capitalCase(countryCode)}Ior`,
        text: 'Reject',
        skipUpdateRecord: () => true,
        skipValidation: () => true,
        show: ({ existingRecord, currentUser }) => {
          return showActivationButtons(existingRecord, currentUser);
        },
        onConfirm: ({ dispatch, isValid }) =>
          new Promise<AppRecordOnConfirmPayload>((resolve, reject) => {
            if (isValid) {
              dispatch(
                setModal({
                  key: ModalKey.REJECT_IOR_ACTIVATION,
                  props: {
                    visible: true,
                    sendConfirmationDecision: resolve,
                  },
                }),
              );
            } else {
              return resolve({ canceled: true });
            }
          }),
        onSubmit: async ({ updatedRecord, confirmationData }) => {
          const euIor = updatedRecord?.ior?.ior;
          const { cancellationReason } = confirmationData || {};
          if (!cancellationReason) {
            message.error(`Please fill out all the fields`);
            return;
          }
          const version = await getIorVersion(
            euIor.id,
            euIorRecordType,
            countryCode,
          );
          await executeMutation({
            mutation: updateEuIorActivationStatus(countryCode),
            successMessage: 'Importer of Record Rejected',
            variables: {
              id: euIor?.id,
              version: version || euIor.version,
              activationInput: {
                customsBroker: {
                  id: updatedRecord.broker.customsBroker.id,
                },
                status: ActivationStatus.REJECTED,
                rejectionReasons: cancellationReason,
              },
            },
          });
          await executeMutation({
            mutation: createWorkOrderMilestoneMutation,
            successMessage: 'Milestone Created',
            variables: {
              idempotencyKey: v4(),
              input: {
                allowDuplicate: false,
                name: WorkOrderMilestoneName.IOR_REJECTED,
                workOrder: {
                  id: updatedRecord.id,
                  version: updatedRecord.version,
                },
              },
            },
          });
        },
      },
    ],
  };
};

export default euIorActivationRoute;
