import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Form, Button, Divider } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { capitalCase } from 'change-case';
import type { InvoiceFeeDetails } from '@xbcb/api-gateway-client';
import { MinusButton } from '@xbcb/button-components';
import { ChargeCode } from '@xbcb/finance-types';
import { FormItem, Select, Option } from '@xbcb/form-item-components';
import { NamePath, CssSize } from '@xbcb/ui-types';
import { isRequired, shouldUpdate, show } from '@xbcb/ui-utils';
import CurrencyField from 'components/CurrencyField';

export type BillingDetailsCustomFeesProps = {
  form: FormInstance;
  disabled?: boolean;
  readOnly?: boolean;
  fullNamePath: NamePath;
};

const BillingDetailsCustomFees: React.FC<BillingDetailsCustomFeesProps> = ({
  form,
  disabled,
  readOnly,
  fullNamePath,
}: BillingDetailsCustomFeesProps) => {
  if (!show({ readOnly, form, field: fullNamePath })) return null;
  return (
    <>
      <Form.List name={fullNamePath} minimum={1}>
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map((field, index) => {
                const baseFullNamePath = [...fullNamePath, field.name];
                const valueFullNamePath = [...baseFullNamePath, 'value'];
                const typeFullNamePath = [...baseFullNamePath, 'type'];
                const fieldsToCheck = [typeFullNamePath, valueFullNamePath];
                const getSomeSubFieldsFilledOut = () =>
                  isRequired(form, fieldsToCheck);
                const showHeader =
                  index === 0 && (!readOnly || getSomeSubFieldsFilledOut());
                const $itemSize = CssSize.TINY_SHORT;
                return (
                  <>
                    {showHeader && <h4>Custom Fees</h4>}
                    <Form.Item
                      shouldUpdate={shouldUpdate(fieldsToCheck)}
                      noStyle
                      key={field.key}
                    >
                      {() => {
                        const someSubFieldsFilledOut =
                          getSomeSubFieldsFilledOut();
                        if (readOnly && !someSubFieldsFilledOut) return null;
                        return (
                          <div>
                            <FormItem
                              label="Type"
                              name={[field.name, 'type']}
                              // If value is filled out the type is required
                              required={Boolean(
                                form.getFieldValue(valueFullNamePath),
                              )}
                              $inline
                              $itemSize={$itemSize}
                            >
                              <Select
                                disabled={disabled}
                                optionLabelProp="children"
                                dropdownMatchSelectWidth={false}
                              >
                                {Object.values(ChargeCode).map((code) => {
                                  const customFees = form.getFieldValue(
                                    fullNamePath,
                                  ) as InvoiceFeeDetails[];
                                  // If there's another custom fee with this
                                  // code we don't want to display this option
                                  // because we only allow one custom fee for
                                  // each type (ChargeCode)
                                  const existingCustomFee = customFees.find(
                                    ({ type }, index) =>
                                      type === code && index !== field.name,
                                  );
                                  if (existingCustomFee) return null;
                                  return (
                                    <Option key={code} value={code}>
                                      {capitalCase(code)}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </FormItem>
                            <CurrencyField
                              label="Value"
                              form={form}
                              fullNamePath={valueFullNamePath}
                              localNamePath={[field.name, 'value']}
                              // If type is filled out the type is required
                              required={Boolean(
                                form.getFieldValue(typeFullNamePath),
                              )}
                              $itemSize={CssSize.TINY}
                              disabled={disabled}
                            />
                            {!readOnly && fields.length > 1 && (
                              <MinusButton
                                label="custom fee"
                                firstRow
                                onRemove={() => remove(field.name)}
                                disabled={disabled}
                              />
                            )}
                          </div>
                        );
                      }}
                    </Form.Item>
                  </>
                );
              })}
              {!readOnly && (
                <Button type="dashed" onClick={add} disabled={disabled}>
                  <PlusOutlined />
                  Custom Fee
                </Button>
              )}
              {fields.length && <Divider />}
            </>
          );
        }}
      </Form.List>
    </>
  );
};

export default BillingDetailsCustomFees;
