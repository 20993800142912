import { lazy } from 'react';

export const Classifications = lazy(
  () => import(/* webpackChunkName: 'UsInBond' */ 'components/Classifications'),
);
export const UsInBond = lazy(
  () => import(/* webpackChunkName: 'UsInBond' */ 'components/UsInBond'),
);
export const UsIorContinuousBondRequest = lazy(
  () =>
    import(
      /* webpackChunkName: 'UsIorContinuousBondRequest' */ 'components/UsIorContinuousBondRequest'
    ),
);

export const UsIorActivation = lazy(
  () =>
    import(
      /* webpackChunkName: 'UsIorActivation' */ 'components/UsIorActivation'
    ),
);

export const EuIorActivation = lazy(
  () =>
    import(
      /* webpackChunkName: 'EuIorActivation' */ 'components/EuIorActivation'
    ),
);

export const DeliveryOrder = lazy(
  () =>
    import(/* webpackChunkName: 'DeliveryOrder' */ 'components/DeliveryOrder'),
);

export const GbCustomsEntry = lazy(
  () =>
    import(/* webpackChunkName: 'GbCustomsEntry' */ 'components/CustomsEntry'),
);

export const NlCustomsEntry = lazy(
  () =>
    import(/* webpackChunkName: 'NlCustomsEntry' */ 'components/CustomsEntry'),
);

export const DeCustomsEntry = lazy(
  () =>
    import(/* webpackChunkName: 'DeCustomsEntry' */ 'components/CustomsEntry'),
);

export const FrCustomsEntry = lazy(
  () =>
    import(/* webpackChunkName: 'FrCustomsEntry' */ 'components/CustomsEntry'),
);

export const CustomsDeclaration = lazy(
  () =>
    import(
      /* webpackChunkName: 'CustomsDeclaration' */ 'components/CustomsEntry'
    ),
);

export const UsConsumptionEntry = lazy(
  () =>
    import(
      /* webpackChunkName: 'UsConsumptionEntry' */ 'components/UsConsumptionEntry'
    ),
);
export const UsPostSummaryCorrection = lazy(
  () =>
    import(
      /* webpackChunkName: 'UsPostSummaryCorrection' */ 'components/UsPostSummaryCorrection'
    ),
);
export const UsIsf = lazy(
  () => import(/* webpackChunkName: 'UsIsf' */ 'components/UsIsf'),
);
export const Trucker = lazy(
  () => import(/* webpackChunkName: 'Trucker' */ 'components/Trucker'),
);
export const Product = lazy(
  () => import(/* webpackChunkName: 'Product' */ 'components/Product'),
);
export const UsIor = lazy(
  () => import(/* webpackChunkName: 'UsIor' */ 'components/UsIor'),
);
export const EuIor = lazy(
  () => import(/* webpackChunkName: 'EuIor' */ 'components/EuIor'),
);
export const OnboardUsIor = lazy(
  () => import(/* webpackChunkName: 'UsIor' */ 'components/OnboardUsIor'),
);
export const Supplier = lazy(
  () => import(/* webpackChunkName: 'Supplier' */ 'components/Supplier'),
);
export const Shipper = lazy(
  () => import(/* webpackChunkName: 'Shipper' */ 'components/Shipper'),
);
export const Facility = lazy(
  () => import(/* webpackChunkName: 'Facility' */ 'components/Facility'),
);

export const Forwarder = lazy(
  () => import(/* webpackChunkName: 'Forwarder' */ 'components/Forwarder'),
);

export const Consignee = lazy(
  () => import(/* webpackChunkName: 'Consignee' */ 'components/Consignee'),
);

export const CustomsAgent = lazy(
  () =>
    import(/* webpackChunkName: 'CustomsAgent' */ 'components/CustomsAgent'),
);

export const Shipment = lazy(
  () => import(/* webpackChunkName: 'Shipment' */ 'components/Shipment'),
);

export const Invoice = lazy(
  () => import(/* webpackChunkName: 'Invoice' */ 'components/Invoice'),
);

export const ReportReconciliationRequest = lazy(
  () =>
    import(
      /* webpackChunkName: 'ReportReconciliationRequest' */ 'components/ReportReconcilationRequest'
    ),
);

export const BulkMilestoneUploadRequest = lazy(
  () =>
    import(
      /* webpackChunkName: 'BulkMilestoneUploadRequest' */ 'components/BulkMilestoneUploadRequest'
    ),
);

export const BulkChargesUploadRequest = lazy(
  () =>
    import(
      /* webpackChunkName: 'BulkChargesUploadRequest' */ 'components/BulkChargesUploadRequest'
    ),
);

export const BulkComplianceUploadRequest = lazy(
  () =>
    import(
      /* webpackChunkName: 'BulkComplianceUploadRequest' */ 'components/BulkComplianceUploadRequest'
    ),
);

export const DocumentTemplate = lazy(
  () =>
    import(/* webpackChunkName: 'DocumentTemplate' */ 'components/Template'),
);

export const UserProfile = lazy(
  () => import(/* webpackChunkName: 'UserProfile' */ 'components/ProfileForm'),
);

export const User = lazy(
  () => import(/* webpackChunkName: 'User' */ 'components/User'),
);

export const Team = lazy(
  () => import(/* webpackChunkName: 'Team' */ 'components/Team'),
);

export const BusinessSupportTeam = lazy(
  () =>
    import(
      /* webpackChunkName: 'BusinessSupportTeam' */ 'components/BusinessSupportTeam'
    ),
);

export const SubjectMatterExpertTeam = lazy(
  () =>
    import(
      /* webpackChunkName: 'SubjectMatterExpertTeam' */ 'components/SubjectMatterExpertTeam'
    ),
);

export const CompanySettings = lazy(
  () => import(/* webpackChunkName: 'Company' */ 'components/CompanySettings'),
);

export const GlobalSearchTable = lazy(
  () =>
    import(
      /* webpackChunkName: 'GlobalSearchTable' */ 'components/GlobalSearchTable'
    ),
);

export const OnboardingDashboard = lazy(
  () =>
    import(
      /* webpackChunkName: 'OnboardingDashboard' */ 'components/OnboardingDashboard'
    ),
);

export const PendingDashboard = lazy(
  () =>
    import(
      /* webpackChunkName: 'OnboardingDashboard' */ 'components/PendingDashboard'
    ),
);

export const Tutorials = lazy(
  () => import(/* webpackChunkName: 'Tutorials' */ 'components/Tutorials'),
);

export const Queries = lazy(
  () =>
    // TODO rename the component to Queries before merging
    import(/* webpackChunkName: 'Queries' */ 'components/Queries'),
);

export const UploadSpreadsheet = lazy(
  () =>
    import(
      /* webpackChunkName: 'UploadSpreadsheet' */ 'components/UploadSpreadsheet'
    ),
);

export const UsType86Entry = lazy(
  () =>
    import(/* webpackChunkName: 'UsType86Entry' */ 'components/UsType86Entry'),
);

export const AssignmentWorkspace = lazy(
  () =>
    import(
      /* webpackChunkName: 'AssignmentWorkspace' */ 'components/AssignmentWorkspace'
    ),
);

export const EscalationMessage = lazy(
  () =>
    import(
      /* webpackChunkName: 'EscalationMessage' */ 'components/EscalationMessage'
    ),
);

export const ProcessingError = lazy(
  () =>
    import(
      /* webpackChunkName: 'ProcessingError' */ 'components/ProcessingError'
    ),
);
