import React from 'react';
import 'libs/inlinePolyfills';
import { render } from 'react-dom';
import { register as registerServiceWorker } from 'libs/registerAppServiceWorker';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from 'styled-components';
import { client } from '@xbcb/apollo-client';
import { Provider } from 'react-redux';
import create from './store';
import ScrollToTop from './scroll';
// APP has to be imported AFTER reduxStore to avoid circular dependency issues (only when building production site)
import App from 'components/App';
import { polyfillLoader } from 'polyfill-io-feature-detection';
import { isAglPortal } from '@xbcb/ui-env';
import { UiStage } from '@xbcb/ui-types';
import { newContent } from 'actions';
import { getEnv } from '@xbcb/ui-utils';
import { primaryTheme } from '@xbcb/ui-styles';
import { awsRumClient } from '@xbcb/cloudwatch-rum';
import DevTools from 'devtools/DevTools';

const { stage, version } = getEnv();
export const store = create();

// Let the compiler know we expect this on the interface
declare global {
  interface Window {
    Cypress?: unknown;
  }
}
if (!window.Cypress) {
  awsRumClient.initClient(stage, version);
}

/**
 * starts react
 */
function main() {
  const store = create();
  const isAgl = isAglPortal();
  const { REACT_APP_STAGE } = process.env;
  if (REACT_APP_STAGE) {
    if (REACT_APP_STAGE !== UiStage.PROD) {
      window.dispatch = store.dispatch;
    }
    // await initializeApolloCache();
    // Typed client argument in ApolloProvider to any because
    // https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/362#issuecomment-496773827
    render(
      <Provider store={store}>
        <ApolloProvider client={client as any}>
          <BrowserRouter basename={isAgl ? '/cbms' : undefined}>
            <ThemeProvider theme={primaryTheme}>
              <ScrollToTop>
                <App />
              </ScrollToTop>
            </ThemeProvider>
          </BrowserRouter>
        </ApolloProvider>
        {REACT_APP_STAGE !== UiStage.PROD ? <DevTools /> : null}
      </Provider>,
      document.getElementById('root'),
    );
    registerServiceWorker({
      onUpdate: async (registration) => {
        // We want to run this code only if we detect a new service worker is
        // waiting to be activated.
        // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
        if (registration && registration.waiting) {
          // Makes Workbox call skipWaiting()
          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        }
        store.dispatch(newContent());
      },
    });
  } else {
    throw new Error('REACT_APP_STAGE not set in environment');
  }
}

// We used to do a manual conditional check here before calling polyfillLoader, but pollyfillLoader does this check for us so it's redundant.
polyfillLoader({
  features:
    'fetch,Set,Array.from,Element.prototype.prepend,String.prototype.includes,Array.prototype.includes,Object.entries,Object.values,String.prototype.startsWith,String.prototype.endsWith,Array.prototype.find,String.fromCodePoint',
  onCompleted: main,
});
