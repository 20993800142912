import React from 'react';
import { Input } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { NamePath } from 'rc-field-form/lib/interface';
import { getNumber, show } from '@xbcb/ui-utils';
import { CssSize, DataCyPrefix } from '@xbcb/ui-types';
import { StyledDiv, StyledFormItem, StyledTooltip } from './styles';

interface FEINumberProps {
  disabled?: boolean;
  readOnly?: boolean;
  form: FormInstance;
  $inline?: boolean;
  field?: NamePath;
}

const FEINumber = ({
  disabled,
  readOnly,
  form,
  $inline,
  field = 'fei',
}: FEINumberProps) => {
  const showComponent = show({ readOnly, form, field });
  return showComponent ? (
    <StyledDiv $inline={$inline}>
      <StyledFormItem
        $itemSize={CssSize.TINY_SHORT}
        name={field}
        rules={[{ required: false, message: ' ', min: 4, max: 11 }]}
        getValueFromEvent={getNumber(11)}
        label="FDA FEI Number"
        $readOnly={readOnly}
        data-cy={DataCyPrefix.FEI_NUMBER}
      >
        <Input placeholder="NNNNNNNNNNN" disabled={disabled} />
      </StyledFormItem>
      <StyledTooltip
        spaceTop
        title="The Food Establishment Identifier (FEI) is used to identify facilities that receive or hold food products for monitoring and inspection purposes."
      />
    </StyledDiv>
  ) : null;
};

export default FEINumber;
