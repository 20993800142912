import { RecordType } from '@xbcb/shared-types';
import {
  EuAbacusReasonCode,
  UsIsfInquiryReasonCode,
  UsConsumptionEntryInquiryReasonCode,
  UsType86EntryInquiryReasonCode,
  UsIorActivationInquiryReasonCode,
  UsInBondInquiryReasonCode,
  EuCustomsEntryInquiryReasonCode,
} from '@xbcb/work-order-types';

// TODO consider not prefixing with the WO type, could change `reasonCodeMap`
// below to first categorize by WO type
export enum InquiryReasonCategory {
  ISF_DOCUMENTATION = 'ISF_DOCUMENTATION',
  US_IN_BOND_COMMERCIAL_INVOICE_AND_PACKING_LIST_DATA = 'US_IN_BOND_COMMERCIAL_INVOICE_AND_PACKING_LIST_DATA',
  US_IN_BOND_TRANSPORTATION_INFO = 'US_IN_BOND_TRANSPORTATION_INFO',
  US_CONSUMPTION_ENTRY_COMMERCIAL_INVOICE_AND_PACKING_LIST_DATA = 'US_CONSUMPTION_ENTRY_COMMERCIAL_INVOICE_AND_PACKING_LIST_DATA',
  US_CONSUMPTION_ENTRY_PGA_DOCUMENTATION = 'US_CONSUMPTION_ENTRY_PGA_DOCUMENTATION',
  US_CONSUMPTION_ENTRY_PRODUCT_DATA = 'US_CONSUMPTION_ENTRY_PRODUCT_DATA',
  US_CONSUMPTION_ENTRY_TRANSPORTATION_INFO = 'US_CONSUMPTION_ENTRY_TRANSPORTATION_INFO',
  US_CONSUMPTION_ENTRY_OTHER = 'US_CONSUMPTION_ENTRY_OTHER',
  US_CONSUMPTION_ENTRY_TECH_FIX = 'US_CONSUMPTION_ENTRY_TECH_FIX',
  ENTRY_SUMMARY_REJECTIONS = 'ENTRY_SUMMARY_REJECTIONS',
  US_CONSUMPTION_ENTRY_PGA_REJECTIONS = 'US_CONSUMPTION_ENTRY_PGA_REJECTIONS',
  US_CONSUMPTION_ENTRY_CBP_HOLD_AND_EXAM = 'US_CONSUMPTION_ENTRY_CBP_HOLD_AND_EXAM',
  US_CONSUMPTION_ENTRY_PAYMENT_PROCESSING = 'US_CONSUMPTION_ENTRY_PAYMENT_PROCESSING',
  US_TYPE86_ENTRY_COMMERCIAL_INVOICE_AND_PACKING_LIST_DATA = 'US_TYPE86_ENTRY_COMMERCIAL_INVOICE_AND_PACKING_LIST_DATA',
  US_TYPE86_ENTRY_PGA_DOCUMENTATION = 'US_TYPE86_ENTRY_PGA_DOCUMENTATION',
  US_TYPE86_ENTRY_PRODUCT_DATA = 'US_TYPE86_ENTRY_PRODUCT_DATA',
  US_TYPE86_ENTRY_TRANSPORTATION_INFO = 'US_TYPE86_ENTRY_TRANSPORTATION_INFO',
  US_TYPE86_ENTRY_OTHER = 'US_TYPE86_ENTRY_OTHER',
  US_TYPE86_ENTRY_TECH_FIX = 'US_TYPE86_ENTRY_TECH_FIX',
  CARGO_RELEASE_REJECTIONS = 'CARGO_RELEASE_REJECTIONS',
  US_TYPE86_ENTRY_PGA_REJECTIONS = 'US_TYPE86_ENTRY_PGA_REJECTIONS',
  US_TYPE86_ENTRY_CBP_HOLD_AND_EXAM = 'US_TYPE86_ENTRY_CBP_HOLD_AND_EXAM',
  US_TYPE86_ENTRY_PAYMENT_PROCESSING = 'US_TYPE86_ENTRY_PAYMENT_PROCESSING',
  MISSING_DOCUMENTATION = 'MISSING_DOCUMENTATION',
  REJECT_DOCUMENTATION = 'REJECT_DOCUMENTATION',
  EU_CUSTOMS_ENTRY_COMMERCIAL_INVOICE_AND_PACKING_LIST_DATA = 'EU_CUSTOMS_ENTRY_COMMERCIAL_INVOICE_AND_PACKING_LIST_DATA',
  EU_CUSTOMS_ENTRY_BILL_OF_LADING_DATA = 'EU_CUSTOMS_ENTRY_BILL_OF_LADING_DATA',
  REGULATORY_DOCUMENTS = 'REGULATORY_DOCUMENTS',
  IMPORT_CONTROL_DOCUMENTS = 'IMPORT_CONTROL_DOCUMENTS',
  OTHER_GOVERNMENT_AUTHORITY_DOCUMENTS = 'OTHER_GOVERNMENT_AUTHORITY_DOCUMENTS',
  FTA_DOCUMENTS = 'FTA_DOCUMENTS',
  GSP_DOCUMENTS = 'GSP_DOCUMENTS',
  SHIPMENT_DATA = 'SHIPMENT_DATA',
}

export const euInquiryReasonCategory = [
  InquiryReasonCategory.EU_CUSTOMS_ENTRY_COMMERCIAL_INVOICE_AND_PACKING_LIST_DATA,
  InquiryReasonCategory.EU_CUSTOMS_ENTRY_BILL_OF_LADING_DATA,
  InquiryReasonCategory.FTA_DOCUMENTS,
  InquiryReasonCategory.GSP_DOCUMENTS,
  InquiryReasonCategory.IMPORT_CONTROL_DOCUMENTS,
  InquiryReasonCategory.OTHER_GOVERNMENT_AUTHORITY_DOCUMENTS,
  InquiryReasonCategory.REGULATORY_DOCUMENTS,
];

export const euCustomsEntryInquiryReasonCodes = Object.values(
  EuCustomsEntryInquiryReasonCode,
);

export const typeCategoryMap: { [key: string]: string[] } = {
  [RecordType.US_ISF]: [InquiryReasonCategory.ISF_DOCUMENTATION],
  [RecordType.US_CONSUMPTION_ENTRY]: [
    InquiryReasonCategory.US_CONSUMPTION_ENTRY_COMMERCIAL_INVOICE_AND_PACKING_LIST_DATA,
    InquiryReasonCategory.US_CONSUMPTION_ENTRY_PGA_DOCUMENTATION,
    InquiryReasonCategory.US_CONSUMPTION_ENTRY_PRODUCT_DATA,
    InquiryReasonCategory.US_CONSUMPTION_ENTRY_TRANSPORTATION_INFO,
    InquiryReasonCategory.US_CONSUMPTION_ENTRY_OTHER,
    InquiryReasonCategory.US_CONSUMPTION_ENTRY_TECH_FIX,
    InquiryReasonCategory.ENTRY_SUMMARY_REJECTIONS,
    InquiryReasonCategory.US_CONSUMPTION_ENTRY_PGA_REJECTIONS,
    InquiryReasonCategory.US_CONSUMPTION_ENTRY_CBP_HOLD_AND_EXAM,
    InquiryReasonCategory.US_CONSUMPTION_ENTRY_PAYMENT_PROCESSING,
  ],
  [RecordType.US_TYPE86_ENTRY]: [
    InquiryReasonCategory.US_TYPE86_ENTRY_COMMERCIAL_INVOICE_AND_PACKING_LIST_DATA,
    InquiryReasonCategory.US_TYPE86_ENTRY_PGA_DOCUMENTATION,
    InquiryReasonCategory.US_TYPE86_ENTRY_PRODUCT_DATA,
    InquiryReasonCategory.US_TYPE86_ENTRY_TRANSPORTATION_INFO,
    InquiryReasonCategory.US_TYPE86_ENTRY_OTHER,
    InquiryReasonCategory.US_TYPE86_ENTRY_TECH_FIX,
    InquiryReasonCategory.CARGO_RELEASE_REJECTIONS,
    InquiryReasonCategory.US_TYPE86_ENTRY_PGA_REJECTIONS,
    InquiryReasonCategory.US_TYPE86_ENTRY_CBP_HOLD_AND_EXAM,
    InquiryReasonCategory.US_TYPE86_ENTRY_PAYMENT_PROCESSING,
  ],
  [RecordType.US_IOR_ACTIVATION]: [
    InquiryReasonCategory.MISSING_DOCUMENTATION,
    InquiryReasonCategory.REJECT_DOCUMENTATION,
  ],
  [RecordType.US_IN_BOND]: [
    InquiryReasonCategory.US_IN_BOND_COMMERCIAL_INVOICE_AND_PACKING_LIST_DATA,
    InquiryReasonCategory.US_IN_BOND_TRANSPORTATION_INFO,
  ],
  [RecordType.GB_CUSTOMS_ENTRY]: euInquiryReasonCategory,
  [RecordType.DE_CUSTOMS_ENTRY]: euInquiryReasonCategory,
  [RecordType.NL_CUSTOMS_ENTRY]: euInquiryReasonCategory,
  [RecordType.FR_CUSTOMS_ENTRY]: euInquiryReasonCategory,
  [RecordType.CUSTOMS_DECLARATION]: euInquiryReasonCategory, // TODO CustomsDeclaration inquiry reasons
};

export const reasonCategoryCodeMap: { [key: string]: string[] } = {
  [InquiryReasonCategory.ISF_DOCUMENTATION]: [
    UsIsfInquiryReasonCode.US_ISF_MISSING_COUNTRY_OF_ORIGION,
    UsIsfInquiryReasonCode.US_ISF_MISSING_HTS_CODE,
    UsIsfInquiryReasonCode.US_ISF_MISSING_MBL_OR_HBL,
    UsIsfInquiryReasonCode.US_ISF_MISSING_SELLER_OR_MANUFACTURER_INFO,
    UsIsfInquiryReasonCode.US_ISF_INFORMATION_NOT_IN_ENGLISH,
    UsIsfInquiryReasonCode.US_ISF_INVALID_HTS_CODE,
    UsIsfInquiryReasonCode.US_ISF_INVALID_BOND,
    UsIsfInquiryReasonCode.US_ISF_IOR_MISMATCH,
    UsIsfInquiryReasonCode.US_ISF_MISSING_ASI_INFO,
    UsIsfInquiryReasonCode.US_ISF_MISSING_ISF_FORM,
    UsIsfInquiryReasonCode.US_ISF_MISSING_ASI_FORM,
    UsIsfInquiryReasonCode.US_ISF_OTHER_DATA_MISSING,
    UsIsfInquiryReasonCode.US_ISF_TECH_ISSUES,
  ],
  [InquiryReasonCategory.US_CONSUMPTION_ENTRY_COMMERCIAL_INVOICE_AND_PACKING_LIST_DATA]:
    [
      UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_INVOICE_IS_NOT_IN_ENGLISH,
      UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_MISSING_PRODUCT_CLASSIFICATIONS,
      UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_MISSING_COUNTRY_OF_ORIGIN_DATA,
      UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_FULL_NAME_AND_ADDRESS_OF_SELLING_PARTY_NOT_LISTED,
      UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_FULL_NAME_AND_ADDRESS_OF_MANUFACTURER_NOT_LISTED,
      UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_MISSING_CURRENCY,
      UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_MISSING_LINE_QUANTITY,
      UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_MISSING_UNIT_COST,
      UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_MISSING_EXTENDED_COST,
      UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_MISSING_REQUIRED_NET_WEIGHT,
      UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_MISSING_HTS_REPORTING_QUANTITY,
      UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_CARTON_COUNT_DOES_NOT_MATCH_PIECE_COUNT,
      UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_INCORRECT_HTS_CODE,
      UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_PENDING_CONSIGNEE_EIN_NUMBER,
      UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_COMMERCIAL_INVOICES_AND_PACKING_LIST_DATA_OTHER_DATA_MISSING,
    ],
  [InquiryReasonCategory.US_CONSUMPTION_ENTRY_PGA_DOCUMENTATION]: [
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_FDA_MISSING_MEDICAL_DEVICE_INFO,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_FDA_MISSING_DRUG_INFO,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_FDA_MISSING_RADIATION_EMITTING_DEVICE_INFO,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_FDA_MISSING_FOODS_INFO,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_FDA_MISSING_COSMETICS_INFO,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_FDA_MISSING_TOBACCO_INFO,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_FDA_MISSING_BIOLOGICS_INFO,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_FDA_MISSING_ANIMAL_DRUGS_AND_DEVICES_INFO,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_EPA_MISSING_TSCA_INFO,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_EPA_MISSING_PESTICIDES_INFO,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_EPA_MISSING_VEHICLES_AND_ENGINES_INFO,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_NHTSA_MISSING_HS7,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_FWS_MISSING_FWS_DOCUMENTATION,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_APHIS_MISSING_LACEY_ACT_INFO,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_APHIS_MISSING_APHIS_CORE_INFO,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_APHIS_MISSING_MISCELLANEOUS_AND_PROCESSED_PRODUCTS_INFO,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_APHIS_MISSING_ANIMAL_PRODUCTS_AND_BYPRODUCTS_INFO,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_APHIS_MISSING_RELATED_ANIMAL_PRODUCTS_INFO,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_APHIS_MISSING_SEEDS_NOT_FOR_PLANTING_INFO,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_APHIS_MISSING_CUT_FLOWERS_INFO,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_APHIS_MISSING_PROPAGATIVE_MATERIAL_INFO,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_APHIS_MISSING_FRUITS_AND_VEGETABLES_INFO,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_APHIS_MISSING_GENETICALLY_ENGINEERED_ORGANISMS_INFO,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_APHIS_MISSING_LIVE_ANIMALS_INFO,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_TTB_MISSING_BEER_INFO,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_TTB_MISSING_WINE_INFO,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_TTB_MISSING_DISTILLED_SPIRITS_INFO,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_TTB_MISSING_TOBACCO_INFO,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_OTHER_PGA_OTHER_DATA_MISSING,
  ],
  [InquiryReasonCategory.US_CONSUMPTION_ENTRY_PRODUCT_DATA]: [
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_NEED_PRODUCT_DESCRIPTION,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_NEED_MATERIAL_COMPOSITION_OF_ITEM,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_NEED_INTENDED_USE_OF_PRODUCT,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_MULTIPLE_DETAILS_REQUIRED_FOR_CLASSIFICATION,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_AD_CVD_INQUIRY,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_MISSING_AD_CVD_NON_REIMBURSEMENT_CERTIFICATE,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_PRODUCT_DATA_OTHER_DATA_MISSING,
  ],
  [InquiryReasonCategory.US_CONSUMPTION_ENTRY_TRANSPORTATION_INFO]: [
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_MISSING_MASTER_BILL,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_MISSING_HOUSE_BILL,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_NO_BILL_MATCH,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_MISSING_CONTAINER_INFO,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_MISSING_GROSS_WEIGHT,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_MISSING_IT_INFO,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_MISSING_PORT_OF_UNLADING_ENTRY_INFO,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_MISSING_FIRMS_CODE,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_MANIFESTED_QUANTITY_DISCREPANCY,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_NOT_DEPARTED_ORIGIN,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_TRANSPORTATION_INFO_OTHER_DATA_MISSING,
  ],
  [InquiryReasonCategory.US_CONSUMPTION_ENTRY_OTHER]: [
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_BOND_ISSUE,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_IOR_SANCTIONED,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_PRE_ALERT_DOCUMENTS_NOT_RECEIVED,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_PRE_ALERT_DOCUMENTS_DELAYED,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_MANUAL_HOLD_SEE_MEMO,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_OTHER_OTHER_DATA_MISSING,
  ],
  [InquiryReasonCategory.US_CONSUMPTION_ENTRY_TECH_FIX]: [
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_PENDING_BUG_FIX,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_PENDING_DATA_FIX,
  ],
  [InquiryReasonCategory.ENTRY_SUMMARY_REJECTIONS]: [
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_PGA_DATA_MISSING_PER_PGA_FLAG,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_TRFF_ADJUSTMENT_HTS_OR_EXCLSN_MISSING,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_SUMMARY_DELETED_OR_REPLACED,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_CONTINUOUS_BOND_TERMINATED,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_MFGR_CODE_UNKNOWN,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_EST_ENTRY_DUTY_GREATER_THAN_STMT_DUTY,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_TRFF_ADJUSTMENT_HTS_NOT_ALLOWED,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_MSNG_OR_INVLD_SCIENT_SPECIES,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_CARRIER_NOT_A_KNOWN_IATA_CODE,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_REPORTED_SURETY_OR_BOND_SURETY_MISMATCH,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_ESV_FAILURE,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_FOREIGN_EXPORTER_UNKNOWN,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_INVALID_CATEGORY_CODE,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_MSNG_OR_INVLD_CONST_ELEMENT_QTY_PER_PGA,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_OTHER_ADD_COMMENT,
  ],
  [InquiryReasonCategory.US_CONSUMPTION_ENTRY_PGA_REJECTIONS]: [
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_CANCELLED_FACILITY_REGISTRATION,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_INVALID_DII,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_INVALID_PNC_NUMBER,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_INVALID_PRODUCT_CODE,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_MISMATCH_IN_REGISTRATION,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_REGISTRATION_NOT_ON_FILE,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_INVALID_COUNTRY_OF_PRODUCTION,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_OTHER_ADD_COMMENT,
  ],
  [InquiryReasonCategory.US_CONSUMPTION_ENTRY_CBP_HOLD_AND_EXAM]: [
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_FDA_EXAM,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_EPA_EXAM,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_CPSC_EXAM,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_CPSC_HOLD,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_LINE_HOLD,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_FDA_LINE_HOLD,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_BOL_MISMATCH,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_PIECE_COUNT_DISCREPANCY,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_CBMS_SYSTEM_ISSUE,
  ],
  [InquiryReasonCategory.US_CONSUMPTION_ENTRY_PAYMENT_PROCESSING]: [
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_PAYMENT_PENDING_CBP_REVIEW,
    UsConsumptionEntryInquiryReasonCode.US_CONSUMPTION_ENTRY_PAYMENT_PENDING_FINANCE_REVIEW,
  ],
  [InquiryReasonCategory.US_TYPE86_ENTRY_COMMERCIAL_INVOICE_AND_PACKING_LIST_DATA]:
    [
      UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_INVOICE_IS_NOT_IN_ENGLISH,
      UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_MISSING_PRODUCT_CLASSIFICATIONS,
      UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_MISSING_COUNTRY_OF_ORIGIN_DATA,
      UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_FULL_NAME_AND_ADDRESS_OF_SELLING_PARTY_NOT_LISTED,
      UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_FULL_NAME_AND_ADDRESS_OF_MANUFACTURER_NOT_LISTED,
      UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_MISSING_CURRENCY,
      UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_MISSING_LINE_QUANTITY,
      UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_MISSING_UNIT_COST,
      UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_MISSING_EXTENDED_COST,
      UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_MISSING_REQUIRED_NET_WEIGHT,
      UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_MISSING_HTS_REPORTING_QUANTITY,
      UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_CARTON_COUNT_DOES_NOT_MATCH_PIECE_COUNT,
      UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_INCORRECT_HTS_CODE,
      UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_PENDING_CONSIGNEE_EIN_NUMBER,
      UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_COMMERCIAL_INVOICES_AND_PACKING_LIST_DATA_OTHER_DATA_MISSING,
    ],
  [InquiryReasonCategory.US_TYPE86_ENTRY_PGA_DOCUMENTATION]: [
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_FDA_MISSING_MEDICAL_DEVICE_INFO,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_FDA_MISSING_DRUG_INFO,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_FDA_MISSING_RADIATION_EMITTING_DEVICE_INFO,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_FDA_MISSING_FOODS_INFO,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_FDA_MISSING_COSMETICS_INFO,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_FDA_MISSING_TOBACCO_INFO,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_FDA_MISSING_BIOLOGICS_INFO,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_FDA_MISSING_ANIMAL_DRUGS_AND_DEVICES_INFO,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_EPA_MISSING_TSCA_INFO,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_EPA_MISSING_PESTICIDES_INFO,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_EPA_MISSING_VEHICLES_AND_ENGINES_INFO,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_NHTSA_MISSING_HS7,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_FWS_MISSING_FWS_DOCUMENTATION,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_APHIS_MISSING_LACEY_ACT_INFO,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_APHIS_MISSING_APHIS_CORE_INFO,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_APHIS_MISSING_MISCELLANEOUS_AND_PROCESSED_PRODUCTS_INFO,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_APHIS_MISSING_ANIMAL_PRODUCTS_AND_BYPRODUCTS_INFO,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_APHIS_MISSING_RELATED_ANIMAL_PRODUCTS_INFO,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_APHIS_MISSING_SEEDS_NOT_FOR_PLANTING_INFO,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_APHIS_MISSING_CUT_FLOWERS_INFO,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_APHIS_MISSING_PROPAGATIVE_MATERIAL_INFO,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_APHIS_MISSING_FRUITS_AND_VEGETABLES_INFO,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_APHIS_MISSING_GENETICALLY_ENGINEERED_ORGANISMS_INFO,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_APHIS_MISSING_LIVE_ANIMALS_INFO,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_TTB_MISSING_BEER_INFO,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_TTB_MISSING_WINE_INFO,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_TTB_MISSING_DISTILLED_SPIRITS_INFO,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_TTB_MISSING_TOBACCO_INFO,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_OTHER_PGA_OTHER_DATA_MISSING,
  ],
  [InquiryReasonCategory.US_TYPE86_ENTRY_PRODUCT_DATA]: [
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_NEED_PRODUCT_DESCRIPTION,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_NEED_MATERIAL_COMPOSITION_OF_ITEM,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_NEED_INTENDED_USE_OF_PRODUCT,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_MULTIPLE_DETAILS_REQUIRED_FOR_CLASSIFICATION,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_PRODUCT_DATA_OTHER_DATA_MISSING,
  ],
  [InquiryReasonCategory.US_TYPE86_ENTRY_TRANSPORTATION_INFO]: [
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_MISSING_MASTER_BILL,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_MISSING_HOUSE_BILL,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_NO_BILL_MATCH,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_MISSING_CONTAINER_INFO,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_MISSING_GROSS_WEIGHT,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_MISSING_IT_INFO,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_MISSING_PORT_OF_UNLADING_ENTRY_INFO,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_MISSING_FIRMS_CODE,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_MANIFESTED_QUANTITY_DISCREPANCY,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_NOT_DEPARTED_ORIGIN,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_TRANSPORTATION_INFO_OTHER_DATA_MISSING,
  ],
  [InquiryReasonCategory.US_TYPE86_ENTRY_OTHER]: [
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_BOND_ISSUE,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_IOR_SANCTIONED,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_PRE_ALERT_DOCUMENTS_NOT_RECEIVED,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_PRE_ALERT_DOCUMENTS_DELAYED,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_MANUAL_HOLD_SEE_MEMO,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_OTHER_OTHER_DATA_MISSING,
  ],
  [InquiryReasonCategory.US_TYPE86_ENTRY_TECH_FIX]: [
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_PENDING_BUG_FIX,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_PENDING_DATA_FIX,
  ],
  [InquiryReasonCategory.CARGO_RELEASE_REJECTIONS]: [
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_PGA_DATA_MISSING_PER_PGA_FLAG,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_TRFF_ADJUSTMENT_HTS_OR_EXCLSN_MISSING,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_CARGO_RELEASE_DELETED_OR_REPLACED,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_CONTINUOUS_BOND_TERMINATED,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_MFGR_CODE_UNKNOWN,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_EST_ENTRY_DUTY_GREATER_THAN_STMT_DUTY,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_TRFF_ADJUSTMENT_HTS_NOT_ALLOWED,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_MSNG_OR_INVLD_SCIENT_SPECIES,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_CARRIER_NOT_A_KNOWN_IATA_CODE,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_REPORTED_SURETY_OR_BOND_SURETY_MISMATCH,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_ESV_FAILURE,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_FOREIGN_EXPORTER_UNKNOWN,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_INVALID_CATEGORY_CODE,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_MSNG_OR_INVLD_CONST_ELEMENT_QTY_PER_PGA,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_OTHER_ADD_COMMENT,
  ],
  [InquiryReasonCategory.US_TYPE86_ENTRY_PGA_REJECTIONS]: [
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_CANCELLED_FACILITY_REGISTRATION,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_INVALID_DII,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_INVALID_PNC_NUMBER,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_INVALID_PRODUCT_CODE,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_MISMATCH_IN_REGISTRATION,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_REGISTRATION_NOT_ON_FILE,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_INVALID_COUNTRY_OF_PRODUCTION,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_OTHER_ADD_COMMENT,
  ],
  [InquiryReasonCategory.US_TYPE86_ENTRY_CBP_HOLD_AND_EXAM]: [
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_FDA_EXAM,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_EPA_EXAM,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_CPSC_EXAM,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_CPSC_HOLD,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_LINE_HOLD,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_FDA_LINE_HOLD,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_BOL_MISMATCH,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_PIECE_COUNT_DISCREPANCY,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_CBMS_SYSTEM_ISSUE,
  ],
  [InquiryReasonCategory.US_TYPE86_ENTRY_PAYMENT_PROCESSING]: [
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_PAYMENT_PENDING_CBP_REVIEW,
    UsType86EntryInquiryReasonCode.US_TYPE86_ENTRY_PAYMENT_PENDING_FINANCE_REVIEW,
  ],

  [InquiryReasonCategory.MISSING_DOCUMENTATION]: [
    UsIorActivationInquiryReasonCode.US_IOR_ACTIVATION_MISSING_MASTER_POWER_OF_ATTORNEY,
    UsIorActivationInquiryReasonCode.US_IOR_ACTIVATION_MISSING_SUB_POWER_OF_ATTORNEY,
    UsIorActivationInquiryReasonCode.US_IOR_ACTIVATION_MISSING_DIRECT_POWER_OF_ATTORNEY,
    UsIorActivationInquiryReasonCode.US_IOR_ACTIVATION_MISSING_CORPORATE_CERTIFICATION,
    UsIorActivationInquiryReasonCode.US_IOR_ACTIVATION_MISSING_OFFICER_VERIFICATION,
    UsIorActivationInquiryReasonCode.US_IOR_ACTIVATION_MISSING_BUSINESS_REGISTRATION,
    UsIorActivationInquiryReasonCode.US_IOR_ACTIVATION_MISSING_SOI_SCREENING,
    UsIorActivationInquiryReasonCode.US_IOR_ACTIVATION_MISSING_CONTINUOUS_BOND,
    UsIorActivationInquiryReasonCode.US_IOR_ACTIVATION_MISSING_PMS_APPLICATION,
    UsIorActivationInquiryReasonCode.US_IOR_ACTIVATION_OTHER_DATA_MISSING,
  ],
  [InquiryReasonCategory.REJECT_DOCUMENTATION]: [
    UsIorActivationInquiryReasonCode.US_IOR_ACTIVATION_REJECT_MASTER_POWER_OF_ATTORNEY,
    UsIorActivationInquiryReasonCode.US_IOR_ACTIVATION_REJECT_SUB_POWER_OF_ATTORNEY,
    UsIorActivationInquiryReasonCode.US_IOR_ACTIVATION_REJECT_DIRECT_POWER_OF_ATTORNEY,
    UsIorActivationInquiryReasonCode.US_IOR_ACTIVATION_REJECT_CORPORATE_CERTIFICATION,
  ],
  [InquiryReasonCategory.US_IN_BOND_COMMERCIAL_INVOICE_AND_PACKING_LIST_DATA]: [
    UsInBondInquiryReasonCode.US_IN_BOND_MISSING_CONSIGNEE,
    UsInBondInquiryReasonCode.US_IN_BOND_MISSING_TOTAL_VALUE,
    UsInBondInquiryReasonCode.US_IN_BOND_MISSING_CURRENCY,
    UsInBondInquiryReasonCode.US_IN_BOND_CARTON_COUNT_DOES_NOT_MATCH_PIECE_COUNT,
    UsInBondInquiryReasonCode.US_IN_BOND_COMMERCIAL_INVOICE_AND_PACKING_LIST_DATA_OTHER_DATA_MISSING,
  ],
  [InquiryReasonCategory.US_IN_BOND_TRANSPORTATION_INFO]: [
    UsInBondInquiryReasonCode.US_IN_BOND_MISSING_BONDED_CARRIER_CODE,
    UsInBondInquiryReasonCode.US_IN_BOND_MISSING_BONDED_CARRIER_TAX_ID,
    UsInBondInquiryReasonCode.US_IN_BOND_MISSING_PORT_OF_UNLADING_ENTRY_INFO,
    UsInBondInquiryReasonCode.US_IN_BOND_MISSING_PREVIOUS_IN_BOND_NUMBER,
    UsInBondInquiryReasonCode.US_IN_BOND_MISSING_PREVIOUS_IN_BOND_ORIGIN_PORT,
    UsInBondInquiryReasonCode.US_IN_BOND_MISSING_PREVIOUS_IN_BOND_DATE,
    UsInBondInquiryReasonCode.US_IN_BOND_MISSING_FIRMS_CODE,
    UsInBondInquiryReasonCode.US_IN_BOND_MISSING_MASTER_BILL,
    UsInBondInquiryReasonCode.US_IN_BOND_MISSING_HOUSE_BILL,
    UsInBondInquiryReasonCode.US_IN_BOND_MISSING_CONTAINER_INFO,
    UsInBondInquiryReasonCode.US_IN_BOND_MANIFESTED_QUANTITY_DISCREPANCY,
    UsInBondInquiryReasonCode.US_IN_BOND_TRANSPORTATION_INFO_OTHER_DATA_MISSING,
  ],
  [InquiryReasonCategory.EU_CUSTOMS_ENTRY_BILL_OF_LADING_DATA]: [
    EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_BILL_OF_LADING_INCORRECT_ENTITY,
    EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_BILL_OF_LADING_INCORRECT_EORI,
    EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_BILL_OF_LADING_INCORRECT_VAT,
    EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_BILL_OF_LADING_MISSING_DATA,
    EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_BILL_OF_LADING_OTHER_PROBLEMS,
  ],
  [InquiryReasonCategory.EU_CUSTOMS_ENTRY_COMMERCIAL_INVOICE_AND_PACKING_LIST_DATA]:
    [
      EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_COMMERCIAL_INVOICES_INCORRECT_CLASSIFICATION,
      EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_COMMERCIAL_INVOICES_INCORRECT_ENTITY,
      EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_COMMERCIAL_INVOICES_INCORRECT_EORI,
      EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_COMMERCIAL_INVOICES_INCORRECT_INCOTERMS,
      EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_COMMERCIAL_INVOICES_INCORRECT_VALUE,
      EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_COMMERCIAL_INVOICES_INCORRECT_VAT,
      EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_COMMERCIAL_INVOICES_MISSING_DATA,
      EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_COMMERCIAL_INVOICES_OTHER_PROBLEMS,
      EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_PACKING_LIST_INCORRECT_DATA,
      EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_PACKING_LIST_INCORRECT_ENTITY,
      EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_PACKING_LIST_INCORRECT_EORI,
      EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_PACKING_LIST_INCORRECT_VAT,
      EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_PACKING_LIST_MISSING_DATA,
      EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_PACKING_LIST_OTHER_PROBLEMS,
    ],
  [InquiryReasonCategory.FTA_DOCUMENTS]: [
    EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_FTA_DOCUMENTS_INCORRECT,
    EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_FTA_DOCUMENTS_INCORRECT_DETAILS,
    EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_FTA_DOCUMENTS_INCORRECT_ENTITY,
    EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_FTA_DOCUMENTS_OTHER_PROBLEMS,
  ],
  [InquiryReasonCategory.GSP_DOCUMENTS]: [
    EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_GSP_DOCUMENTS_INCORRECT,
    EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_GSP_DOCUMENTS_INCORRECT_DETAILS,
    EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_GSP_DOCUMENTS_INCORRECT_ENTITY,
    EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_GSP_DOCUMENTS_OTHER_PROBLEMS,
  ],
  [InquiryReasonCategory.IMPORT_CONTROL_DOCUMENTS]: [
    EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_IMPORT_CONTROL_DOCUMENTS_INCORRECT,
    EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_IMPORT_CONTROL_DOCUMENTS_INCORRECT_ENTITY,
    EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_IMPORT_CONTROL_DOCUMENTS_MISSING,
    EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_IMPORT_CONTROL_DOCUMENTS_OTHER_PROBLEMS,
  ],
  [InquiryReasonCategory.OTHER_GOVERNMENT_AUTHORITY_DOCUMENTS]: [
    EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_OTHER_PROBLEMS,
  ],
  [InquiryReasonCategory.REGULATORY_DOCUMENTS]: [
    EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_REGULATORY_DOCUMENTS_INCORRECT,
    EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_REGULATORY_DOCUMENTS_INCORRECT_ENTITY,
    EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_REGULATORY_DOCUMENTS_MISSING,
    EuCustomsEntryInquiryReasonCode.EU_CUSTOMS_ENTRY_REGULATORY_DOCUMENTS_OTHER_PROBLEMS,
  ],
};

export const euAbacusReasonCategoryCodeMap = {
  [InquiryReasonCategory.SHIPMENT_DATA]: [
    EuAbacusReasonCode.EU_CUSTOMS_ENTRY_ADDITIONAL_INFORMATION_NEEDED,
    EuAbacusReasonCode.EU_CUSTOMS_ENTRY_UNPAID_DUTY,
    EuAbacusReasonCode.EU_CUSTOMS_ENTRY_EORI_NUMBER_INACCURATE,
    EuAbacusReasonCode.EU_CUSTOMS_ENTRY_VAT_NUMBER_INACCURATE,
  ],
};
