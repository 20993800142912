import { Modal, Button, InputNumber, message, Form } from 'antd';
import React, { useState, useCallback, useEffect } from 'react';
import { ModalKey } from 'types';
import { useModal } from '@xbcb/ui-utils';
import { FormItem } from '@xbcb/form-item-components';
import { useParams } from 'react-router-dom';
import { AnyObject, RecordType } from '@xbcb/shared-types';
import { client } from '@xbcb/apollo-client';
import {
  getOneQuery,
  getRecordFromResponse,
  updateOneMutation,
} from '@xbcb/shared-queries';
import { getRecordType } from '@xbcb/core';
import type { UsConsumptionEntry } from '@xbcb/api-gateway-client';
import { useMutation } from '@apollo/client';
import { isNil } from 'lodash';

const getUsConsumptionEntryRecord = async (usConsumptionEntryId: string) => {
  const recordName = getRecordType(usConsumptionEntryId) as RecordType;
  const getQuery = getOneQuery({
    recordName: recordName,
    fields: ` ...recordFields  id version tasks { id version status } totalInvoiceLineCount`,
  });
  const getQueryVariables = { id: usConsumptionEntryId };
  const queryResponse = await client.query({
    query: getQuery,
    variables: getQueryVariables,
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  });

  const { error, loading } = queryResponse;
  const usConsumptionEntryRecord: UsConsumptionEntry =
    getRecordFromResponse(queryResponse, 'get', recordName) || {};
  return { usConsumptionEntryRecord, error, loading };
};

const InvoiceLineCountModal: React.FC = () => {
  const [form] = Form.useForm();
  const { recordId } = useParams<{ recordId: string }>();
  const [inputValue, setInputValue] = useState();
  const [totalInvoiceLineCountValue, setTotalInvoiceLineCountValue] =
    React.useState(0);
  const [currentUsConsumptionEntry, setCurrentUsConsumptionEntry] =
    React.useState<AnyObject>();
  const { visible: isInvoiceLineCountModalVisible, closeModal } = useModal(
    ModalKey.INVOICE_LINE_COUNT,
  );

  const onChange = (inputValue: any) => {
    setInputValue(inputValue);
  };

  const fetchUsConsumptionEntry = useCallback(async () => {
    const usConsumptionEntry = await getUsConsumptionEntryRecord(recordId);
    setTotalInvoiceLineCountValue(
      usConsumptionEntry?.usConsumptionEntryRecord?.totalInvoiceLineCount || 0,
    );
    setCurrentUsConsumptionEntry(usConsumptionEntry?.usConsumptionEntryRecord);
  }, [recordId]);

  useEffect(() => {
    fetchUsConsumptionEntry();
  }, [fetchUsConsumptionEntry]);

  const [
    updateUsConsumptionEntryLineCount,
    { loading: isUpdateConsumptionEntryLoading },
  ] = useMutation(
    updateOneMutation({
      recordName: RecordType.US_CONSUMPTION_ENTRY,
      fields: `id version totalInvoiceLineCount`,
    }),
    {
      onError: async (error) => {
        message.error(
          `Sorry, the following error occurred: ${error}. Please try again later.`,
          3.0,
        );
      },
    },
  );

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      await form.validateFields();
    } catch (e) {
      message.error('Please fill all required fields');
      return;
    }
    if (!isNil(currentUsConsumptionEntry)) {
      updateUsConsumptionEntryLineCount({
        variables: {
          id: currentUsConsumptionEntry?.id,
          version: currentUsConsumptionEntry?.version,
          input: {
            totalInvoiceLineCount: inputValue,
          },
        },
      });
      message.success(`Lines count (${inputValue}) saved successfully.`);
      closeModal();
    } else {
      message.error(`Lines count (${inputValue}) was not saved.`);
    }
  };

  return (
    <Modal
      title="Enter Line count"
      open={isInvoiceLineCountModalVisible}
      footer={[
        <Button
          onClick={closeModal}
          size="large"
          disabled={isUpdateConsumptionEntryLoading}
          key="cancelInvoiceLineCount"
        >
          Cancel
        </Button>,
        <Button
          disabled={isUpdateConsumptionEntryLoading}
          onClick={handleSubmit}
          type="primary"
          size="large"
          htmlType="submit"
          key="invoiceLineCount"
        >
          Save
        </Button>,
      ]}
      onCancel={closeModal}
    >
      <p>
        Line count is defined as the number of product lines on the commercial
        invoice. Line count will be used to measure the complexity of this
        commercial invoice. Any downstream document handling tasks will only be
        assigned to operators with the proper skill level.
      </p>
      <Form form={form}>
        <FormItem
          name={['line count']}
          rules={[{ required: true, message: 'Please input line count!' }]}
          label={'Line Count (' + totalInvoiceLineCountValue + ')'}
        >
          <InputNumber
            id="lineCount"
            min={1}
            max={9999}
            onChange={onChange}
            placeholder={
              totalInvoiceLineCountValue.toString() || 'Enter line count.'
            }
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default InvoiceLineCountModal;
