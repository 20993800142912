import React, { useState } from 'react';
import { omit } from 'lodash';
import { WarningOutlined } from '@ant-design/icons';
import { Select, Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { FormItem, Option } from '@xbcb/form-item-components';
import { formatRecordName } from '@xbcb/js-utils';
import { CssSize } from '@xbcb/ui-types';
import { RecordType } from '@xbcb/shared-types';
import {
  getCountryCodes,
  selectFilter,
  shouldUpdate,
  badCountryCodes,
} from '@xbcb/ui-utils';
import MoneyInput from 'components/MoneyInput';
import UsIorContinuousBondRequestRiskUnderwritingInfo, {
  riskTypeEnum,
} from 'components/UsIorContinuousBondRequestRiskUnderwritingInfo';
import {
  convertShippingValueEstimateToBondAmount,
  formatBondAmount,
  smallestSupportedBondAmount,
  largestSupportedBondAmount,
  bondAmountOptions,
  convertBondAmountToFormattedMaxShippingValueEstimate,
} from 'libs/bondAmountOptions';
import { useCurrentUser } from 'libs/hooks';
import {
  StyledEstimatedCostsWrapperDiv,
  StyledParagraph,
  StyledSpan,
  StyledWarningDiv,
} from './styles';

enum DutiesTaxesAndFeesTimePeriod {
  NEXT = 'next',
  PREVIOUS = 'previous',
}

export type UsIorContinuousBondRequestInfoFieldsProps = {
  form: FormInstance;
  disabled?: boolean;
  readOnly?: boolean;
  // Used in cases like the creation modal where we want more of a "stack" look
  // (due to width restrictions) rather than displaying everything in one line
  $displayHelperTextInSeparateLine?: boolean;
};

const riskTypeMap = [
  { id: riskTypeEnum.Textiles, name: 'Textiles' },
  { id: riskTypeEnum.AddCvd, name: 'ADD/CVD' },
  { id: riskTypeEnum.Fda, name: 'FDA' },
  { id: riskTypeEnum.General, name: 'General Merchandise' },
];
const UsIorContinuousBondRequestInfoFields: React.FC<
  UsIorContinuousBondRequestInfoFieldsProps
> = ({ form, readOnly, disabled, $displayHelperTextInSeparateLine }) => {
  const { accountType } = useCurrentUser();
  const countryCodes = getCountryCodes();
  const codes: { [key: string]: any } = omit(countryCodes, [
    ...badCountryCodes,
    ...['BY'],
  ]);
  const countryOptions = Object.keys(codes).map((countryCode) => (
    <Option key={countryCode} value={countryCode}>
      {codes[countryCode].name}
    </Option>
  ));
  const previousYearShippingValueEstimateNamePath = [
    'previousYearShippingValueEstimate',
  ];
  const nextYearShippingValueEstimateNamePath = [
    'nextYearShippingValueEstimate',
  ];

  const riskNamePath = ['risk'];
  const riskTypeNamePath = [...riskNamePath, 'type'];
  const [riskType, setRiskType] = useState(
    form.getFieldValue(riskTypeNamePath),
  );

  // we are doing this to cause a re-render
  const handleRiskTypeChange = (riskType: string) => {
    setRiskType(riskType);
  };
  const getMoneyInputProps = (timePeriod: DutiesTaxesAndFeesTimePeriod) => {
    const namePath =
      timePeriod === DutiesTaxesAndFeesTimePeriod.NEXT
        ? nextYearShippingValueEstimateNamePath
        : previousYearShippingValueEstimateNamePath;
    return {
      $itemSize: CssSize.MEDIUM,
      max: 100000000,
      label: `Estimated Duties, Taxes & Fees (${timePeriod} 12 months)`,
      localNamePath: namePath,
      fullNamePath: namePath,
      disabled,
      readOnly,
      precision: 0,
      step: 1000,
      currency: 'USD',
      required: true,
    };
  };
  const formattedRecordName = formatRecordName({
    recordType: RecordType.US_IOR,
    accountType,
  });
  return (
    <>
      <FormItem
        name={['productOriginCountryCodes']}
        label="From which countries are the goods imported? (separate with commas)"
        rules={[{ required: true, message: ' ' }]}
        $itemSize={CssSize.MEDIUM}
        $inline
        $readOnly={readOnly}
      >
        <Select
          disabled={disabled}
          showSearch
          allowClear
          filterOption={selectFilter}
          mode="multiple"
          notFoundContent="None Found"
          tokenSeparators={[',']}
        >
          {countryOptions}
        </Select>
      </FormItem>
      <FormItem
        name={['productDescriptions']}
        label="Describe the products being imported below (separate with commas)"
        rules={[{ required: true, message: ' ' }]}
        $itemSize={CssSize.MEDIUM}
        $inline
        $readOnly={readOnly}
      >
        <Select
          disabled={disabled}
          allowClear
          mode="tags"
          tokenSeparators={[',']}
        />
      </FormItem>
      <MoneyInput
        {...getMoneyInputProps(DutiesTaxesAndFeesTimePeriod.PREVIOUS)}
      />
      <MoneyInput {...getMoneyInputProps(DutiesTaxesAndFeesTimePeriod.NEXT)} />
      <StyledEstimatedCostsWrapperDiv
        $displayHelperTextInSeparateLine={$displayHelperTextInSeparateLine}
      >
        <Form.Item
          shouldUpdate={shouldUpdate([
            previousYearShippingValueEstimateNamePath,
            nextYearShippingValueEstimateNamePath,
          ])}
          noStyle
        >
          {() => {
            const previousYearShippingValueEstimate = form.getFieldValue(
              previousYearShippingValueEstimateNamePath,
            );
            const nextYearShippingValueEstimate = form.getFieldValue(
              nextYearShippingValueEstimateNamePath,
            );
            // Don't show the recommendation or the request bond amount input
            // until both values are entered, i.e are defined
            if (
              [
                previousYearShippingValueEstimate,
                nextYearShippingValueEstimate,
              ].some((fieldValue) => fieldValue === undefined)
            )
              return;
            const maximumShippingValueEstimate = Math.max(
              previousYearShippingValueEstimate,
              nextYearShippingValueEstimate,
            );
            // This is the raw recommended bond amount, based off the figures
            // entered by the user. We may increase this recommendation if it
            // doesn't meet the smallest supported bond amount
            const rawRecommendedBondAmount =
              convertShippingValueEstimateToBondAmount(
                maximumShippingValueEstimate,
              );
            // There is a minimum recommended bond amount (aka the
            // smallestSupportedBondAmount) so adjust the raw recommend bond
            // amount if necessary (aka take the larger of the two)
            const recommendedBondAmount = Math.max(
              rawRecommendedBondAmount,
              smallestSupportedBondAmount,
            );
            const HelperText =
              recommendedBondAmount > largestSupportedBondAmount ? (
                <StyledWarningDiv>
                  <WarningOutlined />
                  <StyledParagraph $warn>
                    This bond amount requires a custom quote from an Account
                    Manager. Select "More than 500K" to request one.
                  </StyledParagraph>
                </StyledWarningDiv>
              ) : (
                <StyledParagraph>
                  Based on the amounts entered above, we recommend at least a{' '}
                  <StyledSpan>
                    {formatBondAmount(recommendedBondAmount)}
                  </StyledSpan>{' '}
                  bond. This will cover the {formattedRecordName} for up to{' '}
                  <StyledSpan>
                    {convertBondAmountToFormattedMaxShippingValueEstimate(
                      recommendedBondAmount,
                    )}
                  </StyledSpan>{' '}
                  in duties, taxes, and fees for a rolling{' '}
                  <StyledSpan>12 months</StyledSpan>.
                </StyledParagraph>
              );
            return (
              <>
                {HelperText}
                <FormItem
                  name={['requestedBondAmount']}
                  label="Requested Bond Coverage Amount"
                  rules={[{ required: true, message: ' ' }]}
                  $itemSize={CssSize.MEDIUM}
                  $inline
                  $readOnly={readOnly}
                  required
                >
                  <Select
                    disabled={disabled}
                    allowClear
                    filterOption={selectFilter}
                  >
                    {bondAmountOptions}
                  </Select>
                </FormItem>
              </>
            );
          }}
        </Form.Item>
      </StyledEstimatedCostsWrapperDiv>
      <FormItem
        name={riskTypeNamePath}
        label="Risk Type"
        rules={[{ required: true, message: ' ' }]}
        $readOnly={readOnly}
      >
        <Select onChange={handleRiskTypeChange}>
          {riskTypeMap.map(({ id, name }) => (
            <Option key={id} value={id}>
              {id} - {name}
            </Option>
          ))}
        </Select>
      </FormItem>

      <UsIorContinuousBondRequestRiskUnderwritingInfo
        form={form}
        disabled={disabled}
        readOnly={readOnly}
        baseNamePath={riskNamePath}
        riskType={riskType}
      />
    </>
  );
};

export default UsIorContinuousBondRequestInfoFields;
