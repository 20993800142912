import { AnyObject, KeywordSearchOperator } from '@xbcb/shared-types';
import { AdditionalFormError, UiStage } from '@xbcb/ui-types';
import {
  AppRecordValidateCreateProps,
  AppRecordValidateUpdateProps,
} from 'routes';
import {
  AssignmentTeam,
  LoadType,
  ProductType,
} from '@xbcb/api-gateway-client';
import { isEqual } from 'lodash';
import { fetchAssignmentTeams } from 'libs/fetchAssignmentTeams';
import { getEnv } from '@xbcb/ui-utils';

export const validateAssignmentTeamCreate = (
  props: AppRecordValidateCreateProps,
): Promise<{
  additionalErrors: AdditionalFormError[];
}> => {
  return validateAssignmentTeam({
    payload: props.input,
    operatorId: props.operatorId,
  });
};

export const validateAssignmentTeamUpdate = (
  props: AppRecordValidateUpdateProps,
): Promise<{
  additionalErrors: AdditionalFormError[];
}> => {
  return validateAssignmentTeam({
    payload: props.input,
    assignmentTeamId: props.existingRecord?.id,
    operatorId: props.existingRecord?.operator?.id,
  });
};

type ValidateAssignmentTeamProps = {
  payload: AnyObject;
  operatorId?: string;
  assignmentTeamId?: string;
};
export const validateAssignmentTeam = async ({
  payload,
  operatorId,
  assignmentTeamId,
}: ValidateAssignmentTeamProps): Promise<{
  additionalErrors: AdditionalFormError[];
}> => {
  const { stage } = getEnv();

  const additionalErrors: AdditionalFormError[] = [];
  const { workOrderSpecialties, groups } = payload;
  const workOrderTaskTypes = Object.keys(workOrderSpecialties).reduce(
    (acc: string[], key) => {
      if (workOrderSpecialties[key]) {
        acc.push(...workOrderSpecialties[key]);
      }
      return acc;
    },
    [],
  );
  if (!workOrderTaskTypes.length) {
    additionalErrors.push({
      title: 'No WorkOrderTaskType',
      messages: [
        'An Assignment Team needs to have at least 1 WorkOrderTaskType',
      ],
    });
    return { additionalErrors };
  }
  if (Array.isArray(groups)) {
    const hasOperatorOverlap: boolean = groups
      ?.map((group: AnyObject) => group?.operators?.id ?? '')
      .reduce((acc: string[], curr: string[]) => [...acc, ...curr], [])
      .some(
        (id: string, index: number, array: string) =>
          array.indexOf(id) !== index,
      );
    if (hasOperatorOverlap) {
      additionalErrors.push({
        title: 'Operator assigned to another group',
        messages: ['User can only be an Operator in one group'],
      });
      return { additionalErrors };
    }
  }
  const searchCriteria = {
    workOrderTaskType: {
      values: workOrderTaskTypes,
      operator: KeywordSearchOperator.ONE_OF,
    },
    ...(operatorId && {
      operatorId: {
        values: [operatorId],
        operator: KeywordSearchOperator.EQUALS,
      },
    }),
    deletedTime: {
      operator: KeywordSearchOperator.DOES_NOT_EXIST,
    },
  };
  const searchResponse = await fetchAssignmentTeams(searchCriteria);
  const existingAssignmentTeams =
    searchResponse.data?.searchAssignmentTeams.results || [];
  const duplicateTeams = existingAssignmentTeams
    .filter((team: AssignmentTeam) => {
      if (team.id === assignmentTeamId) return false;
      if (team.verticals?.length && payload.verticals.length) {
        for (const teamVertical of team.verticals) {
          for (const payloadVertical of payload.verticals) {
            const {
              modeOfTransport,
              productTypes,
              loadTypes,
              forwarders,
              shippers,
              iors,
            } = teamVertical;
            const newForwarders = {
              id: forwarders?.map((forwarder) => forwarder.id) || [],
            };
            const newShippers = {
              id: shippers?.map((shipper) => shipper.id) || [],
            };
            const newIors = {
              id: iors?.map((ior) => ior.id) || [],
            };
            const isModeOfTransportDuplicate =
              !modeOfTransport ||
              !payloadVertical.modeOfTransport ||
              isEqual(
                JSON.stringify(modeOfTransport),
                JSON.stringify(payloadVertical.modeOfTransport),
              );
            const isLoadTypeDuplicate =
              !loadTypes?.length ||
              !payloadVertical.loadTypes?.length ||
              loadTypes.some((loadType: LoadType) =>
                payloadVertical.loadTypes.includes(loadType),
              );
            const isProductTypeDuplicate =
              !productTypes?.length ||
              !payloadVertical.productTypes?.length ||
              productTypes.some((productType: ProductType) =>
                payloadVertical.productTypes.includes(productType),
              );
            const isForwarderDuplicate =
              !newForwarders.id.length ||
              !payloadVertical.forwarders?.id?.length ||
              newForwarders.id.some((forwarder: string) =>
                payloadVertical.forwarders.id.includes(forwarder),
              );
            if (stage !== UiStage.PROD) {
              const isShipperDuplicate =
                (!newShippers.id.length &&
                  !payloadVertical.shippers?.id?.length) ||
                newShippers.id.some((shipper: string) =>
                  payloadVertical.shippers?.id.includes(shipper),
                );
              const isIorDuplicate =
                (!newIors.id.length && !payloadVertical.iors?.id?.length) ||
                newIors.id.some((ior: string) =>
                  payloadVertical.iors?.id.includes(ior),
                );
              return (
                isModeOfTransportDuplicate &&
                isLoadTypeDuplicate &&
                isProductTypeDuplicate &&
                isForwarderDuplicate &&
                isShipperDuplicate &&
                isIorDuplicate
              );
            } else {
              return (
                isModeOfTransportDuplicate &&
                isLoadTypeDuplicate &&
                isProductTypeDuplicate &&
                isForwarderDuplicate
              );
            }
          }
        }
      }
      return true;
    })
    .map((team: AssignmentTeam) => {
      return { id: team.id, name: team.name };
    });
  if (duplicateTeams.length) {
    const teamNames = duplicateTeams
      .map((team: AssignmentTeam) => team.name)
      .join(', ');
    const errorMessage = `The setting of Filter is also used in Team(s): ${teamNames}. Duplicate assignment rules in different Teams will cause assignment conflict. Please modify the setting of either team to proceed.`;
    additionalErrors.push({
      title: 'Duplicate team verticals',
      messages: [errorMessage],
    });
  }
  return { additionalErrors };
};
