// TODO Make a "don't show" map.
import React, { useState } from 'react';
import moment from 'moment';
import { Spin, Timeline, Switch, Tooltip } from 'antd';
import { ModeOfTransport } from '@xbcb/shipment-types';
import {
  WorkOrderMilestoneName,
  WorkOrderConfirmationApprovalStatus,
} from '@xbcb/work-order-types';
import { InfoTooltip } from '@xbcb/form-item-components';
import Milestone from 'components/Milestone';
import tzString from 'libs/tzString';
import { StyledFooter, StyledDiv, StyledSpan, StyledEmpty } from './styles';
import { AccountType, TagKey } from '@xbcb/shared-types';
import type {
  WorkOrderInquiry,
  WorkOrderConfirmation,
  WorkOrderMilestone,
} from '@xbcb/api-gateway-client';
import { useCurrentUser } from 'libs/hooks';

interface MilestonesProps {
  loading?: boolean;
  // TODO use apollo codegen type
  milestones: WorkOrderMilestone[];
  modesOfTransport?: ModeOfTransport[];
  pgaDispositions?: any[]; // UsCbpEntryReleaseDisposition[]
  inquiries?: WorkOrderInquiry[];
  confirmations?: WorkOrderConfirmation[];
}

const escalateFeatureMilestones = [
  WorkOrderMilestoneName.ESCALATED_TO_SME,
  WorkOrderMilestoneName.ESCALATED_TO_TEAM_LEAD,
  WorkOrderMilestoneName.ESCALATION_RESOLVED,
];

const Milestones = ({
  loading,
  milestones,
  modesOfTransport = [],
  pgaDispositions,
  confirmations = [],
  inquiries,
}: MilestonesProps) => {
  const [showAll, setShowAll] = useState(false);
  const [openMilestoneId, setOpenMilestoneId] = useState('');
  const [isListening, setIsListening] = useState(false);
  const user = useCurrentUser();
  const { accountType } = user;
  const isOperatorUser = accountType === AccountType.OPERATOR;

  if (loading) return <Spin />;

  const outsideClickListener = (event: any) => {
    const milestonesElement = document.querySelector('.milestones');
    if (!milestonesElement || !milestonesElement.contains(event.target)) {
      // a click outside of the the milestones box
      removeClickListener();
      setOpenMilestoneId('');
    }
  };

  const addClickListener = () => {
    if (!isListening) {
      document.addEventListener('click', outsideClickListener);
      setIsListening(true);
    }
  };

  const removeClickListener = () => {
    document.removeEventListener('click', outsideClickListener);
    setIsListening(false);
  };

  const toggleOpen = (milestoneId: string) => {
    if (milestoneId === openMilestoneId) {
      // close the milestone if it's already open
      removeClickListener();
      setOpenMilestoneId('');
    } else {
      // open the milestone
      addClickListener();
      setOpenMilestoneId(milestoneId);
    }
  };

  // sort mutates an existing array by default so using the spread operator prevents read-only errors with milestones
  const sortedMilestones = [...milestones].sort((a: any, b: any) => {
    const firstMoment = moment(a.occurenceTime);
    const secondMoment = moment(b.occurenceTime);
    if (secondMoment.isAfter(firstMoment)) return -1;
    return 1;
  });

  const activeMilestones = milestones.reduce(
    (obj: { [key in WorkOrderMilestoneName]?: boolean }, milestone) => {
      obj[milestone.name] = true;
      return obj;
    },
    {},
  );

  const hiddenMilestones = {
    [WorkOrderMilestoneName.US_ISF_BOND_ACQUISITION_REQUESTED]:
      activeMilestones[WorkOrderMilestoneName.US_ISF_SUBMITTED],
    [WorkOrderMilestoneName.US_ISF_SUBMITTED]:
      activeMilestones[WorkOrderMilestoneName.US_ISF_FILED],
    [WorkOrderMilestoneName.US_IN_BOND_SUBMITTED]:
      activeMilestones[WorkOrderMilestoneName.US_IN_BOND_AUTHORIZED],
    [WorkOrderMilestoneName.US_ISF_REJECTED]:
      activeMilestones[WorkOrderMilestoneName.US_ISF_FILED],
    [WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_SUBMITTED]:
      activeMilestones[WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_FILED],
  };

  let hiddenCount = 0;
  const shownMilestones = sortedMilestones.filter(({ name, source }) => {
    if (hiddenMilestones[name as keyof typeof hiddenMilestones]) {
      hiddenCount++;
      if (!showAll) return false;
    }
    if (
      pgaDispositions &&
      name === WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_PGA_HOLD
    ) {
      const hidden = pgaDispositions.reduce(
        (isReleased, { code, status }: { code: string; status: string }) => {
          if (code === source && status === '07') return false;
          return isReleased;
        },
        true,
      );
      if (hidden) hiddenCount++;
      return hidden;
    }
    // Exclude escalateFeatureMilestones if account type is not operatorUser.
    if (
      !isOperatorUser &&
      escalateFeatureMilestones.includes(name as WorkOrderMilestoneName)
    ) {
      return false;
    }
    return true;
  });

  const timeline = (
    <Timeline>
      {shownMilestones.map((milestone) => {
        let customContent = '';
        let tooltipTitle = '';
        // Display reject code in milestone if entry confirmation is disputed
        if (
          [
            WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_DUTY_DISPUTED,
            WorkOrderMilestoneName.CUSTOMS_ENTRY_DUTY_DISPUTED,
          ].includes(milestone.name as WorkOrderMilestoneName) &&
          milestone.tags?.some(
            (tag) => tag.key === TagKey.WORK_ORDER_CONFIRMATION_ID,
          )
        ) {
          const confirmationId = milestone.tags?.find(
            (tag) => tag.key === TagKey.WORK_ORDER_CONFIRMATION_ID,
          )?.value;
          const { status, disputedReason, additionalComments } =
            confirmations?.find(
              (confirmation) => confirmation.id === confirmationId,
            ) || {};

          customContent =
            status === WorkOrderConfirmationApprovalStatus.DISPUTED &&
            disputedReason
              ? `Reject reason code: ${disputedReason}`
              : '';
          tooltipTitle = additionalComments || '';
        }
        const milestoneReactNode = (
          <Milestone
            key={milestone.id}
            isOpen={openMilestoneId === milestone.id}
            milestone={milestone}
            toggleOpen={toggleOpen}
            inquiries={inquiries}
            customContent={customContent}
          />
        );
        if (tooltipTitle) {
          return (
            <Tooltip title={tooltipTitle}>
              <div>{milestoneReactNode}</div>
            </Tooltip>
          );
        }
        return <>{milestoneReactNode}</>;
      })}
    </Timeline>
  );

  const timeZone = tzString();

  return (
    <StyledDiv>
      {!milestones.length ? (
        <StyledEmpty description="No milestones" />
      ) : (
        <>
          {timeline}
          <StyledFooter>
            <div>
              {hiddenCount > 0 && (
                <>
                  <StyledSpan>Show All</StyledSpan>
                  <Switch size="small" onChange={() => setShowAll(!showAll)} />
                </>
              )}
            </div>
            <InfoTooltip title={`All times in ${timeZone}`} placement="left" />
          </StyledFooter>
        </>
      )}
    </StyledDiv>
  );
};

export default Milestones;
