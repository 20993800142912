import React from 'react';
import { useHistory } from 'react-router-dom';
import { StyledButton, StyledDiv } from './style';

const HelpVideosButton = () => {
  const handleOptionClick = () => {
    history.push('/help');
  };

  const history = useHistory();
  return (
    <StyledDiv>
      <StyledButton disabled={false} onClick={handleOptionClick}>
        Help Center
      </StyledButton>
    </StyledDiv>
  );
};

export default HelpVideosButton;
