import React from 'react';
import { SingleFormCard } from '@xbcb/display-components';
import SignLinkButton from 'components/SignLinkButton';
import type { Ior, Forwarder, Tag, UsIor } from '@xbcb/api-gateway-client';
import SignLinks from 'components/SignLinks';
export type SignLinkWrapperProps = {
  ior: Ior;
  forwarders: Forwarder[];
  deleted?: boolean;
  canceled?: boolean;
  dsrTags: Tag[];
};

const SignLinksWrapper: React.FC<SignLinkWrapperProps> = ({
  ior,
  forwarders,
  dsrTags,
  deleted = false,
  canceled = false,
}) => {
  if (deleted || canceled) return null;
  return (
    <SingleFormCard
      title="Power of Attorney"
      extraContent={
        <SignLinkButton ior={ior} forwarders={forwarders} dsrTags={dsrTags} />
      }
    >
      <SignLinks dsrs={(ior as UsIor).documentSignRequests} />
    </SingleFormCard>
  );
};

export default SignLinksWrapper;
