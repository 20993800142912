import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  DocsCore,
  DocsCoreCommonProps,
  getRecordIdTag,
} from '@xbcb/document-components';
import SendDocumentModal from 'components/SendDocumentModal';
import InquireWorkOrderModal from 'components/InquireWorkOrderModal';
import InvoiceLineCountModal from 'components/InvoiceLineCountModal';
import { showInquireWorkOrderKebabMenuItemFunc } from 'libs/showInquireWorkOrderKebabMenuItemFunc';
import type {
  WorkOrder,
  WorkOrderTask,
  WorkOrderGroupForwarder,
  CustomsDeclaration,
} from '@xbcb/api-gateway-client';
import {
  useCurrentUser,
  usePermissions,
  useWorkOrderTaskType,
} from 'libs/hooks';
import { ModalKey } from 'types';
import { ObjectType, AnyObject, AccountType } from '@xbcb/shared-types';
import { useModal, useSearchDocuments } from '@xbcb/ui-utils';
import { DocumentPermissions } from '@xbcb/ui-types';
import { showInvoiceLineCountKebabMenuItemFunc } from 'libs/showInvoiceLineCountKebabMenuItemFunc';

const getClientIdTagFromUser = (user: {
  accountType: AccountType;
  customsBroker?: {
    id: string;
  };
  forwarder?: {
    id: string;
  };
  shipper?: {
    id: string;
  };
}) => {
  switch (user.accountType) {
    case AccountType.OPERATOR:
      return {
        key: 'customsBrokerId',
        value: user.customsBroker?.id as string,
      };
    case AccountType.SHIPPER:
      return { key: 'shipperId', value: user.shipper?.id as string };
    case AccountType.FORWARDER:
      return { key: 'forwarderId', value: user.forwarder?.id as string };
    default:
      throw new Error('Unknown account type');
  }
};

// The whole point of Docs is to be a wrapper around DocsCore and provide logic
// generally for all use case in NewAppUi to determine the operatorId,
// permissions, whether to showInquireWorkOrderKebabMenuItem, and if any
// additional tags are needed. These are consider the "DocsCoreAdditionalProps".
// Docs should take the DocsCoreCommonProps as props as they can be configured
// at each individual use case and need to be provided to DocsCore with the
// DocsCoreAdditionalProps that it configures in this file
type DocsProps = DocsCoreCommonProps & {
  workOrder?: WorkOrder;
  workOrderVersion?: number;
  workOrderTasks?: WorkOrderTask[];
  forwarders?: WorkOrderGroupForwarder[];
  // By default, we rely on `usePermissions` to determine the permissions. But,
  // in some rare cases we may want to provide custom permissions
  customPermissions?: DocumentPermissions;
  setAllDocuments?: (documents: AnyObject[]) => void;
  setIsDownloadButtonDisabled?: (isDownloadButtonDisabled: boolean) => void;
  // NOTE: On the SignPage, an operatorId will also be provided via props, we
  // should rely on the solution below in this component instead of using it
};

const Docs = ({
  workOrder,
  workOrderVersion,
  workOrderTasks,
  forwarders,
  customPermissions,
  fallbackRecordId,
  searchQueryTags: providedSearchQueryTags = [],
  // Don't default to `[]` as if they aren't defined, we don't want to provide
  // them to `DocsCore`. This is a different case than searchQueryTags
  additionalTags,
  setAllDocuments,
  setIsDownloadButtonDisabled,
  ...docsProps
}: DocsProps): React.ReactElement => {
  const { workOrderStatus, searchDeletedDocuments } = docsProps;
  const { recordId } = useParams<{ recordId: string }>();
  const { visible: isSendDocumentModalVisible } = useModal(
    ModalKey.SEND_DOCUMENT,
  );
  const { visible: isInquireWorkOrderModalVisible } = useModal(
    ModalKey.INQUIRE_WORK_ORDER,
  );
  const { visible: isInvoiceLineCountModalVisible } = useModal(
    ModalKey.INVOICE_LINE_COUNT,
  );
  const [workOrderTaskType] = useWorkOrderTaskType();
  const user = useCurrentUser();
  const { permissions } = usePermissions(ObjectType.DOCUMENT);
  const { pathname } = useLocation();

  const operatorId = user?.operator?.id;

  const showInquireWorkOrderKebabMenuItem =
    showInquireWorkOrderKebabMenuItemFunc({
      status: workOrderStatus,
      taskType: workOrderTaskType,
      workOrderTasks,
    });

  const showInvoiceLineCountKebabMenuItem =
    showInvoiceLineCountKebabMenuItemFunc({
      status: workOrderStatus,
      taskType: workOrderTaskType,
      workOrderTasks,
    });

  const recordIdTag = getRecordIdTag({
    pathname,
    recordId,
    fallbackRecordId,
    operatorId,
  });

  const searchQueryTags = [recordIdTag, ...(providedSearchQueryTags || [])];
  const { documents } = useSearchDocuments({
    searchDeletedDocuments,
    operatorId,
    tags: searchQueryTags,
  });

  // CustomsDeclaration is currently the only work order for which CountryCode is necessary
  const workOrderCountryCode = (workOrder as CustomsDeclaration)
    ?.goodsShipment?.[0]?.importCountry?.[0]?.country;

  useEffect(() => {
    const documentsDownloadInfo = documents.map(
      ({ fileName, content, extension, documentTags }) => ({
        name: fileName,
        link: content.downloadLink,
        extension: extension.toLowerCase(),
        documentTags,
      }),
    );
    if (setAllDocuments) setAllDocuments(documentsDownloadInfo);
    if (setIsDownloadButtonDisabled) setIsDownloadButtonDisabled(false);
    // eslint thinks we're missing the documents dependency since it's wrapped
    // in JSON.stringify, but we know it's included
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(documents), setAllDocuments, setIsDownloadButtonDisabled]);

  return (
    <>
      {isSendDocumentModalVisible && (
        <SendDocumentModal
          recordId={recordId}
          documents={documents}
          workOrderVersion={workOrderVersion}
          operatorId={operatorId}
        />
      )}
      {isInquireWorkOrderModalVisible && (
        <InquireWorkOrderModal
          documents={documents}
          workOrder={workOrder}
          workOrderId={recordId}
          workOrderVersion={workOrderVersion}
          workOrderTasks={workOrderTasks}
          forwarders={forwarders}
        />
      )}
      {isInvoiceLineCountModalVisible && <InvoiceLineCountModal />}
      <DocsCore
        {...docsProps}
        permissions={customPermissions || permissions}
        operatorId={operatorId}
        showInquireWorkOrderKebabMenuItem={showInquireWorkOrderKebabMenuItem}
        showInvoiceLineCountKebabMenuItem={showInvoiceLineCountKebabMenuItem}
        // In additional to the provided searchQueryTags, we should add the recordIdTag
        searchQueryTags={searchQueryTags}
        // In additional to the provided additionalTags, we should add the recordIdTag
        tagsForNewDocuments={
          additionalTags
            ? [recordIdTag, ...additionalTags, getClientIdTagFromUser(user)]
            : [getClientIdTagFromUser(user)]
        }
        accountType={user.accountType}
        workOrderCountryCode={workOrderCountryCode}
      />
    </>
  );
};

export default Docs;
