import React from 'react';
import { MailOutlined } from '@ant-design/icons';
import { StyledButton } from './styles';
import DelayedTooltip from 'components/DelayedTooltip';

const EscalatedToMe: React.FC<{ taskCount: number }> = ({ taskCount }) => {
  const title = `${taskCount} assignment(s) are currently escalated to you`;
  return (
    <DelayedTooltip title={title} placement="right">
      <StyledButton disabled={false} shape="circle">
        <MailOutlined data-testid="escalated-to-me-tooltip" />
      </StyledButton>
    </DelayedTooltip>
  );
};

export default EscalatedToMe;
