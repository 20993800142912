import {
  recordFields,
  recordActionFields,
  addressFields,
  costFields,
  externalComplianceReferenceFields,
  phoneFields,
} from '@xbcb/shared-queries';
import {
  iorNumberFields,
  periodicMonthlyStatementFragment,
  dailyStatementFragment,
  shipperContainerFragment,
  usConsumptionEntryPointOfContactFields,
  pgaRequirementFragments,
} from '@xbcb/work-order-queries';
import { pointOfContactFields } from '@xbcb/party-queries';
import { inquiryFields } from './inquiryFields';

const continuousBondFields = `
  {
    number
    effectiveDate
    renewalDate
    terminationDate
    suretyCode
  }
`;

const usConsumptionEntryBaseFragment = `fragment usConsumptionEntryBaseFields on UsConsumptionEntry {
  ...recordFields
  operator {
    id
  }
  cbpCharges {
    duties
    taxes
    fees
    adDuties
    cvDuties
    taxesList {
      type
      value
    }
    feesList {
      type
      value
    }
  }
  cbpLiquidatedCharges {
    duties
    taxes
    fees
    adDuties
    cvDuties
    taxesList {
      type
      value
    }
    feesList {
      type
      value
    }
  }
  ior {
    name
    address {
      ...addressFields
    }
    pointOfContact {
      ...usConsumptionEntryPointOfContactFields
    }
    iorNumber {
      ...iorNumberFields
    }
    payerUnitNumber
    duns
    unknownDuns
    preferredPaymentMethod
  }
  consignee {
    name
    address {
      ...addressFields
    }
    pointOfContact {
      ...usConsumptionEntryPointOfContactFields
    }
    iorNumber {
      ...iorNumberFields
    }
    sameAsIor
    unknownDuns
    usConsignee {
      id
      version
    }
  }
  buyer {
    ein
    name
    address {
      ...addressFields
    }
    sameAsIor
    buyer {
      id
      version
    }
  }
  poNumbers
  summaryStatus
  summaryRejectionReasons {
    name
    code
  }
  summaryNotification {
    type
    remarks
    cbpTeam
    cbpOfficer {
      name
    }
  }
  status
  releaseStatus 
  releaseDate
  releaseDispositions {
    billNumber
    code
  }
  releaseRejectionReasons {
    name
    code
  }
  pgaDispositions {
    status
    code
  }
  splitShipmentReleaseMethod
  cbpTeam
  collectionStatus
  paymentType
  merchandiseUse
  dailyStatement {
    ...dailyStatementFields
  }
  periodicMonthlyStatement {
    ...periodicMonthlyStatementFields
  }
  entryNumber
  brokerNumber
  entryType
  singleTransactionBond {
    amount
    accountNumber
    suretyCode
  }
  invoices {
    invoiceNumber
    value {
      ...costFields
    }
    assist {
      ...costFields
    }
    currencyRate
    adjustments {
      type
      value {
        value
        currency
      }
      description
    }
    seller {
      name
      address {
        ...addressFields
      }
      mid
      supplier {
        id
        version
      }
    }
    products {
      quantity
      totalValue {
        ...costFields
      }
      totalAssist {
        ...costFields
      }
      product {
        id
        version
      }
      lines {
        censusWarnings {
          warningCode
          warningStatus
          overrideCode
        }
        externalReference {
          ...externalComplianceReferenceFields
        }
        value
        assist
        description
        ruling {
          type
          number
        }
        poNumber
        spi
        licenseCode
        licenseNumber
        steelExclusionNumber
        aluminumExclusionNumber
        aluminumRequirements {
          aluminumPrimaryCountryOfSmelt
          aluminumCountryOfCast
          aluminumSecondaryCountryOfSmelt
          secondaryAluminumOnly
        }
        productClaimCode
        origin {
          countryCode
          stateCode
        }
        feeOptions {
          required
          disclaim
          type
        }
        taxOptions {
          required
          disclaim
          type
          defer
          rate
        }
        cbpCharges {
          duties
          taxes
          fees
          adDuties
          cvDuties
        }
        manufacturer {
          name
          address {
            ...addressFields
          }
          mid
          supplier {
            id
            version
          }
          related
        }
        tariffs {
          unitValue {
            value
            currency
          }
          unitAssist {
            value
            currency
          }
          unitReportingQuantities {
            value
            unit
          }
          pga {
            ...pgaRequirementFields
          }
          pgaDescription {
            sameAsCbp
            text
          }
          htsNumber
          hts {
            id
            version
          }
          extraPgaFlags
        }
        adCase {
          id
        }
        adDisclaimerCode
        cvCase {
          id
        }
        cvDisclaimerCode
        isNrbsOnFile
        nrbsRecordNumber
      }
    } 
  }
  masterBills {
    number
    houseBills {
      number
      quantity
      nonAMS
      inBondNumber
    }
  }
  containers {
    ...shipperContainerFields
  }
  departure {
    exportDate
    exportCountryCode
    portOfLadingCode
  }
  conveyance {
    modeOfTransport
    containerized
    conveyanceName
    tripNumber
    grossWeight
  }
  arrival {
    portOfUnladingCode
    firmsCode
    examSite
    preferredExamSite
    goNumber
    importDate
    usDestinationStateCode
    inBond {
      portOfEntryCode
      estimatedEntryDate
      initiationDate
    }
  }
  broker {
    name
    address {
      ...addressFields
    }
    pointOfContact {
      ...usConsumptionEntryPointOfContactFields
    }
    usCustomsBroker {
      id
    }
  }
  badges
  loadType
  group {
    id
  }
  stateMachine {
    id
    version
    workOrderType
  }
  milestones {
    id
    name
    occurenceTime
    source
    tags {
      key
      value
    }
  }
  exam {
    site
    status 
    subType
    type
    containers {
      ...shipperContainerFields
    }
  }
}`;

export const usConsumptionEntryFragment = `fragment usConsumptionEntryFields on UsConsumptionEntry {
  ...usConsumptionEntryBaseFields
  invoices {
    products {
      product {
        clientIdentifier
        secondaryIdentifiers {
          type
          value
        }
      }
    }
  }
  invoicesReference {
    downloadLink
    referenceKey
  }
  ior { 
    usIor {
      id
      version
      status
      continuousBonds {
        past ${continuousBondFields}
        present ${continuousBondFields}
        future ${continuousBondFields}
      }
    }
  }
  group {
    shipment {
      id
      created {
        time
      }
      friendlyId
      clientIdentifier
      preferredDutyCollectPartyType
      version
      legs {
        transportationServiceName
      }
      clientReferences {
        bookingId
      }
      tags {
        key
        value
      }
    }
    shipper {
      id
      status
      billingDetails {
        disbursement
        subscriptionPlan
        status
        accountingSystemCustomerReferences {
          type
          value
        }
        customFeeConfig {
          singleEntryBond {
            rate
            minimum
          }
          disbursement {
            rate
          }
        }
      }
      forwarders {
        nodes {
          id
          billingDetails {
            disbursement
            subscriptionPlan
          }
        }
      }
    }
    forwarders {
      forwarder {
        id
        billingDetails {
          disbursement
          subscriptionPlan
          status
          customFeeConfig {
            singleEntryBond {
              rate
              minimum
            }
            disbursement {
              rate
            }
          }
        }
      }      
    }
  }
  stateMachine {
    id
    version
    workOrderType
  }
  milestones {
    created {
      client {
        name
      }
    }
  }
  ${inquiryFields}
}`;

const sharedFragments = `
${usConsumptionEntryBaseFragment}
${recordFields}
${recordActionFields}
${addressFields}
${iorNumberFields}
${periodicMonthlyStatementFragment}
${dailyStatementFragment}
${shipperContainerFragment}
${usConsumptionEntryPointOfContactFields}
${pgaRequirementFragments}
${costFields}
${externalComplianceReferenceFields}
${pointOfContactFields}
${phoneFields}
`;

export const usConsumptionEntryFragments = `
${usConsumptionEntryFragment}
${sharedFragments}`;

export const usConsumptionEntrySubscriptionFragments = `
${sharedFragments}`;
