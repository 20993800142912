import React, { useState } from 'react';
import { Input } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { conformToMask } from 'text-mask-core';
import { show } from '@xbcb/ui-utils';
import { FormItem } from '@xbcb/form-item-components';
import { CssSize, NamePath } from '@xbcb/ui-types';

// This component expects `CbpAssignedNumber` which is defined as : /^\d{6}-\d{5}$/
interface CAINNumberProps {
  localNamePath: NamePath;
  fullNamePath: NamePath;
  disabled?: boolean;
  required?: boolean;
  form: FormInstance;
  readOnly?: boolean;
}

const mask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
const cainLength = 12;

const CAINNumber: React.FC<CAINNumberProps> = ({
  localNamePath,
  fullNamePath,
  disabled,
  required,
  form,
  readOnly,
}) => {
  const [cainPreviousConformedValue, setCainPreviousConformedValue] =
    useState('');

  const handleCainChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (!e || !e.currentTarget) return e;

    // Had to change this to currentTarget for ts to be happy
    const { value } = e.currentTarget;
    const { conformedValue } = conformToMask(value, mask, {
      guide: false,
      previousConformedValue: cainPreviousConformedValue,
    });

    setCainPreviousConformedValue(conformedValue);

    return conformedValue;
  };

  const showComponent = show({
    readOnly,
    form,
    field: fullNamePath,
  });

  return showComponent ? (
    <FormItem
      $itemSize={CssSize.TINY_SHORT}
      name={localNamePath}
      rules={[
        {
          required,
          min: cainLength,
          max: cainLength,
          message: ' ',
        },
      ]}
      getValueFromEvent={handleCainChange}
      label="CBP Assigned No."
      $inline
      $readOnly={readOnly}
    >
      <Input placeholder="NNNNNN-NNNNN" disabled={disabled} />
    </FormItem>
  ) : null;
};

export default CAINNumber;
