import { WorkOrderTab } from '@xbcb/ui-types';

export const DEFAULT = 'DEFAULT';
export const JP = 'JP';
// DEFAULT tab configuration is the full set of tabs available, i.e. the tabs you would see when looking at the full Work Order page instead of a single Work Order Task page
// N.B. A typical work order will have AT LEAST the Documents, Tasks, and Summary tabs.
export const baseEuEntryWorkOrderTabs = [
  WorkOrderTab.Documents,
  WorkOrderTab.Milestones,
  WorkOrderTab.Charges,
];
export const defaultEuEntryWorkOrderTabs = [
  ...baseEuEntryWorkOrderTabs,
  WorkOrderTab.Tasks,
];

export const defaultEuEntryWorkOrderTabsForFbaScotForwardingOps = [
  WorkOrderTab.Documents,
  WorkOrderTab.Milestones,
  WorkOrderTab.Tasks,
];

export const defaultIorActivationTabs = [
  WorkOrderTab.Documents,
  WorkOrderTab.Tasks,
  WorkOrderTab.Summary,
];

export const iorActivationTaskTypeTabs = [
  WorkOrderTab.Documents,
  WorkOrderTab.Summary,
];

export const getBaseEuCustomsEntryConfiguration = (customsEntryTaskType: {
  [key: string]: string;
}): {
  [x: string]: WorkOrderTab[];
} =>
  Object.values(customsEntryTaskType).reduce(
    (config: Record<string, WorkOrderTab[]>, euTaskType: string) => ({
      ...config,
      [euTaskType]: baseEuEntryWorkOrderTabs,
    }),
    {},
  );
