import { WorkOrderTaskType } from '__generated__/graphql';

export const isExamOrHoldWorkOrderTask = (
  taskType: WorkOrderTaskType | string | undefined,
): boolean => {
  return (
    taskType != undefined &&
    [
      WorkOrderTaskType.UsConsumptionEntryCbpExam,
      WorkOrderTaskType.UsConsumptionEntryCbpHold,
      WorkOrderTaskType.UsConsumptionEntryPgaHold,
      WorkOrderTaskType.UsType86EntryCbpExam,
      WorkOrderTaskType.UsType86EntryCbpHold,
      WorkOrderTaskType.UsConsumptionEntryPgaHold,
    ].includes(taskType as WorkOrderTaskType)
  );
};
