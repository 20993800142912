import React from 'react';
import { Input } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { NamePath } from 'rc-field-form/lib/interface';
import { show } from '@xbcb/ui-utils';
import { CssSize, DataCyPrefix } from '@xbcb/ui-types';
import { FormItem } from '@xbcb/form-item-components';

export type MIDProps = {
  disabled?: boolean;
  readOnly?: boolean;
  form: FormInstance;
  handleMidFocus?: (event: React.FocusEvent<HTMLElement>) => void;
  field: NamePath;
};

const MID_MAX_LENGTH = 15;

const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
  if (!e || !e.currentTarget) return e;
  const { value } = e.currentTarget;
  return value.toUpperCase();
};

const MID = ({
  disabled,
  handleMidFocus,
  readOnly,
  form,
  field = 'mid',
}: MIDProps) => {
  const showComponent = show({ readOnly, form, field });
  return showComponent ? (
    <FormItem
      $itemSize={CssSize.TINY_SHORT}
      label="MID"
      name={field}
      rules={[
        {
          required: true,
          message: ' ',
          max: MID_MAX_LENGTH,
        },
      ]}
      $readOnly={readOnly}
      $inline
      getValueFromEvent={handleChange}
      data-cy={DataCyPrefix.MID}
    >
      <Input
        disabled={disabled}
        onFocus={handleMidFocus}
        maxLength={MID_MAX_LENGTH}
      />
    </FormItem>
  ) : null;
};

export default MID;
