import React from 'react';
import { FormInstance } from 'antd/lib/form';
import { INLTSwitch } from '@xbcb/form-item-components';
import { NamePath } from '@xbcb/ui-types';
import { StyledDiv, StyledTooltip } from './styles';

type SupportsPersonalEffectsProps = {
  disabled?: boolean;
  form: FormInstance;
  readOnly?: boolean;
  localNamePath: NamePath;
  fullNamePath: NamePath;
  $inline?: boolean;
};

const SupportsPersonalEffects: React.FC<SupportsPersonalEffectsProps> = ({
  disabled,
  form,
  readOnly,
  localNamePath,
  fullNamePath,
  $inline,
}) => {
  const supportsPersonalEffects = form.getFieldValue(fullNamePath);
  return (
    <StyledDiv $inline={$inline}>
      <INLTSwitch
        onWord="Yes"
        offWord="No"
        label="Supports Personal Effects"
        field={localNamePath}
        readOnly={readOnly}
        disabled={disabled}
        initialValue={supportsPersonalEffects === true}
      />
      <StyledTooltip
        title="Personal effects are personal items that belong to, and are used by, only one person (e.g. apparel, jewelry). Personal effects shipping involves sending such items across country or internationally - typically to some destination where the owner of these items is also traveling to."
        spaceTop
      />
    </StyledDiv>
  );
};

export default SupportsPersonalEffects;
