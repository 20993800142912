import React from 'react';
import { RecordType } from '@xbcb/shared-types';
import SCACSelect from 'components/SCACSelect';
import EINNumber from 'components/EINNumber';
import CAINNumber from 'components/CAINNumber';
import PartyForm from 'components/PartyForm';
import { RecordProps } from 'routes';
import { Divider } from 'antd';
import { PointOfContact } from '@xbcb/form-components';
import { DataCySuffix } from '@xbcb/ui-types';

const Trucker: React.FC<RecordProps> = ({
  disabled,
  form,
  readOnly,
  hideDocs,
}) => {
  const dispatchPointOfContactNamePath = ['dispatchPointOfContact'];
  return (
    <PartyForm
      recordType={RecordType.TRUCKER}
      readOnly={readOnly}
      disabled={disabled}
      form={form}
      addressRequired
      hideDocs={hideDocs}
      companyTileTitle="Trucker Info"
      hideDba={true}
      hideGlobalPhone={true}
      hideGlobalEmail={true}
      hideDuns={true}
      hidePocTitle={true}
      hideBusinessStructure={true}
      additionalCompanyInfo={
        <>
          <CAINNumber
            form={form}
            disabled={disabled}
            readOnly={readOnly}
            localNamePath={['cain']}
            fullNamePath={['cain']}
          />
          <EINNumber
            form={form}
            disabled={disabled}
            readOnly={readOnly}
            localNamePath={['ein']}
            fullNamePath={['ein']}
          />
          <SCACSelect
            form={form}
            disabled={disabled}
            readOnly={readOnly}
            localNamePath={['scac']}
            fullNamePath={['scac']}
            $inline
          />

          <Divider />
          <h4>Dispatch Point of Contact (optional)</h4>
          <PointOfContact
            readOnly={readOnly}
            disabled={disabled}
            form={form}
            fullNamePath={dispatchPointOfContactNamePath}
            localNamePath={dispatchPointOfContactNamePath}
            defaultCountryCode="1"
            allowClearCountryCode={false}
            dataCySuffix={DataCySuffix.DISPATCH_POINT_OF_CONTACT}
          />
        </>
      }
    />
  );
};

export default Trucker;
