import { SearchQuery } from '@xbcb/shared-queries';
import React from 'react';
import { Collapse } from 'antd';
import { constantCase } from '@xbcb/js-utils';
import { getEnv } from '@xbcb/ui-utils';
import {
  RESET_PASSWORD_FOR_EXT_CUSTOMERS_FEATURE,
  resetPasswordForExtCustomersFeatureName,
} from '@xbcb/feature-flags';
import { uiStageToBackendStage } from '@xbcb/ui-types';
import {
  RecordType,
  KeywordSearchOperator,
  DateSearchOperator,
} from '@xbcb/shared-types';
import './AccountDetails.less';
import { SingleFormCard } from '@xbcb/display-components';
import { FormInstance } from 'antd/lib/form';
import { get } from 'lodash';
import { useCurrentUser } from 'libs/hooks';
import { useQuery } from '@apollo/client';
import SwitchUserButton from 'components/SwitchUserButton';
import ResetPasswordButton from 'components/ResetPasswordButton';
import {
  PasswordResettableUserType,
  passwordResettableUserTypes,
} from '../ResetPasswordButton/component';
import { noCase, pascalCase } from 'change-case';
import type { ForwarderUser, ShipperUser } from '@xbcb/api-gateway-client';

interface AccountDetailsProps {
  record?: any;
  recordId?: string;
  recordType: RecordType;
  form: FormInstance;
}
type ClientUserType = ShipperUser | ForwarderUser;

const AccountDetails = ({
  record,
  recordId,
  recordType,
  form,
}: AccountDetailsProps) => {
  const currentUser = useCurrentUser();
  const { Panel } = Collapse;
  const accountUserRecordType = `${constantCase(
    recordType,
  )}_USER` as RecordType;

  const isPasswordResettableType = isPasswordResettableUserType(
    accountUserRecordType,
  );

  const userHasResetPasswordPermission = checkResetPasswordPermission(
    currentUser.id,
  );

  const searchUserQuery = SearchQuery({
    recordName: accountUserRecordType,
    fields: `id root title email name`,
  });
  const { data } = useQuery(searchUserQuery, {
    variables: {
      input: {
        searchCriteria: {
          [`${noCase(recordType)}Id` as keyof any]: {
            values: [record.id],
            operator: KeywordSearchOperator.EQUALS,
          },
          operatorId: {
            values: currentUser.operator?.id,
            operator: DateSearchOperator.EQUALS,
          },
          deletedTime: {
            operator: DateSearchOperator.DOES_NOT_EXIST,
          },
        },
      },
    },
  });

  const accountUsers: ClientUserType[] = get(
    data,
    `search${pascalCase(recordType)}Users.results`,
    [],
  );
  if (accountUsers.length === 0) return null;
  return (
    <SingleFormCard title="Account Details">
      {accountUsers.length > 0 && (
        <Collapse bordered={false}>
          {accountUsers.map((user: ClientUserType) => {
            const header = (
              <p>
                {user.title}:{' '}
                <b>
                  {user.name}
                  {user.root && ' *'}
                </b>
                :{`< ${user.email} >`}
                <b>
                  {'      '}
                  <SwitchUserButton assumedUserId={user.id}></SwitchUserButton>
                </b>
                {userHasResetPasswordPermission && isPasswordResettableType && (
                  <b>
                    <ResetPasswordButton
                      userId={user.id}
                      userType={accountUserRecordType}
                    ></ResetPasswordButton>
                  </b>
                )}
              </p>
            );
            return <Panel key={user.id} header={header}></Panel>;
          })}
        </Collapse>
      )}
    </SingleFormCard>
  );
};

const checkResetPasswordPermission = (operatorUserId?: string): boolean => {
  return (
    operatorUserId != null &&
    RESET_PASSWORD_FOR_EXT_CUSTOMERS_FEATURE.isEnabled(
      resetPasswordForExtCustomersFeatureName,
      {
        stage: uiStageToBackendStage[getEnv().stage],
        operator: operatorUserId,
      },
    )
  );
};

const isPasswordResettableUserType = (
  recordType: RecordType,
): recordType is PasswordResettableUserType => {
  return passwordResettableUserTypes.includes(
    recordType as PasswordResettableUserType,
  );
};

export default AccountDetails;
