import { capitalCase } from 'change-case';
import { Select, Option, FormItem } from '@xbcb/form-item-components';
import { AccountType, SubscriptionPlan } from '@xbcb/shared-types';
import { CssSize, NamePath } from '@xbcb/ui-types';
import { useCurrentUser } from 'libs/hooks';

interface PlanSelectProps {
  disabled?: boolean;
  readOnly?: boolean;
  localNamePath: NamePath;
}

const PlanSelect: React.FC<PlanSelectProps> = ({
  disabled,
  localNamePath,
  readOnly,
}) => {
  const { accountType } = useCurrentUser();
  return (
    <FormItem
      name={localNamePath}
      required
      label="Plan"
      $itemSize={CssSize.TINY}
      $inline
      $readOnly={readOnly}
    >
      <Select
        disabled={disabled}
        optionLabelProp="children"
        dropdownMatchSelectWidth={false}
      >
        {[
          // Intentionally listing these out instead of using Object.values as
          // the order in which we display them in is critical
          SubscriptionPlan.LITE,
          SubscriptionPlan.BASIC,
          SubscriptionPlan.PRO,
          SubscriptionPlan.ENTERPRISE,
        ].map((plan) => {
          // LITE plans can only be selected for shippers
          const hideOption =
            plan === SubscriptionPlan.LITE &&
            accountType !== AccountType.SHIPPER;
          if (hideOption) return null;
          return (
            <Option key={plan} value={plan}>
              {capitalCase(plan)}
            </Option>
          );
        })}
      </Select>
    </FormItem>
  );
};

export default PlanSelect;
