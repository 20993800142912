import downloadURL from 'libs/downloadURL';
import ModalComponents from 'components/ModalComponents';
import { RawHTML } from '@xbcb/static-text-components';
import safeStringify from 'libs/safeStringify';
import { notification, message, Modal } from 'antd';
import { Cognito } from '@xbcb/aws-utils';
import { UiStage } from '@xbcb/ui-types';
import { store } from 'store';
import { getEnv, reportError, openFetchDocTab } from '@xbcb/ui-utils';
import { timeout } from '@xbcb/js-utils';
import awsIOT from 'aws-iot-device-sdk';
import { fromTemporaryCredentials } from '@aws-sdk/credential-providers';
import { createAction } from 'redux-actions';

let client;

export const MQTT_LOADED = createAction('MQTT_LOADED');

export const MQTT_DISCONNECT = () => async (dispatch) => {
  if (client)
    await new Promise((resolve) => {
      client.end(false, (err) => {
        if (!err) {
          client = undefined;
          dispatch(MQTT_LOADED(false));
          resolve();
        }
      });
    });
};

const SHOW_MESSAGE =
  ({
    level = 'info',
    message: messageText, // don't want to conflict with antd message
  }) =>
  () => {
    try {
      message[level](messageText);
    } catch (e) {
      reportError(e);
    }
  };

const SHOW_NOTIFICATION =
  ({ level = 'info', message: messageText, description, duration = 5 }) =>
  () => {
    try {
      notification[level]({
        message: messageText,
        description: <RawHTML html={description} />,
        duration,
      });
    } catch (e) {
      reportError(e);
    }
  };

const DOWNLOAD_URL =
  ({ url, fileName, newTab }) =>
  () => {
    if (newTab) {
      const w = openFetchDocTab();
      if (w) w.location.href = url;
    } else {
      try {
        downloadURL(url, fileName);
      } catch (e) {
        reportError(e);
      }
    }
  };

const SHOW_MODAL =
  ({
    level = 'success',
    title,
    component,
    props = {},
    content,
    shouldRefresh,
    ...otherModalProps
  }) =>
  async () => {
    const C = ModalComponents(component);
    try {
      const modal = Modal[level]();
      const modalUpdate = {
        title,
        content: C ? (
          <C {...props} modal={modal} store={store} />
        ) : (
          <RawHTML html={content} />
        ),
        okText: shouldRefresh ? 'Refresh' : 'Close',
        onOk: shouldRefresh ? () => window.location.reload() : undefined,
        width: 550,
        ...otherModalProps,
      };
      modal.update(modalUpdate);
      await timeout(0); // the modal is referenced asynchronously so we need to wait a render cycle for it to appear.
      const modalElement = document.querySelector('.ant-modal-wrap'); // this isn't great to do w/ React but I don't see an easy way to get a ref to the DOM element.
      if (modalElement) {
        modalElement.scrollTop = 0; // really big modals overflow the screen and by default the middle is centered on the screen. We always want the top of the modal to be visible.
      }
    } catch (e) {
      reportError(e);
    }
  };

if (process.env.REACT_APP_STAGE === UiStage.LOCAL) {
  window.SHOW_MODAL = SHOW_MODAL;
  window.SHOW_MESSAGE = SHOW_MESSAGE;
  window.SHOW_NOTIFICATION = SHOW_NOTIFICATION;
  window.DOWNLOAD_URL = DOWNLOAD_URL;
}

const stageMap = {
  [UiStage.LOCAL]: UiStage.BETA.toLowerCase(),
  [UiStage.ALPHA]: UiStage.BETA.toLowerCase(),
  [UiStage.BETA]: UiStage.BETA.toLowerCase(),
  [UiStage.GAMMA]: UiStage.GAMMA.toLowerCase(),
  [UiStage.PROD]: UiStage.PROD.toLowerCase(),
};

export const MQTT_CONNECT = (cbmsUserId) => async (dispatch) => {
  try {
    const {
      stage,
      AWS_IOT_ENDPOINT: host,
      IOT_ASSUME_ROLE_UI_ARN: iotAssumeRoleArn,
    } = getEnv();
    let creds;

    const provider = await Cognito.authUser().catch(() => null); // Fallback to null if Cognito fails

    if (provider) {
      // Use Cognito credentials if available
      creds = await provider();
    } else {
      creds = fromTemporaryCredentials({
        params: {
          RoleArn: iotAssumeRoleArn,
        },
      });
    }

    const params = {
      host,
      protocol: 'wss',
      clientId: `${stage}-${creds.identityId}-${Math.floor(
        Math.random() * 100000 + 1,
      )}`,
      accessKeyId: creds.accessKeyId,
      secretKey: creds.secretAccessKey,
      sessionToken: creds.sessionToken,
    };

    client = await awsIOT.device(params);

    client.on('connect', () => {
      client.subscribe(`cbms-app/${stageMap[stage]}/${cbmsUserId}`, (err) => {
        if (!err) dispatch(MQTT_LOADED(true));
      });
    });
  } catch (e) {
    reportError(e);
    throw new Error('Unable to authenticate and connect to MQTT');
  }
  const dispatchAction = (messageObject) => {
    if (messageObject.type) {
      try {
        switch (messageObject.type) {
          case 'SHOW_MESSAGE':
            dispatch(SHOW_MESSAGE(messageObject));
            break;
          case 'SHOW_NOTIFICATION':
            dispatch(SHOW_NOTIFICATION(messageObject));
            break;
          case 'SHOW_MODAL':
            dispatch(SHOW_MODAL(messageObject));
            break;
          case 'DOWNLOAD_URL':
            dispatch(DOWNLOAD_URL(messageObject));
            break;
          default:
            dispatch(messageObject);
        }
      } catch (e) {
        reportError(e);
      }
    }
  };

  client.on('message', (topic, payload) => {
    const parsedMessage = JSON.parse(payload);
    if (Array.isArray(parsedMessage)) {
      parsedMessage.map((messageObject) => dispatchAction(messageObject));
    } else {
      dispatchAction(parsedMessage);
    }
  });

  client.on('error', async (error) => {
    const stringified = safeStringify(error);
    if (error.isTrusted === true) {
      // retry connection
      await dispatch(MQTT_DISCONNECT());
      await dispatch(MQTT_CONNECT());
    } else {
      try {
        reportError(new Error(stringified));
      } catch (e) {
        reportError(e);
      }
    }
  });
};
