import React from 'react';
import { Divider, Form, InputNumber } from 'antd';
import { defaultDisbursementFeeRateAfterSubscriptions } from '@xbcb/finance-utils';
import { Checkbox, FormItem } from '@xbcb/form-item-components';
import { CssSize, NamePath } from '@xbcb/ui-types';
import AccountingPointOfContacts from 'components/AccountingPointOfContacts';
import BillingDetailsCustomFees from 'components/BillingDetailsCustomFees';
import CurrencyField from 'components/CurrencyField';
import SourceSelect from 'components/SourceSelect';
import { shouldUpdate } from '@xbcb/ui-utils';
import { FormInstance } from 'antd/lib/form';

export interface CustomerFeesProps {
  isSubscriptionsDisabled: boolean;
  disabled?: boolean;
  readOnly?: boolean;
}

const CustomerFees: React.FC<CustomerFeesProps> = ({
  isSubscriptionsDisabled,
  disabled,
  readOnly,
}) => {
  const form = Form.useFormInstance();
  const billingDetailsNamePath = ['billingDetails'];
  const billingDetailsItemSize = CssSize.TINY_SHORT;
  const customSingleEntryBondNamePath = [
    ...billingDetailsNamePath,
    'customFeeConfig',
    'singleEntryBond',
  ];
  const customSingleEntryBondRateNamePath = [
    ...customSingleEntryBondNamePath,
    'rate',
  ];
  const customSingleEntryBondMinimumNamePath = [
    ...customSingleEntryBondNamePath,
    'minimum',
  ];

  const customDisbursementRateNamePath = [
    ...billingDetailsNamePath,
    'customFeeConfig',
    'disbursement',
    'rate',
  ];
  const defaultDisbursementRatePercentage =
    defaultDisbursementFeeRateAfterSubscriptions * 100;

  const disbursementNamePath = [...billingDetailsNamePath, 'disbursement'];

  return (
    <>
      <h4>Fee Details</h4>
      <UniformCurrencyField
        label="Credit Limit"
        namePath={[...billingDetailsNamePath, 'creditDetails', 'creditLimit']}
        form={form}
        readOnly={readOnly}
        disabled={disabled}
      />
      <SourceSelect
        localNamePath={[...billingDetailsNamePath, 'paymentMethod']}
        $inline
        $itemSize={billingDetailsItemSize}
        readOnly={readOnly}
        disabled={disabled}
      />
      <Form.Item
        shouldUpdate={shouldUpdate([customSingleEntryBondMinimumNamePath])}
        noStyle
      >
        {() => (
          <UniformCurrencyField
            label="Custom STB Rate (per $1000)"
            namePath={customSingleEntryBondRateNamePath}
            required={Boolean(
              form.getFieldValue([
                ...customSingleEntryBondMinimumNamePath,
                'value',
              ]),
            )}
            form={form}
            readOnly={readOnly}
            disabled={disabled}
          />
        )}
      </Form.Item>
      <Form.Item
        shouldUpdate={shouldUpdate([customSingleEntryBondRateNamePath])}
        noStyle
      >
        {() => (
          <UniformCurrencyField
            label="Custom STB Minimum"
            namePath={customSingleEntryBondMinimumNamePath}
            required={Boolean(
              form.getFieldValue([
                ...customSingleEntryBondRateNamePath,
                'value',
              ]),
            )}
            form={form}
            readOnly={readOnly}
            disabled={disabled}
          />
        )}
      </Form.Item>
      {isSubscriptionsDisabled && (
        <FormItem
          shouldUpdate={shouldUpdate([customDisbursementRateNamePath])}
          noStyle
          name={customDisbursementRateNamePath}
          label="Custom Disbursement Rate"
          style={{ width: '200px' }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber<number>
            min={0}
            max={100}
            addonAfter="%"
            defaultValue={defaultDisbursementRatePercentage}
            disabled={disabled}
            aria-label="Custom Disbursement Rate"
            required={true}
          />
        </FormItem>
      )}
      {!isSubscriptionsDisabled && (
        <Checkbox
          disabled={disabled}
          readOnly={readOnly}
          form={form}
          fullNamePath={disbursementNamePath}
          localNamePath={disbursementNamePath}
          text="Disbursement fee charged?"
          $spaceTop
        />
      )}
      <Divider />
      <BillingDetailsCustomFees
        form={form}
        disabled={disabled}
        readOnly={readOnly}
        fullNamePath={[...billingDetailsNamePath, 'customFees']}
      />
      <AccountingPointOfContacts
        form={form}
        disabled={disabled}
        readOnly={readOnly}
        fullNamePath={[...billingDetailsNamePath, 'accountingPointofContacts']}
      />
    </>
  );
};

// Each CurrencyField should render the same minus the label and namePath
const UniformCurrencyField = ({
  label,
  namePath,
  required,
  form,
  readOnly,
  disabled,
}: {
  label: string;
  namePath: NamePath;
  required?: boolean;
  form: FormInstance;
  readOnly?: boolean;
  disabled?: boolean;
}) => (
  <CurrencyField
    form={form}
    label={label}
    fullNamePath={namePath}
    $itemSize={CssSize.TINY_SHORT}
    disabled={disabled}
    readOnly={readOnly}
    required={required}
  />
);

export default CustomerFees;
