import './App.less';
import React, { useState, useEffect, useCallback } from 'react';
import { useApolloClient, QueryResult } from '@apollo/client';
import { message, Layout } from 'antd';
import AppHeader from 'components/AppHeader';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import { Cognito } from '@xbcb/aws-utils';
import { customDomainRuntimeInfo } from 'libs/sharedQueries';
import { cachePersistor } from '@xbcb/apollo-client';
import { reportError, processCustomDomainRuntimeInfo } from '@xbcb/ui-utils';
import { useSelector, useDispatch } from 'react-redux';
import { loginAsync, LOGOUT_ASYNC } from 'actions';
import LoadingSpinner from 'components/LoadingSpinner';
import Routes from 'components/Routes';
import { apolloLogout } from 'libs/logout';
import { usePrevious, useTrackPageLoad } from 'libs/hooks';
import { PersistState } from 'redux-persist';
import initServices from '../../services';
import { isMonsEnv } from '@xbcb/ui-env';

const isMons = isMonsEnv();

const initializeApolloCache = async () => {
  if (localStorage.getItem('DISABLE_APOLLO_CACHE')) return;
  try {
    await cachePersistor.restore();
  } catch (e) {
    // We can load without the cache, no need to block the app if this fails for any reason.
    message.error('Unable to initialize Apollo cache');
    reportError(e);
    await cachePersistor.purge();
  }
};

const StyledLayout = styled(Layout)`
  display: flex;
  flex-flow: column;
  min-height: 100%;

  h2 {
    margin-bottom: 0;
    line-height: normal;
  }

  .ant-legacy-form-item {
    margin-bottom: 16px;
  }
`;

type RootSelectorState = {
  _persist: PersistState;
};

const useInitializeApp = () => {
  // hooks
  const { rehydrated } = useSelector((state: RootSelectorState) => {
    return {
      rehydrated: state._persist.rehydrated,
    };
  });
  const dispatch = useDispatch();
  const apolloClient = useApolloClient();
  const login = useCallback(
    // typing as boolean (the return value of the dispatched function) until we
    // properly type Dispatch (it does not understand the middleware that will
    // be applied). See this section of the redux documentation for more info
    // on useDispatch: https://redux.js.org/recipes/usage-with-typescript#define-typed-hooks
    (loginParams) => dispatch(loginAsync(loginParams)) as unknown as boolean,
    [dispatch],
  );
  const logout = useCallback(async () => {
    dispatch(LOGOUT_ASYNC());
    await apolloLogout();
  }, [dispatch]);
  const [isRehydrating, setIsRehydrating] = useState(true);
  const prevIsRehydrating = usePrevious(isRehydrating);
  const location = useLocation();
  const history = useHistory();
  const [
    isLoadingCustomDomainRuntimeInfo,
    setIsLoadingCustomDomainRuntimeInfo,
  ] = useState(true);
  const [isLoadingUserToken, setIsLoadingUserToken] = useState(true);

  if (isRehydrating && rehydrated) setIsRehydrating(false);

  useEffect(() => {
    const initialize = async () => {
      if (prevIsRehydrating === true && isRehydrating === false) {
        // Load cached Apollo data from localForage
        //  await initializeApolloCache();
        try {
          // get custom domain runtime info
          const runtimeInfoResult = await apolloClient.query({
            query: customDomainRuntimeInfo,
          });
          processCustomDomainRuntimeInfo(runtimeInfoResult as QueryResult);
          initServices(); // butter, sentry, etc... rely on environment variables from this runtime info
          setIsLoadingCustomDomainRuntimeInfo(false);
        } catch (e) {
          reportError(e);
          await apolloLogout();
        }

        // log the user out if on the login page
        if (
          location.pathname === '/login' &&
          location.search.startsWith('?link')
        ) {
          await logout();
          setIsLoadingUserToken(false);
          return;
        }

        if (isMons) {
          try {
            const success = await login({});
            if ((await success) && location.pathname === '/login') {
              history.push('/');
            }
          } catch (e) {
            if (e.code !== 'NotAuthorizedException') {
              reportError(e);
            }
          }
          setIsLoadingUserToken(false);
          return;
        }
        const currentUser = Cognito.getCurrentUser();

        if (currentUser === null) {
          setIsLoadingUserToken(false);
          return;
        }

        // auto login based off of cached credentials
        try {
          const userToken = await Cognito.getUserToken(currentUser);
          if (userToken !== null) {
            const userAttributes = await Cognito.getUserAttributes();
            const success = await login({ ...userAttributes, userToken });
            if ((await success) && location.pathname === '/login') {
              history.push('/');
            }
          }
        } catch (e) {
          if (e.code !== 'NotAuthorizedException') {
            reportError(e);
          }
        }

        setIsLoadingUserToken(false);
      }
    };

    initialize();
  }, [
    prevIsRehydrating,
    isRehydrating,
    location.pathname,
    location.search,
    logout,
    login,
    history,
    apolloClient,
  ]);

  return {
    isLoadingUserToken,
    isRehydrating,
    isLoadingCustomDomainRuntimeInfo,
  };
};

const App = (): JSX.Element => {
  useTrackPageLoad();
  const {
    isLoadingUserToken,
    isRehydrating,
    isLoadingCustomDomainRuntimeInfo,
  } = useInitializeApp();
  const isLoading =
    isLoadingUserToken || isRehydrating || isLoadingCustomDomainRuntimeInfo;
  const { pathname } = useLocation();

  return (
    <StyledLayout>
      {/* AppHeader should show on all pages except for the SignPage (which is reserved for `/sign/`) */}
      {!pathname.startsWith('/sign/') && <AppHeader isLoading={isLoading} />}
      {isLoading ? <LoadingSpinner size={'large'} /> : <Routes />}
    </StyledLayout>
  );
};

export default App;
