import React from 'react';
import { gql, useQuery } from '@apollo/client';
import DelayedTooltip from 'components/DelayedTooltip';
import { StyledButton, StyledDiv } from './style';
import { ConnectionStatus } from '@xbcb/shared-types';
import { useModal } from '@xbcb/ui-utils';
import { ConnectionStatusOperatingRegion, ModalKey } from 'types';
import { DataCyPrefix } from '@xbcb/ui-types';
import ChannelConnectionStatusModal from 'components/ChannelConnectionStatusModal';
import {
  connectionStatusIconMap,
  connectionStatusTooltipTextMap,
  channelConnectionQueryInput,
} from './constants';

const QUERY_CHANNEL_CONNECTION_STATUS = gql`
  query queryChannelConnectionStatus(
    $input: QueryChannelConnectionStatusInput!
  ) {
    queryChannelConnectionStatus(input: $input) {
      status
      statusHistory {
        status
        time
      }
    }
  }
`;

interface ChannelConnectionStatusButtonProps {
  operatingRegion: ConnectionStatusOperatingRegion;
}

const ChannelConnectionStatusButton = ({
  operatingRegion,
}: ChannelConnectionStatusButtonProps) => {
  const { data } = useQuery(QUERY_CHANNEL_CONNECTION_STATUS, {
    variables: {
      input: channelConnectionQueryInput[operatingRegion],
    },
  });

  const { queryChannelConnectionStatus } = data || {};

  const { status, statusHistory } = queryChannelConnectionStatus || {};

  const {
    openModal: openChannelConnectionModal,
    visible: channelConnectionModalVisible,
    closeModal: closeChannelConnectionModal,
  } = useModal(ModalKey.CHANNEL_CONNECTION_STATUS);
  const handleOptionClick = () => {
    openChannelConnectionModal();
  };

  return (
    <>
      {queryChannelConnectionStatus && (
        <StyledDiv>
          <ChannelConnectionStatusModal
            open={channelConnectionModalVisible}
            closeModal={closeChannelConnectionModal}
            channelConnectionStatus={status}
            connectionHistory={statusHistory}
            operatingRegion={operatingRegion}
          />
          <DelayedTooltip
            title={
              connectionStatusTooltipTextMap[operatingRegion][
                status as ConnectionStatus
              ]
            }
          >
            <StyledButton
              disabled={false}
              shape="circle"
              onClick={handleOptionClick}
              data-cy={DataCyPrefix.CHANNEL_CONNECTION_STATUS_BUTTON}
            >
              {
                connectionStatusIconMap[operatingRegion][
                  status as ConnectionStatus
                ]
              }
            </StyledButton>
          </DelayedTooltip>
        </StyledDiv>
      )}
    </>
  );
};

export default ChannelConnectionStatusButton;
