import React from 'react';
import { RocketOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import './NewBundleAvailable.less';

const NewBundleAvailable = () => (
  <div className="new-bundle button-wrapper">
    <Tooltip title="New update available, click to refresh.">
      <Button
        icon={<RocketOutlined />}
        shape="circle"
        onClick={() => window.location.reload()}
      />
    </Tooltip>
  </div>
);

export default NewBundleAvailable;
