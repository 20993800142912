// TODO Make a "don't show" map.

import React from 'react';
import moment from 'moment';
import {
  inactive,
  workOrderMilestoneToColorMap,
} from 'libs/workOrderMilestoneToColorMap';
import { formatWorkOrderMilestoneName } from 'libs/formatWorkOrderMilestoneName';
import {
  StyledButterContent,
  StyledTimelineItem,
  StyledMilestoneParagraph,
  StyledInquiryCodesTag,
} from './styles';
import type { WorkOrderInquiry } from '@xbcb/api-gateway-client';
import { WorkOrderMilestoneName } from '@xbcb/work-order-types';
import { codeToText } from 'libs/formatCodeToText';
import { useCurrentUser } from 'libs/hooks';
import { DataCyPrefix, createDataCyValue } from '@xbcb/ui-types';

enum MilestoneSource {
  CBMS = 'CBMS',
  SYSTEM = 'SYSTEM',
}

const getTimestamp = (iso: string) =>
  moment(iso).format('MMM D YYYY, h:mm a zz');

interface MilestoneProps {
  isOpen?: boolean;
  // TODO use Apollo codegen type
  milestone: any; // WorkOrderMilestone
  toggleOpen: any;
  inquiries?: WorkOrderInquiry[];
  customContent?: React.ReactNode;
}

const Milestone = ({
  isOpen,
  milestone,
  toggleOpen,
  inquiries,
  customContent,
}: MilestoneProps) => {
  const { accountType } = useCurrentUser();
  const { id: milestoneId, name, occurenceTime, source, created } = milestone;
  const formattedMilestoneName = formatWorkOrderMilestoneName(
    name,
    accountType,
    'capital',
  );
  const clientName = created?.client?.name;
  const sourceName =
    source && source !== MilestoneSource.CBMS
      ? source
      : clientName
      ? clientName
      : MilestoneSource.SYSTEM;

  const butterKey = `milestone_${formatWorkOrderMilestoneName(
    name,
    accountType,
  )}`;

  const handleClick = () => {
    toggleOpen(milestoneId);
  };

  let inquiryReasonCodes: string[] = [];
  if (
    inquiries &&
    milestone.name === WorkOrderMilestoneName.WORK_ORDER_PENDING_INQUIRY
  ) {
    const inquiry = inquiries.find(
      (inquiry) => inquiry.milestone.id === milestone.id,
    );
    if (inquiry) {
      inquiryReasonCodes = inquiry.reasonCodes ?? [];
    }
  }

  const reasonCodeTags = inquiryReasonCodes.map((code) => (
    <StyledInquiryCodesTag>{codeToText(code)}</StyledInquiryCodesTag>
  ));

  return (
    <StyledTimelineItem
      color={
        workOrderMilestoneToColorMap[
          name as keyof typeof workOrderMilestoneToColorMap
        ] || inactive
      }
      // This was done to have better styling for the Timeline.Item. In legacy, we were passing an onClick to Timeline.Item but TS throws an error here since onClick is not a prop on Timeline.Item from Antd
      // eslint-disable-next-line
      // @ts-ignore
      onClick={handleClick}
      $isOpen={isOpen}
    >
      <StyledMilestoneParagraph
        $bold
        data-cy={createDataCyValue(DataCyPrefix.MILESTONE_NAME, name)}
      >
        {formattedMilestoneName}
      </StyledMilestoneParagraph>
      {occurenceTime && (
        <StyledMilestoneParagraph $fontSize="12px">
          <span>
            <i>{getTimestamp(occurenceTime)}</i>
          </span>
        </StyledMilestoneParagraph>
      )}
      <StyledMilestoneParagraph>{sourceName}</StyledMilestoneParagraph>
      {isOpen && <StyledButterContent whiteSpinner={false} code={butterKey} />}
      {reasonCodeTags.length > 0 && (
        <StyledMilestoneParagraph $fontSize="12px">
          {reasonCodeTags}
        </StyledMilestoneParagraph>
      )}
      {customContent && (
        <StyledMilestoneParagraph $fontSize="12px">
          {customContent}
        </StyledMilestoneParagraph>
      )}
    </StyledTimelineItem>
  );
};

export default Milestone;
