import { productFragments } from '@xbcb/compliance-queries';
import {
  invoiceFragments,
  reportReconciliationRequestFragments,
} from '@xbcb/finance-queries';
import { RecordType } from '@xbcb/shared-types';
import { gql } from '@apollo/client';
import { transformProduct } from 'libs/formTransforms';
import {
  getInvoiceTitleField,
  getReportReconciliationRequestTitleField,
} from 'libs/getRecordTitleField';
import { validateProductUpdate } from 'libs/validateProduct';
import {
  Invoice,
  Product,
  ReportReconciliationRequest,
  DocumentTemplate,
  ProcessingError,
} from '../loadableComponents';

import { AppRecordRoute } from '../types';
import appRecordPartyRoutes from './partyRoutes';
import appRecordUserRoutes from './userRoutes';
import appRecordTeamRoutes from './teamRoutes';
import appRecordWorkOrderRoutes from './workOrderRoutes';
import appRecordShipmentRoutes from './shipmentRoutes';
import { executeMutation } from 'libs/executeMutation';
import { InvoiceStatus } from '@xbcb/finance-types';
import {
  AGL_FORWARDER_NAME,
  supportsEquityInfusionModel,
} from '@xbcb/finance-utils';
import { updateOneMutation } from '@xbcb/shared-queries';
import {
  UpdateInvoiceInput,
  TagInput,
  Invoice as InvoiceType,
} from '@xbcb/api-gateway-client';
import { documentTemplateFragments } from '@xbcb/document-queries';
import { checkAccess, UserPermissions } from '@xbcb/client-utils';
import { setModal } from 'actions';
import { ModalKey } from 'types';
import isArInvoice from 'libs/arInvoice';

import bulkUploadRequestRoutes from './utils/bulkUploadRequestRoutes';
import escalationMessageRoutes from './utils/escalationMessageRoutes';

const queryProcessingError = gql`
  query ProcessingErrorDetail($id: ID!) {
    processingError(id: $id) {
      id
      name
      source
      message
      status
      created {
        time
      }
      updated {
        time
      }
    }
  }
`;

const updateProcessingError = gql`
  mutation UpdateProcessingError(
    $id: ID!
    $version: Int!
    $input: UpdateProcessingErrorInput!
  ) {
    updateProcessingError(input: $input, version: $version, id: $id) {
      record {
        id
      }
    }
  }
`;

const invoiceFields = `
  ...invoiceFields
  creditNotes {
    id
    version
    creditTime
    status
    lines {
      id
      invoiceLineId
      type
      amount {
          currency
          value
      }
    }
    documents {
      id
      fileName
      extension
      content {
        downloadLink
      }
      tags {
        key
        value
      }
    }
  }
  payments {
    totalAmount {
      currency
      value
    }
  }  
  lines {
    workOrder {
      id
      ...on UsIorContinuousBondRequest {
        id
        bondNumber
        usIor {
          id
          name
          activationTime
          activations {
            status
            activated {
              time
            }
          }
        }
      }
    }
  }
  workOrderGroup {
    tags {
      key
      value
    }
    shipment {
      clientReferences {
        bookingId
      } 
      tags {
        key
        value
      }
    }
  }
`;

// Note: Move a AppRecordRoute to a separate file if it has >3 kebab menu items + buttons
export const appRecordRoutes: AppRecordRoute[] = [
  {
    Page: Product,
    recordType: RecordType.PRODUCT,
    validateUpdate: validateProductUpdate,
    transformUpdateRecordInput: transformProduct.toUpdateSchema,
    transformRecordToInput: transformProduct.toForm,
    getTitleField: () => 'clientIdentifier',
    fields: `...productFragment`,
    fragments: productFragments,
    kebabMenuItems: [
      {
        key: 'cloneProduct',
        text: 'Clone Product',
        action: async ({ dispatch, record }) => {
          dispatch(
            setModal({
              key: ModalKey.CLONE_PRODUCT,
              props: {
                visible: true,
                redirect: true,
                record,
              },
            }),
          );
        },
        show: ({ currentUser }) => {
          return checkAccess(
            currentUser,
            RecordType.PRODUCT,
            UserPermissions.CREATE,
          );
        },
      },
    ],
  },
  {
    Page: Invoice,
    recordType: RecordType.INVOICE,
    fields: invoiceFields,
    fragments: invoiceFragments,
    getTitleField: getInvoiceTitleField,
    defaultEditMode: false,
    kebabMenuItems: [
      {
        key: 'voidInvoice',
        text: 'Void Invoice',
        action: async ({ record }) => {
          const cantVoidStatus = getCantVoidStatus(record);
          if (cantVoidStatus.includes(record.status)) {
            return;
          }

          // Removing these tags because FW won’t process the "InvoiceListener" invoice to prevent the circular updates.
          const tags = record.tags.filter((tag: TagInput) => {
            return (
              tag.key !== 'ActionResource' && tag.value !== 'InvoiceListener'
            );
          });

          const mutation = updateOneMutation({
            recordName: RecordType.INVOICE,
          });

          // If invoice status is PROCESSING, then we can directly void the invoice since it hasn't been sent to downstream services.
          const invoiceStatus =
            record.status === InvoiceStatus.PROCESSING
              ? InvoiceStatus.VOID
              : InvoiceStatus.PENDING_VOID;

          await executeMutation({
            mutation,
            variables: {
              version: record.version,
              id: record.id,
              input: {
                status: invoiceStatus,
                tags,
              } as UpdateInvoiceInput,
            },
          });
        },
        show: ({ existingRecord }) => {
          const cantVoidStatus = getCantVoidStatus(existingRecord);
          return (
            isArInvoice(existingRecord) &&
            !cantVoidStatus.includes(existingRecord.status)
          );
        },
        confirmAction: true,
      },
    ],
  },
  {
    Page: ReportReconciliationRequest,
    recordType: RecordType.REPORT_RECONCILIATION_REQUEST,
    fields: `...reportReconciliationRequestFields`,
    fragments: reportReconciliationRequestFragments,
    getTitleField: getReportReconciliationRequestTitleField,
    defaultEditMode: false,
  },
  {
    Page: ProcessingError,
    recordType: RecordType.PROCESSING_ERROR,
    queries: { get: queryProcessingError, update: updateProcessingError },
    getTitleField: () => 'name',
    defaultEditMode: false,
  },
  {
    Page: DocumentTemplate,
    recordType: RecordType.DOCUMENT_TEMPLATE,
    fields: `...documentTemplateFields`,
    fragments: documentTemplateFragments,
    defaultEditMode: false,
  },
  ...appRecordPartyRoutes,
  ...appRecordUserRoutes,
  ...appRecordWorkOrderRoutes,
  ...appRecordShipmentRoutes,
  ...appRecordTeamRoutes,
  ...bulkUploadRequestRoutes,
  ...escalationMessageRoutes,
];

const getCantVoidStatus = (record: InvoiceType) => {
  const cantVoidStatus = [];
  // if the invoice is not for AGL, supportsEquityInfusionModel Invoices and already PAID, then take no action
  if (
    record.billToParty.name !== AGL_FORWARDER_NAME &&
    !supportsEquityInfusionModel(record.billToParty.name)
  ) {
    cantVoidStatus.push(InvoiceStatus.PAID);
  }
  // If the invoice is already paid or void, then take no action
  cantVoidStatus.push(InvoiceStatus.PENDING_VOID, InvoiceStatus.VOID);
  return cantVoidStatus;
};
