import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useCurrentUser } from 'libs/hooks';
import { useQuery } from '@apollo/client';
import DelayedTooltip from 'components/DelayedTooltip';
import { DataCyPrefix } from '@xbcb/ui-types';
import { StyledSelect } from './style';
import { clearRecentlyViewed, setActiveBroker } from 'actions';
import { SearchCustomsBrokersQuery } from 'libs/sharedQueries';
import { client } from '@xbcb/apollo-client';
import { RootState } from 'reducers';
import { inltBrokerIdByStageMap } from 'libs/inltBrokerIdByStageMap';
import { getEnv } from '@xbcb/ui-utils';

const { stage } = getEnv();

const refetchAllQueries = () => {
  const activeQueries = client.getObservableQueries();

  // Refetch all active queries
  client.refetchQueries({
    include: Array.from(activeQueries.keys()),
  });
};

const ActiveBrokerSelect: React.FC = () => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const history = useHistory();
  const currentActiveBroker = useSelector(
    (state: RootState) => state.activeBroker.currentActiveBroker,
  );
  const getTeamIds = (teams?: { nodes?: { id: string }[] }) =>
    teams?.nodes?.map((node) => node.id) || [];

  const currentUserTeams: string[] = [
    ...getTeamIds(currentUser.assignmentTeams),
    ...getTeamIds(currentUser.businessSupportTeams),
  ];
  const { data, loading } = useQuery(SearchCustomsBrokersQuery, {
    variables: {
      input: {
        searchCriteria: {
          userTeams: {
            values: currentUserTeams,
            operator: 'ONE_OF',
          },
        },
      },
    },
    skip: !currentUser.id,
  });
  const brokerOptions: [{ value: string; label: JSX.Element }] = useMemo(() => {
    if (!data || !data.searchCustomsBrokers || loading) return [];

    return data.searchCustomsBrokers.results.map(
      (broker: { id: string; name: string }) => ({
        value: broker.id || '',
        label: (
          <DelayedTooltip title={`${broker.name}`} placement="bottomRight">
            <span>{broker.name}</span>
          </DelayedTooltip>
        ),
      }),
    );
  }, [data, loading]);

  const handleBrokerSelect = useCallback(
    (brokerid?: string, resetStore = true) => {
      if (!brokerid) return;
      localStorage.setItem('ACTIVE_BROKER_ID', brokerid);
      dispatch(setActiveBroker(brokerid));
      history.push('/');
      if (resetStore) {
        refetchAllQueries();
        dispatch(clearRecentlyViewed());
      }
    },
    [dispatch, history],
  );

  useEffect(() => {
    if (!currentActiveBroker) {
      const inltBroker = inltBrokerIdByStageMap[stage];
      const defaultValue = brokerOptions.find(
        (broker) => broker.value === inltBroker,
      )
        ? inltBroker
        : brokerOptions[0]?.value;
      handleBrokerSelect(defaultValue, false);
    }
  }, [brokerOptions, currentActiveBroker, handleBrokerSelect]);
  const disabledCondition = brokerOptions.length < 2;

  return (
    <StyledSelect
      value={currentActiveBroker || currentUser.customsBroker?.name}
      options={brokerOptions}
      onSelect={(value) => handleBrokerSelect(value)}
      size="small"
      disabled={disabledCondition}
      data-cy={DataCyPrefix.MULTI_BROKER_DROPDOWN}
    />
  );
};

export default ActiveBrokerSelect;
