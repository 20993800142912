import React, { memo } from 'react';
import { UserStatus } from '@xbcb/client-types';
import { StyledStatusDisplay, StyledStatusIcon } from './styles';

export interface StatusDisplayProps {
  currentStatus: UserStatus;
}

const statusDisplayMap: Record<UserStatus, string> = {
  [UserStatus.ACTIVE]: 'Active',
  [UserStatus.IDLE]: 'Idle',
  [UserStatus.ACTIVE_TASK]: 'Active',
  [UserStatus.OFFLINE]: 'Offline',
  [UserStatus.OUT_OF_OFFICE]: 'Out of Office',
  [UserStatus.AWAY]: 'Away',
};

const StatusDisplay: React.FC<StatusDisplayProps> = ({ currentStatus }) => {
  return (
    <StyledStatusDisplay>
      <StyledStatusIcon currentStatus={currentStatus} />
      {statusDisplayMap[currentStatus]}
    </StyledStatusDisplay>
  );
};

export default memo(StatusDisplay);
