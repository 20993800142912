import moment from 'moment';
import { AnyObject } from '@xbcb/shared-types';
import { SingleFormCard, Datum } from '@xbcb/display-components';
import { capitalCase } from 'change-case';
import { UsIorPaymentTerms } from '@xbcb/party-types';

type PaymentTermsCardProps = {
  record: AnyObject;
};

const PaymentTermsCard: React.FC<PaymentTermsCardProps> = ({ record }) => {
  const { paymentTerms, paymentTermsUpdated } = record;
  const formattedPaymentTerms = paymentTerms
    ? paymentTerms === UsIorPaymentTerms.PMS
      ? 'Periodic Monthly Statement'
      : capitalCase(paymentTerms)
    : 'Not Defined';
  const paymentTermsUpdatedTime = paymentTermsUpdated?.time;
  const now = moment();
  const diff = paymentTermsUpdatedTime
    ? `${now
        .diff(moment(paymentTermsUpdatedTime), 'days')
        .toLocaleString()} days ago`
    : 'N/A';
  return (
    <SingleFormCard title="Payment Terms" titlePosition="top">
      <tbody>
        <Datum label="Terms" value={formattedPaymentTerms} />
        <Datum label="Updated" value={diff} />
      </tbody>
    </SingleFormCard>
  );
};

export default PaymentTermsCard;
