import React from 'react';
import { message } from 'antd';
import { executeMutation } from 'libs/executeMutation';
import { UserSwitchOutlined } from '@ant-design/icons';
import { StyledButton } from './styles';
import { gql } from '@apollo/client';
import { useCurrentUser } from 'libs/hooks';
interface SwitchUserButtonProp {
  assumedUserId?: string; // If set, switch to assumedUser. If not, going back to operator user.
}
const SwitchUserButton: React.FC<SwitchUserButtonProp> = ({
  assumedUserId,
}) => {
  const toggleAssumedUser = gql`
    mutation toggleAssumedUser($id: ID!, $assumedUser: IdInput) {
      toggleAssumedUser(id: $id, assumedUser: $assumedUser) {
        operatorUser {
          id
          assumedUser {
            id
            name
            root
            email
            __typename
          }
        }
      }
    }
  `;

  const buttonClassName = assumedUserId
    ? 'switch-user-button'
    : 'switch-user-icon';
  const currentUser = useCurrentUser();
  const { originalUser } = currentUser;
  return (
    <StyledButton
      className={buttonClassName}
      onClick={async (event: any) => {
        try {
          await executeMutation({
            mutation: toggleAssumedUser,
            variables: {
              id: originalUser ? originalUser.id : currentUser.id,
              assumedUser: assumedUserId &&
                !originalUser && {
                  id: assumedUserId,
                },
            },
          });
          window.location.reload();
        } catch (e) {
          message.error(`Error occurred while switching user.`);
        }
      }}
      shape={assumedUserId ? 'round' : undefined}
      type={assumedUserId ? 'primary' : undefined}
      icon={
        assumedUserId ? null : (
          <UserSwitchOutlined style={{ fontSize: '28px', color: 'orange' }} />
        )
      }
    >
      {assumedUserId ? 'Switch User' : ''}
    </StyledButton>
  );
};

export default SwitchUserButton;
