import { useMutation, gql } from '@apollo/client';
import { Form, message, Modal } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import type {
  UsIor,
  TemplateDocumentSignRequest,
} from '@xbcb/api-gateway-client';
import { getRecordIdTag } from '@xbcb/document-components';
import { DocumentTag } from '@xbcb/document-types';
import { POAMethod } from '@xbcb/party-types';
import { getOneQuery } from '@xbcb/shared-queries';
import { RecordType } from '@xbcb/shared-types';
import { CssSize } from '@xbcb/ui-types';
import { reportError, useModal, useSearchDocuments } from '@xbcb/ui-utils';
import RecordSelect from 'components/RecordSelect';
import controlClick from 'libs/controlClick';
import { getRecordPath } from 'libs/getRecordPath';
import { linkModalContent, openLinkModal } from 'libs/linkModal';
import { documentTypeToSignLinkTypeMap } from 'libs/documentTypeMap';
import { usIorFields, usIorFragments } from 'libs/sharedQueries';
import { ModalKey } from 'types';
import { StyledH4, StyledH3, StyledButton } from './styles';
import { useCurrentUser, useCustomsBrokerId } from 'libs/hooks';

const getUsIorQuery = getOneQuery({
  recordName: RecordType.US_IOR,
  fields: usIorFields,
  fragments: usIorFragments,
});

const LINK_FORWARDER = gql`
  mutation linkForwarderToUsIor(
    $id: ID!
    $version: Int!
    $forwarderId: ID!
    $customsBrokerId: ID!
    $input: LinkForwarderToIorInput!
  ) {
    linkForwarderToUsIor(
      id: $id
      version: $version
      forwarderId: $forwarderId
      customsBrokerId: $customsBrokerId
      input: $input
    ) {
      templateDocumentSignRequest {
        url
        documentTags
      }
      linked
    }
  }
`;

type LinkForwarderToUsIorModalProps = { record: UsIor };

const LinkForwarderToUsIorModal: React.FC<LinkForwarderToUsIorModalProps> = ({
  record,
}) => {
  const {
    iorNumber,
    name: importerName,
    id: importerId,
    version: importerVersion,
    shippers,
  } = record || {};
  const [form] = Form.useForm();
  const {
    closeModal,
    visible,
    modalProps,
  }: Omit<ReturnType<typeof useModal>, 'modalProps'> & {
    modalProps: {
      existingImporterId?: string;
      existingImporterName?: string;
      // if this is coming in as a prop, we're trying to activate a new importer
      // that is a duplicate. So we know which forwarder we want to link to.
      // TODO can we remove this?
      linkForwarderId?: string;
    };
  } = useModal(ModalKey.LINK_FORWARDER);
  const { existingImporterId, existingImporterName, linkForwarderId } =
    modalProps;
  const history = useHistory();
  const { pathname } = useLocation();
  const currentUser = useCurrentUser();
  const customsBrokerId = useCustomsBrokerId();

  const forwarderBaseNamePath = ['forwarder'];

  const operatorId = currentUser?.operator?.id;
  const recordIdTag = getRecordIdTag({
    pathname,
    recordId: importerId,
    operatorId,
  });

  const { documents } = useSearchDocuments({
    operatorId,
    tags: [recordIdTag],
  });

  const hasDirectPoa = documents.some(({ documentTags }) =>
    documentTags?.includes(DocumentTag.DIRECT_POWER_OF_ATTORNEY),
  );

  const handleClose = () => {
    closeModal();
    form.resetFields();
  };

  const [linkForwarder, { loading }] = useMutation(LINK_FORWARDER, {
    refetchQueries: () => [
      { query: getUsIorQuery, variables: { id: importerId } },
    ],
    onCompleted: async (data) => {
      const { templateDocumentSignRequest, linked } = data.linkForwarderToUsIor;
      const { url, documentTags } = (templateDocumentSignRequest ||
        {}) as TemplateDocumentSignRequest;

      if (linked) message.success('All set! Forwarder linked.');
      handleClose();

      if (url) {
        const modalContent = linkModalContent({
          link: url,
          type: documentTypeToSignLinkTypeMap.get(documentTags[0]),
          heading: 'Sign Link Created',
        });
        openLinkModal(modalContent);
      } else if (existingImporterId) {
        history.push(
          getRecordPath({
            recordType: RecordType.US_IOR,
            id: existingImporterId,
          }),
        );
      }
    },
  });
  const handleSubmit = async (evt: any) => {
    evt.preventDefault();

    const { forwarder } = form.getFieldsValue();

    try {
      await form.validateFields();
    } catch (e) {
      message.error(`Please fill out all of the fields`);
      return;
    }

    try {
      await linkForwarder({
        variables: {
          id: existingImporterId || importerId,
          version: importerVersion,
          forwarderId: forwarder?.id,
          customsBrokerId,
          input: {
            poaMethod: POAMethod.DIRECT,
            generateMasterPoa: false,
            // This occurs after trying to activate an importer that is a
            // duplicate of an already duplicated importer.
            terminateImporterId: existingImporterId ? importerId : undefined,
          },
        },
      });
    } catch (e) {
      reportError(e);
    }
  };
  return (
    <Modal
      maskClosable={!loading}
      open={visible}
      width={400}
      closable={true}
      footer={null}
      onCancel={handleClose}
      destroyOnClose
    >
      <Form form={form} initialValues={{ forwarder: { id: linkForwarderId } }}>
        <StyledH3>
          {existingImporterId ? (
            <b>Cannot activate {importerName}</b>
          ) : (
            `Link a forwarder to ${importerName}`
          )}
        </StyledH3>
        <RecordSelect
          $itemSize={CssSize.SHORT_MEDIUM}
          recordType={RecordType.FORWARDER}
          form={form}
          localNamePath={forwarderBaseNamePath}
          fullNamePath={forwarderBaseNamePath}
          horizontal
          required
          isLoading={loading}
          getExcludedOptions={() => {
            const existingRelatedForwarderIds: string[] = [];
            shippers.nodes.forEach(({ forwarders }) =>
              forwarders.nodes.forEach(({ id }) =>
                existingRelatedForwarderIds.push(id),
              ),
            );
            return existingRelatedForwarderIds;
          }}
          readOnly={Boolean(linkForwarderId)}
          disabled={Boolean(linkForwarderId)}
          targetBlank
        />
        {existingImporterId ? (
          <>
            <StyledH4>
              The IOR Number {iorNumber} is already in use by{' '}
              <a
                target="blank"
                onClick={(event) =>
                  controlClick({
                    history,
                    event,
                    path: getRecordPath({
                      recordType: RecordType.US_IOR,
                      id: existingImporterId,
                    }),
                    targetBlank: true,
                  })
                }
              >
                {existingImporterName}
              </a>
              .
            </StyledH4>
            <StyledH4>
              If you click proceed below, we will link the above forwarder to{' '}
              {existingImporterName}, copy over all documents to the existing
              importer, and terminate this duplicate importer.
            </StyledH4>
          </>
        ) : hasDirectPoa ? (
          <>
            <StyledH4>
              {importerName} already has a direct POA on file; no additional
              POAs are required by INLT.
            </StyledH4>
          </>
        ) : (
          <StyledH4>
            {importerName} does not have a POA on file with this forwarder. Once
            a POA is signed, please link this forwarder again for the link to be
            complete.
          </StyledH4>
        )}
        <StyledButton
          onClick={handleSubmit}
          loading={loading}
          danger={existingImporterId ? true : false}
          htmlType="submit"
          size="large"
          type="primary"
        >
          {existingImporterId ? 'Proceed' : 'Submit'}
        </StyledButton>
      </Form>
    </Modal>
  );
};

export default LinkForwarderToUsIorModal;
