import React from 'react';
import { message } from 'antd';
import { RecordType } from '@xbcb/shared-types';
import { gql, useMutation } from '@apollo/client';
import { StyledButton } from './styles';
import { camelCase } from 'lodash';
import { titleCase } from '../../libs/format';

export const passwordResettableUserTypes = [
  RecordType.FORWARDER_USER,
  RecordType.SHIPPER_USER,
];

export type PasswordResettableUserType =
  (typeof passwordResettableUserTypes)[number];

interface ResetPasswordButtonProp {
  userId: string;
  userType: PasswordResettableUserType;
}

const ResetPasswordButton: React.FC<ResetPasswordButtonProp> = ({
  userId,
  userType,
}) => {
  const mutationName = `reset${titleCase(camelCase(userType))}Password`;
  const resetPasswordMutation = gql`
    mutation resetUserPassword($id: ID!) {
        ${mutationName} (id: $id) {
        email
    }
    }
  `;

  const [resetPassword, { loading: isPasswordBeingReset }] = useMutation(
    resetPasswordMutation,
    {
      onCompleted: (record) => {
        message.success(
          `An email has been sent to ${record[mutationName]?.email} with a temporary password`,
        );
      },
      onError: async (error) => {
        message.error(
          `Error occurred while resetting password: ${error.message}`,
        );
      },
    },
  );

  const onClick = async () => {
    await resetPassword({
      variables: { id: userId },
    });
  };

  return (
    <StyledButton
      disabled={isPasswordBeingReset}
      onClick={onClick}
      shape="round"
      type="primary"
    >
      Reset Password
    </StyledButton>
  );
};

export default ResetPasswordButton;
