import React from 'react';
import { dataMap } from 'libs/display';
import { useCurrentUser } from 'libs/hooks';
import ArrayTooltip from 'components/ArrayTooltip';
import { Datum } from '@xbcb/display-components';
import { get } from 'lodash';
import { AccountType } from '@xbcb/shared-types';
import { getCountryCodes } from '@xbcb/ui-utils';
import { StyledTable, StyledSpan } from './styles';

type ReadOnlyDataProps = {
  displayMapKey: string;
  data?: any;
  keys?: string[];
  accountType?: AccountType;
  boldLast?: boolean;
  extraRows?: React.ReactNode;
  helper?: any;
  tooltips?: React.ReactNode[];
  append?: any;
};

// could use a refactor
const ReadOnlyData = ({
  displayMapKey,
  data,
  keys = [],
  boldLast,
  extraRows,
  helper = {},
  tooltips,
  append,
}: ReadOnlyDataProps) => {
  const currentUser = useCurrentUser();
  if (!data) return null;
  const dataObj = { [displayMapKey]: data };
  const countryCodes = getCountryCodes();
  const displayMap = dataMap(dataObj, {
    countryCodes,
    accountType: currentUser?.accountType,
    ...helper,
  });
  return (
    <StyledTable>
      <tbody>
        {keys.map((key, index) => {
          const mappedObject = get(displayMap, [displayMapKey, key]);
          const name = get(mappedObject, 'name');
          const rawValue = get(mappedObject, 'data');
          if (!rawValue && rawValue !== 0 && rawValue !== false) return null;
          let value = (
            <div>
              <span>{rawValue}</span>
              {append && append[name] ? append[name] : null}
            </div>
          );
          if (Array.isArray(rawValue)) {
            if (rawValue.length === 0) return null;
            if (rawValue.length > 1) {
              value = (
                <ArrayTooltip array={rawValue}>
                  <StyledSpan>{rawValue[0]}</StyledSpan>
                  {append && append[name] ? append[name] : null}
                </ArrayTooltip>
              );
            }
          }
          const bold = boldLast && index === keys.length - 1;
          const datum = (
            <Datum
              key={`${key}-${index}`}
              $bold={bold}
              value={value}
              label={get(mappedObject, 'name')}
              tooltip={get(tooltips, [key])}
            />
          );
          return datum;
        })}
        {extraRows}
      </tbody>
    </StyledTable>
  );
};

export default ReadOnlyData;
