import React, { useState } from 'react';
import { Modal, Form } from 'antd';
import { Email } from '@xbcb/form-item-components';
import { useMutation } from '@apollo/client';
import { createOneMutation } from '@xbcb/shared-queries';
import { RecordType } from '@xbcb/shared-types';
import { useCurrentUser } from 'libs/hooks';
import handleRecordCreate from 'libs/handleRecordCreate';
import {
  StyledAppRadioGroup,
  StyledButton,
  StyledDiv,
  StyledParagraph,
} from './style';

type StartOnboardingModalProps = {
  closeModal: any;
  visible: boolean;
};

const StartOnboardingModal = ({
  closeModal,
  visible,
}: StartOnboardingModalProps) => {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const createMutation = createOneMutation({
    recordName: RecordType.ONBOARDING_REQUEST,
    fields: `
    id
    version
    loginLink
    email
    `,
  });

  const [createRecord, { loading, error: createError }] = useMutation(
    createMutation,
    {
      onCompleted: (data: any) => {
        Modal.success({
          title: 'Success! Here is the link:',
          content: data?.createOnboardingRequest?.record?.loginLink,
        });
        setLoading(false);
      },
    },
  );

  const user = useCurrentUser();
  const handleSubmit = async () => {
    await handleRecordCreate({
      form,
      recordType: RecordType.ONBOARDING_REQUEST,
      createRecord,
      clean: false,
      closeModal,
      setLoading,
      user,
    });
  };

  return (
    <Modal
      open={visible}
      closable={false}
      width={400}
      footer={null}
      onCancel={closeModal}
      className="start-onboarding-modal"
    >
      <Form form={form}>
        <StyledParagraph>
          The customer will receive an email to begin onboarding.
        </StyledParagraph>
        <Email
          localNamePath={['email']}
          readOnly={false}
          disabled={false}
          required={true}
        />
        <StyledAppRadioGroup
          options={[
            {
              value: 'SHIPPER',
              label: 'Shipper',
            },
            {
              value: 'FORWARDER',
              label: 'Forwarder',
            },
          ]}
          localNamePath={['accountType']}
          rules={[{ required: true, message: ' ' }]}
          required={true}
        />
        {!isLoading && (
          <StyledDiv>
            <StyledButton
              type="primary"
              shape="round"
              size="large"
              onClick={handleSubmit}
            >
              Start Onboarding
            </StyledButton>
          </StyledDiv>
        )}
      </Form>
    </Modal>
  );
};

export default StartOnboardingModal;
