import React from 'react';
import { Skeleton, Tooltip } from 'antd';
import { capitalCase } from 'change-case';
import type {
  WorkOrderTask,
  WorkOrderTaskDefinition,
} from '@xbcb/api-gateway-client';
import { safeGet } from '@xbcb/js-utils';
import { StyledDiv, StyledParagraph } from './styles';
interface WorkOrderTaskInfoCardProps {
  loading?: boolean;
  workOrderTask: WorkOrderTask;
}

const WorkOrderTaskInfoCard: React.FC<WorkOrderTaskInfoCardProps> = ({
  loading,
  workOrderTask,
}) => {
  const {
    assignee = {},
    definition = {} as WorkOrderTaskDefinition,
    name,
    status,
  } = workOrderTask;
  const { estimatedDuration = 15 } = definition;
  const currentAssignee = safeGet(assignee, 'name', 'Nobody');

  return loading ? (
    <Skeleton active />
  ) : (
    <StyledDiv>
      <h3>{capitalCase(name)}</h3>
      <hr></hr>
      <Tooltip title={currentAssignee}>
        <StyledParagraph
          $overflow="hidden"
          $textOverflow="ellipsis"
          $whitespace="nowrap"
        >
          Assigned to: {currentAssignee}
        </StyledParagraph>
      </Tooltip>
      <StyledParagraph>Status: {capitalCase(status)}</StyledParagraph>
      <StyledParagraph>
        Estimated duration: {estimatedDuration} minutes
      </StyledParagraph>
    </StyledDiv>
  );
};

export default WorkOrderTaskInfoCard;
