import React from 'react';
import { Skeleton } from 'antd';
import { SingleFormCard } from '@xbcb/display-components';
import { InfoTooltip } from '@xbcb/form-item-components';
import tzString from 'libs/tzString';
import { StyledDiv, StyledFooter, StyledParagraph } from './styles';

export interface WorkOrderInfo {
  label: string;
  value?: string;
}

interface WorkOrderInfoCardProps {
  loading?: boolean;
  workOrderInfo: WorkOrderInfo[];
}

const WorkOrderInfoCard = ({
  loading,
  workOrderInfo,
}: WorkOrderInfoCardProps) => {
  const timezone = tzString();
  return (
    <SingleFormCard title="Work Order Info" titlePosition="top" $noMarginBottom>
      {loading ? (
        <Skeleton active />
      ) : (
        <StyledDiv>
          {workOrderInfo.map(({ label, value }, idx) => {
            if (!value) return null;
            return (
              <StyledParagraph key={idx}>
                {label}: {value}
              </StyledParagraph>
            );
          })}
          <StyledFooter>
            <InfoTooltip title={`All times in ${timezone}`} placement="left" />
          </StyledFooter>
        </StyledDiv>
      )}
    </SingleFormCard>
  );
};

export default WorkOrderInfoCard;
