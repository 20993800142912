import React from 'react';
import { message } from 'antd';
import { Select, FormItem } from '@xbcb/form-item-components';
import { email as emailRegex } from '@xbcb/regex';
import { CssSize } from '@xbcb/ui-types';
import { FormInstance } from 'antd/lib/form';

const getValueFromEvent = (val: any) => {
  if (Array.isArray(val)) {
    return val.reduce((acc, v) => {
      const lowerCased = v.toLowerCase();
      if (emailRegex.test(lowerCased)) {
        acc.push(lowerCased);
      } else {
        message.error(`${v} is not a valid email`);
      }
      return acc;
    }, []);
  } else {
    return val;
  }
};

export type EmailsProps = {
  form: FormInstance;
  label: string;
  disabled?: boolean;
  readOnly?: boolean;
  name: string;
  maxTagCount?: number;
  $itemSize?: CssSize;
};

const Emails = ({
  form,
  label,
  disabled,
  readOnly,
  name,
  $itemSize,
  maxTagCount = 10,
}: EmailsProps) => (
  <FormItem
    $itemSize={$itemSize}
    label={label}
    name={name}
    rules={[{ required: false, message: ' ' }]}
    $readOnly={readOnly}
  >
    <Select
      mode="tags"
      maxTagCount={maxTagCount}
      disabled={disabled}
      notFoundContent="Type then press enter to add"
      tokenSeparators={[',', ' ', '/']}
    />
  </FormItem>
);

export default Emails;
