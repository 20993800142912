import React from 'react';
import { Modal } from 'antd';
import { useModal } from '@xbcb/ui-utils';
import { ModalKey } from '@xbcb/ui-types';

// General confirmation modal pop-up creates confirmation page upon invocation.

// This component is rendered by AppHeader so that we have one generic
// confirmation modal per page; it can be opened/used anywhere except
// for the login landing page. To use this modal simply dispatch the
// `setModal` action. An example can be shown here:
// ------------------ example ------------------
// dispatch(
//   setModal({
//     key: 'ModalKey.GENERAL_CONFIRMATION',
//     props: {
//       title: 'Are you sure?',
//       visible: true,
//       onOk: () => resolve({ canceled: false }),
//       onCancel: () => resolve({ canceled: true }),
//     },
//   }),
// );

const GeneralConfirmationModal = () => {
  const { closeModal, visible, modalProps } = useModal(
    ModalKey.GENERAL_CONFIRMATION,
  );

  const { title, onOk, onCancel, okText, cancelText } = modalProps;

  return (
    <Modal
      title={title ? title : 'Are you sure?'}
      okText={okText ? okText : 'Ok'}
      onOk={() => {
        closeModal();
        return onOk();
      }}
      cancelText={cancelText ? cancelText : 'Cancel'}
      onCancel={() => {
        closeModal();
        if (typeof onCancel === 'function') return onCancel();
      }}
      open={visible}
    />
  );
};

export default GeneralConfirmationModal;
