import React from 'react';
import { ToolOutlined } from '@ant-design/icons';
import { Dropdown, MenuItemProps } from 'antd';
import DelayedTooltip from 'components/DelayedTooltip';
import { StyledButton } from './style';
import { useModal } from '@xbcb/ui-utils';
import { ModalKey } from 'types';
import StartOnboardingModal from 'components/StartOnboardingModal';

const TeamActionsButton: React.FC = () => {
  const { openModal, visible, closeModal } = useModal(
    ModalKey.ONBOARDING_MODAL,
  );
  const handleOptionClick: MenuItemProps['onClick'] = ({ key }) => {
    if (key === 'startOnboarding') {
      openModal();
    }
  };
  const dropdownItems = [{ key: 'startOnboarding', label: 'Start Onboarding' }];

  return (
    <div className="team-actions button-wrapper">
      <StartOnboardingModal visible={visible} closeModal={closeModal} />
      <DelayedTooltip title="Team Actions">
        <Dropdown
          placement="bottomRight"
          menu={{ items: dropdownItems, onClick: handleOptionClick }}
        >
          <StyledButton disabled={false} shape="circle">
            <ToolOutlined />
          </StyledButton>
        </Dropdown>
      </DelayedTooltip>
    </div>
  );
};

export default TeamActionsButton;
