import React, { useState } from 'react';
import { Form, Button, message } from 'antd';
import {
  SimpleSelect,
  FormItem,
  HiddenField,
} from '@xbcb/form-item-components';
import { useModal } from '@xbcb/ui-utils';
import { CssSize } from '@xbcb/ui-types';
import cbpDocumentCodes from 'libs/cbpDocumentCodes';
import { StyledModal, StyledTable } from './styles';
import { gql, useMutation } from '@apollo/client';
import type {
  SubmitUsConsumptionEntryDisInput,
  Document,
} from '@xbcb/api-gateway-client';
import { v4 as uuidV4 } from 'uuid';
import { ModalKey } from 'types';
import {
  DocumentTag,
  DocumentStatus,
  DisDocuments,
} from '@xbcb/document-types';
import { createWorkOrderMilestoneMutation } from 'libs/sharedQueries';
import { WorkOrderMilestoneName } from '@xbcb/work-order-types';
import { SubmitUsType86EntryDisInput } from '__generated__/graphql';

interface SendDocumentModalProps {
  recordId?: string;
  documents?: Document[];
  operatorId?: number;
  workOrderVersion?: number;
}

const getDefaultDisCode = (documentTags?: DocumentTag[]) => {
  if (documentTags?.length === 1) {
    const [firstDocumentTag] = documentTags;
    const disDocument =
      DisDocuments.getDisDocumentByDocumentTag(firstDocumentTag);
    return disDocument?.labelCode;
  }
  return undefined;
};

const SendDocumentModal = ({
  recordId,
  documents,
  operatorId,
  workOrderVersion,
}: SendDocumentModalProps) => {
  const [form] = Form.useForm();
  const initialSelected: any[] = [];
  const [selectedRowKeys, setSelectedRowKeys] = useState(initialSelected);
  const [isLoading, setIsLoading] = useState(false);
  const isUsConsumptionEntry = recordId?.startsWith('usConsumptionEntry');
  const isUsType86Entry = recordId?.startsWith('usType86Entry');
  const shouldSendToCbp = isUsConsumptionEntry || isUsType86Entry;

  const {
    visible: isModalVisible,
    modalProps,
    closeModal,
  } = useModal(ModalKey.SEND_DOCUMENT);
  // get document if a specific document is selected
  const document = modalProps?.document;

  const documentsToSend = document ? [document] : documents;

  const handleClose = () => {
    closeModal();
  };

  const buildColumns = () => {
    const columnsArray: [Object] = [
      {
        title: 'Filename',
        key: 'filename',
        dataIndex: 'filename',
        width: 'auto',
        render: (text: string, record: any) => {
          return (
            <div>
              {text}
              <HiddenField
                localNamePath={[record.index, 'docId']}
                form={form}
                value={record.docId}
              />
            </div>
          );
        },
      },
    ];

    const disCodeColumn = {
      title: 'DIS Code',
      key: 'disCode',
      dataIndex: 'disCode',
      render: (text: string, record: any) => {
        const defaultDisCode = getDefaultDisCode(record.documentTags);
        const isRequired = document
          ? true
          : selectedRowKeys.includes(record.docId)
          ? true
          : false;
        return (
          <SimpleSelect
            showSearch
            form={form}
            key={record.docId}
            $itemSize={CssSize.SHORT}
            required={isRequired}
            dropdownMatchSelectWidth={false}
            fullNamePath={[record.index, 'cbpLabel']}
            localNamePath={[record.index, 'cbpLabel']}
            map={cbpDocumentCodes}
            initialValue={defaultDisCode}
            allowClear
            aria-label={`cbpLabel${record.index}`}
          />
        );
      },
    };

    if (shouldSendToCbp) {
      columnsArray.push(disCodeColumn);
    }

    return columnsArray;
  };

  const buildData = () => {
    return documentsToSend?.map((doc, index) => {
      const { id, fileName, extension, documentTags } = doc;
      return {
        filename: fileName + '.' + extension,
        documentTags: documentTags,
        key: id,
        docId: id,
        index,
      };
    });
  };

  const submitDisabled = document ? false : selectedRowKeys.length < 1;

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (keys: any[], rows: any[]) => {
      setSelectedRowKeys(keys);
    },
  };

  const submitEntryDis = gql`
    mutation SubmitUsConsumptionEntryDis(
      $idempotencyKey: String!
      $input: SubmitUsConsumptionEntryDisInput!
      $recordId: ID!
    ) {
      submitUsConsumptionEntryDis(
        idempotencyKey: $idempotencyKey
        input: $input
        usConsumptionEntryId: $recordId
      ) {
        transaction {
          ... on SubmitUsConsumptionEntryDisTransaction {
            id
            version
          }
        }
      }
    }
  `;

  const submitType86EntryDis = gql`
    mutation SubmitUsType86EntryDis(
      $idempotencyKey: String!
      $input: SubmitUsType86EntryDisInput!
      $recordId: ID!
    ) {
      submitUsType86EntryDis(
        idempotencyKey: $idempotencyKey
        input: $input
        usType86EntryId: $recordId
      ) {
        transaction {
          ... on SubmitUsType86EntryDisTransaction {
            id
            version
          }
        }
      }
    }
  `;

  const sendDisDocs = async (
    input: SubmitUsConsumptionEntryDisInput | SubmitUsType86EntryDisInput,
  ) => {
    const { data, errors } = await sendDisDocument({
      variables: {
        idempotencyKey: uuidV4(),
        input,
        recordId,
      },
    });
    if (errors) {
      message.error(
        `Sorry, the submission failed with error ${errors}, please try again later.`,
      );
    } else {
      message.success('Submitted to CBP.');
    }
  };

  const handleSubmitToCBP = async (e: any) => {
    e.preventDefault();
    try {
      await form.validateFields();
    } catch (e) {
      message.error('Please ensure all selected docs have a CBP tag');
      return;
    }
    setIsLoading(true);
    const formObj = form.getFieldsValue();
    const input = {
      documents: Object.entries(formObj).reduce((arr: any, item: any) => {
        const { docId, cbpLabel } = item[1];
        if (document?.id || selectedRowKeys.includes(docId)) {
          arr.push({
            document: { id: docId },
            cbpLabel,
          });
        }
        return arr;
      }, []),
    };

    await sendDisDocs(input);
    setIsLoading(false);
    closeModal();
  };

  const [sendDisDocument] = useMutation(
    isUsType86Entry ? submitType86EntryDis : submitEntryDis,
  );

  const createWorkOrderConfirmation = gql`
    mutation CreateWorkOrderConfirmation(
      $idempotencyKey: String!
      $input: CreateWorkOrderConfirmationInput!
    ) {
      createWorkOrderConfirmation(
        idempotencyKey: $idempotencyKey
        input: $input
      ) {
        record {
          id
        }
      }
    }
  `;

  const [sendDocumentToSeller] = useMutation(createWorkOrderConfirmation);

  const sendDocsToSeller = async () => {
    const { data, errors } = await sendDocumentToSeller({
      variables: {
        idempotencyKey: uuidV4(),
        input: {
          workOrder: {
            id: recordId,
            version: workOrderVersion,
          },
          operator: {
            id: operatorId,
          },
        },
      },
    });

    if (errors) {
      message.error(
        `Sorry, the submission failed with error ${errors}, please try again later.`,
      );
    } else {
      message.success('Sent to Seller.');
    }
  };

  const getWorkOrderQuery = gql`
    query getWorkOrderQuery($id: ID!, $version: Int) {
      workOrder(id: $id, version: $version) {
        id
        version
        status
        milestones {
          id
          name
          version
          occurenceTime
        }
      }
    }
  `;

  const [createWorkOrderMilestone] = useMutation(
    createWorkOrderMilestoneMutation,
    {
      refetchQueries: () => [
        {
          query: getWorkOrderQuery,
          variables: { id: recordId, version: workOrderVersion },
        },
      ],
      awaitRefetchQueries: true,
    },
  );

  const handleSubmitToSeller = async (e: any) => {
    e.preventDefault();
    try {
      await form.validateFields();
    } catch (e) {
      message.error('Please ensure all selected docs have a tag');
      return;
    }
    if (document.status === DocumentStatus.PENDING_UPLOAD) {
      message.error(
        'Document uploaded with error. Please delete document and upload again.',
      );
      return;
    }
    setIsLoading(true);

    if (document?.documentTags.includes(DocumentTag.CUSTOMS_ENTRY_DRAFT)) {
      await sendDocsToSeller();
    } else if (document?.documentTags.includes(DocumentTag.CUSTOMS_ENTRY)) {
      await createWorkOrderMilestone({
        variables: {
          idempotencyKey: `${recordId}:${workOrderVersion}:${WorkOrderMilestoneName.FINAL_CUSTOMS_ENTRY_DOCUMENT_UPLOADED}`,
          input: {
            allowDuplicate: true,
            name: WorkOrderMilestoneName.FINAL_CUSTOMS_ENTRY_DOCUMENT_UPLOADED,
            workOrder: {
              id: recordId,
              version: workOrderVersion,
            },
          },
        },
      });
    }

    setIsLoading(false);
    closeModal();
  };

  return (
    <StyledModal
      destroyOnClose={true}
      maskClosable={true}
      open={isModalVisible}
      width={860}
      closable={false}
      footer={[
        <Button
          onClick={shouldSendToCbp ? handleSubmitToCBP : handleSubmitToSeller}
          type="primary"
          size="large"
          loading={isLoading}
          htmlType="submit"
          disabled={submitDisabled}
          key="sendButton"
        >
          {shouldSendToCbp ? 'Send to CBP' : 'Send to Seller'}
        </Button>,
      ]}
      onCancel={handleClose}
    >
      <Form form={form}>
        <FormItem>
          <StyledTable
            columns={buildColumns()}
            dataSource={buildData()}
            rowKey={(record: any) => record.docId}
            rowSelection={document ? undefined : rowSelection}
            pagination={false}
          />
        </FormItem>
      </Form>
    </StyledModal>
  );
};

export default SendDocumentModal;
