import { openModal, setModal } from 'actions';
import { message } from 'antd';
import { gql } from '@apollo/client';
import { memoFragments } from '@xbcb/client-queries';
import { checkAccess, UserPermissions } from '@xbcb/client-utils';
import { AccountType, RecordType } from '@xbcb/shared-types';
import {
  subscriptionWorkOrderTaskFragments,
  workOrderTaskFragments,
  workOrderTaskStepFragments,
} from '@xbcb/work-order-queries';
import {
  UsCbpEntryReleaseStatus,
  WorkOrderTaskType,
  WorkOrderType,
} from '@xbcb/work-order-types';
import { AppRecordEditMode } from '@xbcb/ui-types';
import { ModalKey } from 'types';
import { executeMutation } from 'libs/executeMutation';
import { transformUsType86Entry } from 'libs/formTransforms';
import {
  queryUsType86EntryReleaseCbp,
  usType86EntryFragments,
  usType86EntrySubscriptionFragments,
} from 'libs/sharedQueries';
import {
  isRecordCanceled,
  hasEntryNumber,
  isRecordDeleted,
} from 'libs/workOrderConditions';
// import UsType86EntrySecondaryHeader from 'components/UsType86EntrySecondaryHeader';
import { UsType86Entry } from '../../loadableComponents';
import { WorkOrderRoute } from '.';
import {
  findWorkOrderTaskAndModifyStepCompletion,
  appRecordAbiDataMenuItem,
  appRecordAssignWorkOrderTaskMenuItem,
  appRecordMemoMenuItem,
  inquireWorkOrderItem,
  unblockWorkOrderItem,
} from '../utils';
import MOTMenuOptions from 'components/MOTMenuOptions';
import { capitalCase } from 'change-case';
import { markWorkOrderButton } from '../utils/markWorkOrdersReadyButton';
import {
  createUsType86EntryReleaseCbp,
  replaceUsType86EntryReleaseCbp,
  updateUsType86EntryPgaCbp,
  updateUsType86EntryReleaseCbp,
} from '../../../libs/sharedQueries/mutations/transactions';
import validateUsType86Entry from 'libs/validateUsType86Entry';
import { checkKebabMenuItemForWorkOrderTask } from '../utils/checkKebabMenuItemForWorkOrderTask';
import { completeWorkOrderTaskButton } from '../utils/completeWorkOrderTaskButton';
import { createFlipWorkOrderMenuItem } from '../utils/flipWorkOrder';
import { appRecordSendAbiMessage } from '../utils/appRecordSendAbiMessage';
import { afterUsType86EntryUpdate } from 'libs/afterUsType86EntryUpdate.ts';
import { isExamOrHoldWorkOrderTask } from '../utils/isExamOrHoldWorkOrderTask';

const changeMot = gql`
  mutation ChangeUsType86EntryMot(
    $id: ID!
    $version: Int!
    $input: UpdateUsType86EntryInput!
  ) {
    updateUsType86Entry(id: $id, version: $version, input: $input) {
      record {
        id
        version
        conveyance {
          modeOfTransport
        }
      }
    }
  }
`;

const sharedType86EntryRouteFragment = `fragment usType86EntryRouteBaseFields on UsType86Entry {
  deadline
  status
  exam {
    lspNotifiedDate
  }
  confirmations {
    id
    status
    disputedReason
    additionalComments
  }
  pendingUpdates {
    path
    value
    occurrenceTime
    dataSource
  }
  tasks {
    ...workOrderTaskFields
    assignee {
      id
    }
    steps {
      id
      name
      description
      isCompletedBySystem
      isFinalConfirmation
    }
    definition {
      id
      workOrderTaskType
    }
    customFields {
			label
			customFieldType
		  booleanValue
			dateValue
		  textValue
		  recordId
      enumOptions
      enumValues
		}
  }
 
}`;

const fields = `
  ...usType86EntryFields
  ...usType86EntryRouteBaseFields
  group {
    forwarders {
      forwarder {
        tags {
          key
          value
        }
      }
    }
    documents {
      id
    }
    memos {
      ...memoFields
    }
    shipper {
      name
      memos {
        ...memoFields
      }
    }
  }
  ior {
    usIor {
      name
      industry
      memos {
        ...memoFields
      }
      iorNumber { value type }
    }
  }
  tasks {
    assignee {
      id
      name
    }
  }
`;
const releaseStatus = (existingRecord: any) => {
  return (
    !existingRecord?.releaseStatus ||
    existingRecord?.releaseStatus === UsCbpEntryReleaseStatus.SUBMITTED
  );
};

const showButton = (
  workOrderTaskType: string | WorkOrderTaskType | undefined,
) => {
  // Cannot use array.includes(workOrderTaskType) or array.includes(workOrderTaskType as WorkOrderTaskType).
  // Both of them will show error "workOrderTaskType is not assignable to type WorkOrderTaskType"
  return (
    !workOrderTaskType ||
    WorkOrderTaskType.US_TYPE86_ENTRY_COMPLETION_AND_SUBMISSION ===
      workOrderTaskType ||
    WorkOrderTaskType.US_TYPE86_ENTRY_CBP_EXAM === workOrderTaskType ||
    WorkOrderTaskType.US_TYPE86_ENTRY_CBP_HOLD === workOrderTaskType ||
    WorkOrderTaskType.US_TYPE86_ENTRY_EXCEPTION === workOrderTaskType
  );
};

const sharedFragments = `${sharedType86EntryRouteFragment}${workOrderTaskStepFragments}`;

const fragments = `${usType86EntryFragments}${workOrderTaskFragments}${sharedFragments}${memoFragments}`;

const subscriptionFields =
  '...usType86EntryBaseFields ...usType86EntryRouteBaseFields';

const subscriptionFragments = `${usType86EntrySubscriptionFragments}${subscriptionWorkOrderTaskFragments}${sharedFragments}`;

const usType86EntryRoute: WorkOrderRoute = {
  Page: UsType86Entry,
  recordType: RecordType.US_TYPE86_ENTRY,
  getTitleField: () => 'entryNumber',
  fields,
  fragments,
  transformUpdateRecordInput: transformUsType86Entry.toSchema,
  transformRecordToInput: transformUsType86Entry.toForm,
  validateUpdate: validateUsType86Entry,
  afterRecordUpdate: afterUsType86EntryUpdate,
  // SecondaryHeaderContents: [UsType86EntrySecondaryHeader],
  kebabMenuItems: [
    appRecordAbiDataMenuItem,
    inquireWorkOrderItem,
    unblockWorkOrderItem,
    appRecordSendAbiMessage,

    // {
    //   text: 'Generate Entry Number',
    //   key: 'generateEntryNumber',
    //   action: async ({ record }) => {
    //     const { data, errors } = await apolloClient.mutate({
    //       mutation: generateUsType86EntryNumber,
    //       variables: { usType86EntryId: record.id },
    //       errorPolicy: 'all', // this means any errors returned will  no throw, so are handled directly below
    //     });
    //     if (errors) {
    //       message.error(
    //         `Sorry, an error occurred, please try again later.`,
    //         5.0,
    //       );
    //     } else {
    //       message.success(
    //         `Entry number ${data?.generateUsType86EntryNumber?.usType86Entry?.entryNumber} generated.`,
    //         5.0,
    //       );
    //     }
    //   },
    //   show: ({ existingRecord, currentUser }) =>
    //     currentUser.accountType === AccountType.OPERATOR &&
    //     !existingRecord?.entryNumber &&
    //     !existingRecord?.brokerNumber &&
    //     !existingRecord?.deleted,
    // },
    appRecordAssignWorkOrderTaskMenuItem,
    appRecordMemoMenuItem,
    // TODO ABI sub menu
    {
      text: 'Update PGA',
      key: 'updatePga',
      action: async ({ setEditMode }) => {
        setEditMode('updatePga');
      },
      show: ({
        existingRecord,
        currentUser,
        workOrderTaskType,
        workOrderTaskEvaluatedState,
      }) =>
        currentUser.accountType === AccountType.OPERATOR &&
        // TODO use iterator/generator to check for PGA lines
        !releaseStatus(existingRecord) &&
        !isRecordDeleted(existingRecord) &&
        checkKebabMenuItemForWorkOrderTask(
          workOrderTaskType,
          workOrderTaskEvaluatedState,
        ),
    },
    {
      text: 'Query Entry',
      key: 'queryEntry',
      action: async ({ record }) => {
        await executeMutation({
          mutation: queryUsType86EntryReleaseCbp,
          variables: { usType86EntryId: record.id },
        });
      },
      show: ({
        existingRecord,
        currentUser,
        workOrderTaskType,
        workOrderTaskEvaluatedState,
      }) =>
        currentUser.accountType === AccountType.OPERATOR &&
        hasEntryNumber(existingRecord) &&
        checkKebabMenuItemForWorkOrderTask(
          workOrderTaskType,
          workOrderTaskEvaluatedState,
        ),
    },
    {
      text: 'Send Documents to CBP',
      key: 'sendDocumentsToCbp',
      action: async ({ dispatch }) => {
        dispatch(
          setModal({
            key: ModalKey.SEND_DOCUMENT,
            props: {
              visible: true,
              // set document to null since we don't want to just send one specific document
              document: null,
            },
          }),
        );
      },
      show: ({ currentUser, workOrderTaskType, workOrderTaskEvaluatedState }) =>
        currentUser.accountType === AccountType.OPERATOR &&
        checkKebabMenuItemForWorkOrderTask(
          workOrderTaskType,
          workOrderTaskEvaluatedState,
        ),
    },
    {
      text: 'Upload Invoice',
      key: 'uploadInvoice',
      action: async ({ history, location }) => {
        history.push(`${location.pathname}/upload`);
      },
      show: ({
        currentUser,
        existingRecord,
        workOrderTaskType,
        workOrderTaskEvaluatedState,
      }) =>
        checkAccess(currentUser, RecordType.PRODUCT, UserPermissions.UPDATE) &&
        checkAccess(currentUser, RecordType.PRODUCT, UserPermissions.CREATE) &&
        currentUser.accountType === AccountType.OPERATOR &&
        !isRecordDeleted(existingRecord) &&
        checkKebabMenuItemForWorkOrderTask(
          workOrderTaskType,
          workOrderTaskEvaluatedState,
        ),
    },
    {
      text: 'Delete Release',
      key: 'deleteRelease',
      action: async ({ dispatch }) => {
        dispatch(
          openModal({
            key: ModalKey.DELETE_US_TYPE86_ENTRY_RELEASE_CBP,
          }),
        );
      },
      show: ({ existingRecord, currentUser }) =>
        currentUser.accountType === AccountType.OPERATOR &&
        hasEntryNumber(existingRecord) &&
        existingRecord?.releaseStatus !== UsCbpEntryReleaseStatus.DELETED,
    },
    {
      text: 'Change MOT',
      key: 'changeMot',
      action: async ({ record, props }) => {
        const originalMot = record.conveyance.modeOfTransport;
        if (!props?.key) {
          message.error(
            `Sorry, an error occurred, please try again later.`,
            5.0,
          );
        }
        await executeMutation({
          mutation: changeMot,
          variables: {
            id: record.id,
            version: record.version,
            input: {
              conveyance: {
                modeOfTransport: props!.key.toUpperCase(),
              },
            },
          },
          successMessage: `MOT changed from ${originalMot.toLowerCase()} to ${
            props!.key
          }`,
        });
      },
      show: ({
        existingRecord,
        currentUser,
        workOrderTaskType,
        workOrderTaskEvaluatedState,
      }) =>
        currentUser.accountType === AccountType.OPERATOR &&
        checkAccess(
          currentUser,
          RecordType.US_TYPE86_ENTRY,
          UserPermissions.UPDATE,
        ) &&
        !isRecordCanceled(existingRecord) &&
        existingRecord?.releaseStatus !== UsCbpEntryReleaseStatus.FILED &&
        checkKebabMenuItemForWorkOrderTask(
          workOrderTaskType,
          workOrderTaskEvaluatedState,
        ),
      SubMenuOptions: MOTMenuOptions,
    },
    createFlipWorkOrderMenuItem({
      currentWorkOrderType: WorkOrderType.UsType86Entry,
      outputWorkOrderTypes: [WorkOrderType.UsConsumptionEntry],
      validateFilingTypeClassificationBeforeFlip: false,
    }),
  ],
  editModes: [
    {
      key: 'updateRelease',
      submitText: 'Submit bill update',
      cancelText: 'Cancel bill update',
      onSubmit: async ({ updatedRecord, confirmationData }) => {
        await executeMutation({
          mutation: updateUsType86EntryReleaseCbp,
          variables: { usType86EntryId: updatedRecord.id },
        });
      },
    },
    {
      key: 'updateEntry',
      submitText: 'Update Entry',
      cancelText: 'Cancel',
      onSubmit: async ({ updatedRecord }) => {
        await executeMutation({
          mutation: replaceUsType86EntryReleaseCbp,
          variables: { usType86EntryId: updatedRecord.id },
        });
      },
    },
    {
      key: 'updatePga',
      submitText: 'Submit PGA update',
      cancelText: 'Cancel PGA update',
      onSubmit: async ({ updatedRecord }) => {
        await executeMutation({
          mutation: updateUsType86EntryPgaCbp,
          variables: { usType86EntryId: updatedRecord.id },
        });
      },
    },
    {
      key: AppRecordEditMode.UPDATE_EXAM_INFO,
      submitText: 'Save',
      cancelText: 'Cancel',
      onSubmit: async (): Promise<void> => {
        // Do nothing, default action is to save record
      },
    },
  ],
  submitButtons: [
    completeWorkOrderTaskButton({
      skipValidationForWorkOrderTaskTypes: [
        WorkOrderTaskType.US_TYPE86_ENTRY_COMPLETION_AND_SUBMISSION,
      ],
    }),
    markWorkOrderButton(WorkOrderType.UsType86Entry),
    {
      key: 'submitEntry',
      text: 'Submit entry',
      show: ({ existingRecord, editMode, currentUser, workOrderTaskType }) => {
        return (
          showButton(workOrderTaskType) &&
          releaseStatus(existingRecord) &&
          editMode === 'draft' &&
          !isRecordDeleted(existingRecord) &&
          !isRecordCanceled(existingRecord) &&
          !currentUser.loading &&
          currentUser.accountType === AccountType.OPERATOR &&
          checkAccess(
            currentUser,
            RecordType.US_TYPE86_ENTRY,
            UserPermissions.UPDATE_RELEASE_CBP_TRANSACTION,
          )
        );
      },
      onSubmit: async ({
        updatedRecord,
        confirmationData,
        workOrderTaskType,
      }) => {
        if (workOrderTaskType) {
          await findWorkOrderTaskAndModifyStepCompletion({
            workOrder: updatedRecord,
            workOrderTaskType,
          });
        }
        await executeMutation({
          mutation: createUsType86EntryReleaseCbp,
          variables: {
            usType86EntryId: updatedRecord.id,
          },
        });
      },
    },
  ],
  editButtons: [
    {
      key: AppRecordEditMode.UPDATE_ENTRY,
      text: 'Update entry',
      show: ({ existingRecord, workOrderTaskType }) =>
        showButton(workOrderTaskType) &&
        !releaseStatus(existingRecord) &&
        !isRecordCanceled(existingRecord) &&
        !isRecordDeleted(existingRecord),
    },
    {
      key: AppRecordEditMode.UPDATE_RELEASE,
      text: 'Update bill',
      show: ({ existingRecord, workOrderTaskType }) =>
        showButton(workOrderTaskType) &&
        !releaseStatus(existingRecord) &&
        !isRecordCanceled(existingRecord) &&
        !isRecordDeleted(existingRecord),
    },
    {
      key: AppRecordEditMode.UPDATE_EXAM_INFO,
      text: 'Update Exam Info',
      show: ({ existingRecord, workOrderTaskType }) =>
        isExamOrHoldWorkOrderTask(workOrderTaskType) &&
        showButton(workOrderTaskType) &&
        !releaseStatus(existingRecord) &&
        !isRecordCanceled(existingRecord) &&
        !isRecordDeleted(existingRecord),
    },
  ],
  defaultEditMode: false,
  draftEditMode: ({ existingRecord }) => {
    return releaseStatus(existingRecord);
  },
  getAdditionalWorkOrderInfo: ({ record }) => {
    const { entryNumber, releaseStatus } = record || {};

    return [
      {
        label: 'Entry #',
        value: entryNumber,
      },
      {
        label: 'Release status',
        value: capitalCase(releaseStatus || ''),
      },
    ];
  },
  subscriptionOptions: {
    fields: subscriptionFields,
    fragments: subscriptionFragments,
  },
};

export default usType86EntryRoute;
